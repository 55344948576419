import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

export const DeleteModal = ({ isOpen, product, toggle, confirm }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Excluir produto
            </ModalHeader>
            <ModalBody>
                <p>Deseja realmente excluir este produto?</p>
                <b>{product && product.name}</b>
            </ModalBody>
            <ModalFooter>
                <Button className="text-button" color="danger" onClick={confirm}>Excluir</Button>
                <Button className="text-button ms-2" color="secondary" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

export const DetailModal = ({ isOpen, product, toggle }) => {

    const DeliveryPlaces = () => {
        if (product.delivery_modes.length === 0) {
            return <p className="text-danger">Não há opção de entrega para este produto</p>
        } else {
            return <ul>{product.delivery_modes.map(e => <li key={e.id}>{e.description}</li>)}</ul>
        }
    }

    return (
        <Modal isOpen={isOpen && product !== null} toggle={toggle} size="lg">
            {product && (
                <>
                    <ModalHeader toggle={toggle}>Detalhes</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col xs="auto" className="text-center">
                                    <img className="rounded" src={product.image} alt={product.name} width='260' />
                                    <span className="d-block mt-3 badge text-bg-light">{product.hotel_place.name}</span>
                                </Col>
                                <Col>
                                    <h5>{product.name}</h5>
                                    <h5><b>R$ {product.price.number.toFixed(2).replace('.', ',')}</b></h5>
                                    <p className="mt-1">{product.description}</p>
                                    <hr />
                                    <small className="text-muted">Locais de entrega</small>
                                    <DeliveryPlaces />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="text-button" color="secondary" onClick={toggle}>Fechar</Button>
                    </ModalFooter>
                </>
            )}
        </Modal>
    )
}