import { Table } from "reactstrap"
import PropTypes from 'prop-types'

const transactionType = {
    'ORDER_PRODUCT': 'Solicitação de Produto',
    'ORDER_SERVICE': 'Solicitação de Serviço',
    'ORDER_ITEM_REJECTED': 'Cancelado'
}

export const GuestCheckoutTable = ({ guest }) => {

    function getValueTextColor(item) {
        return (item.reference.type === 'ORDER_ITEM_REJECTED' ? "text-danger" : "text-dark")
    }

    function getValueOfItem(item) {
        let symbol = item.reference.type === 'ORDER_ITEM_REJECTED' ? '-' : '+'
        let value = getAsCurrencyFormat(item.value.number)
        return `${symbol}${value}`
    }


    function getOrderDescription(order) {
        let description = order.description.trim()
        let items =  description.split(';').filter(e => e !== '')
        return items.map((e, index) => {
            let isLast = index === items.length - 1
            if (!isLast) {
                return <><span>- {e}</span> <br /> </>
            } else {
                return <span>- {e}</span>
            }
        })
    }

    function getAsCurrencyFormat(value) {
        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })       
    }

    return (
        <div className="mb-3">
            <p className="mb-1" style={{ fontSize: 16 }}>Hóspede: {guest.email}</p>
            <p className="" style={{ fontSize: 16 }}>Valor: {getAsCurrencyFormat(guest.total.number)}</p>
            <Table responsive>
                <thead>
                    <tr>
                        <th scope="col">Pedido</th>
                        <th className="text-center" scope="col">Tipo</th>
                        <th scope="col">{getAsCurrencyFormat(guest.total.number)}</th>
                    </tr>
                </thead>
                <tbody>
                    {guest.clean_items.map((item, index) =>
                        <tr key={`items-checkout-key-${index}`}>
                            <td style={{ maxWidth: 300 }}>{getOrderDescription(item)}</td>
                            <td className="text-center" style={{ minWidth: 240 }}>{transactionType[item.reference.type]}</td>
                            <td style={{ minWidth: 100 }}>
                                <span className={getValueTextColor(item)}>
                                    {getValueOfItem(item)}
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

GuestCheckoutTable.propTypes = {
    guest: PropTypes.object.isRequired
}