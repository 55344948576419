import { Modal, ModalBody, ModalHeader } from "reactstrap"

export const LeadCaptureSuccessModal = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="border-0" />
            <ModalBody className="text-center px-lg-5 pb-5">

                <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37.7144 51.1519L28.9353 42.3728C28.3978 41.8353 27.7408 41.5665 26.9644 41.5665C26.188 41.5665 25.5012 41.8651 24.904 42.4623C24.3665 42.9998 24.0978 43.6568 24.0978 44.4332C24.0978 45.2096 24.3665 45.8665 24.904 46.404L35.8332 57.4228C36.3707 57.9603 36.9978 58.229 37.7144 58.229C38.4311 58.229 39.0582 57.9603 39.5957 57.4228L61.0061 36.0123C61.6033 35.4151 61.9019 34.7283 61.9019 33.9519C61.9019 33.1755 61.6033 32.4887 61.0061 31.8915C60.4089 31.354 59.7071 31.1002 58.9009 31.13C58.0946 31.1599 57.4228 31.4436 56.8853 31.9811L37.7144 51.1519ZM42.9998 78.8332C38.1026 78.8332 33.4741 77.8925 29.1144 76.0113C24.7547 74.13 20.9474 71.562 17.6925 68.3071C14.4377 65.0523 11.8696 61.245 9.98838 56.8853C8.10713 52.5255 7.1665 47.8971 7.1665 42.9998C7.1665 38.0429 8.10713 33.3846 9.98838 29.0248C11.8696 24.6651 14.4377 20.8728 17.6925 17.6478C20.9474 14.4228 24.7547 11.8696 29.1144 9.98838C33.4741 8.10713 38.1026 7.1665 42.9998 7.1665C47.9568 7.1665 52.6151 8.10713 56.9748 9.98838C61.3346 11.8696 65.1269 14.4228 68.3519 17.6478C71.5769 20.8728 74.13 24.6651 76.0113 29.0248C77.8925 33.3846 78.8332 38.0429 78.8332 42.9998C78.8332 47.8971 77.8925 52.5255 76.0113 56.8853C74.13 61.245 71.5769 65.0523 68.3519 68.3071C65.1269 71.562 61.3346 74.13 56.9748 76.0113C52.6151 77.8925 47.9568 78.8332 42.9998 78.8332ZM42.9998 73.4582C51.4804 73.4582 58.6769 70.487 64.5894 64.5446C70.5019 58.6023 73.4582 51.4207 73.4582 42.9998C73.4582 34.5193 70.5019 27.3228 64.5894 21.4103C58.6769 15.4978 51.4804 12.5415 42.9998 12.5415C34.579 12.5415 27.3974 15.4978 21.455 21.4103C15.5127 27.3228 12.5415 34.5193 12.5415 42.9998C12.5415 51.4207 15.5127 58.6023 21.455 64.5446C27.3974 70.487 34.579 73.4582 42.9998 73.4582Z" fill="#6FCF97" />
                </svg>

                <h3 className="text-title text-gray1 mt-5">Obrigado por se inscrever!</h3>
                <p className="text-body1 text-gray1 mt-3">Em breve, um de nossos especialistas entrará em contato <br />com você para agendar uma demonstração.</p>

            </ModalBody>
        </Modal>
    )
}