import { createContext, useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../StoreContext";
import { mapPlaceSettings } from "../../services/aloha-settings";
import NotificationSound from "./../../assets/sound/friend-request.mp3"
import SseEvents from "../../services/sse";
import moment from "moment";
import useRequestsOrdersHelper from "./RequestsOrdersHelper";
import useRequestsServicesHelper from "./RequestsServicesHelper";
import useRequestsCheckInsHelper from "./RequestsCheckInsHelper";

const ORDER_PENDING = 'REQUESTED'
const ORDER_ACCEPTED = 'ACCEPTED'
const ORDER_DONE = 'DONE'

export const RequestsContext = createContext({
    notifications: [],
    orders: {
        pending: [],
        accepted: [],
        concluded: []
    },
    services: {
        pending: [],
        accepted: [],
        concluded: []
    },
    checkIns: {
        pending: []
    },
    refreshNotifications: () => { }
})

export const RequestsProvider = ({ children }) => {

    const store = useContext(StoreContext)
    const requestsOrdersHelper = useRequestsOrdersHelper()
    const requestsServicesHelper = useRequestsServicesHelper()
    const requestsCheckInsHelper = useRequestsCheckInsHelper()

    const audioPlayer = useRef(null)
    const [notifications, setNotifications] = useState([])
    const [orders, setOrders] = useState({ pending: [], accepted: [], concluded: [] })
    const [services, setServices] = useState({ pending: [], accepted: [], concluded: [] })
    const [checkIns, setCheckIns] = useState({ pending: [] })

    useEffect(_ => {
        SseEvents.connect(data => { onMessageReceive(data) })
    }, [])

    useEffect(_ => {
        handlePlaySound()
    }, [notifications])

    useEffect(_ => {
        refreshNotifications()
    }, [orders.pending])

    function onMessageReceive(data) {
        requestsOrdersHelper.handleMessageReceive(data)
        requestsServicesHelper.handleMessageReceive(data)
        requestsCheckInsHelper.handleMessageReceive(data)
        refreshState()
    }

    function handlePlaySound() {
        if (notifications.length > 0) {
            audioPlayer.current.play()
        }
    }

    function refreshState() {
        setOrders({
            pending: requestsOrdersHelper.orders.current.filter(item => item.status === ORDER_PENDING),
            accepted: requestsOrdersHelper.orders.current.filter(item => item.status === ORDER_ACCEPTED),
            concluded: requestsOrdersHelper.orders.current.filter(item => item.status === ORDER_DONE)
        })
        setServices({
            pending: requestsServicesHelper.services.current.filter(item => item.isPending),
            accepted: requestsServicesHelper.services.current.filter(item => item.isAccepted),
            concluded: requestsServicesHelper.services.current.filter(item => item.isConcluded)
        })
        setCheckIns({
            pending: requestsCheckInsHelper.checkIns.current
        })
    }

    /**
     * Update the notifications list
     */
    function refreshNotifications() {

        let notificationsList = []

        // find places that we can notify
        let validPlaces = store.places.get().map(mapPlaceSettings).filter(place => {

            let isValid = place.notifications.active

            /**
             * if the time is setted we need to validate the time
             */
            if (isValid && place.notifications.startTime && place.notifications.endTime) {
                let startTime = new moment(place.notifications.startTime, ['HH:mm'])
                let endTime = new moment(place.notifications.endTime, ['HH:mm'])
                let current = new moment()

                // Important to handle time range from night to morning 
                if (startTime.isAfter(endTime)) {
                    isValid = current.isAfter(startTime) || current.isBefore(endTime)
                } else {
                    isValid = current.isBetween(startTime, endTime)
                }
            }

            return isValid
        }).map(place => place.id)

        orders.pending.forEach(order => {
            if (validPlaces.includes(order.placeId)) {
                notificationsList.push({ place: order.placeId })
            }
        })

        services.pending.forEach(service => {
            if (validPlaces.includes(service.placeId)) {
                notificationsList.push({ place: service.placeId })
            }
        })

        setNotifications(notificationsList)
    }

    return (
        <RequestsContext.Provider value={{ notifications, services, orders, checkIns, refreshNotifications }}>
            <audio ref={audioPlayer} autoPlay={false} src={NotificationSound} loop={notifications.length > 0} />
            {children}
        </RequestsContext.Provider>
    )
}