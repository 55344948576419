import TimePicker from "./TimePicker";
import styled from "styled-components";

function getBorder(className) {
  if (className === 'is-invalid') {
    return '#dc3545'
  }
  return '#dee2e6'
}

const StyledTimePicker = styled(TimePicker)`
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-input {
    border-color: ${(props) => getBorder(props.className)} !important;
  }
  & .rc-time-picker-panel-select {
    border-color: #e9e9e9 !important;
    box-shadow: none !important;
  }
  & .rc-time-picker-panel-input {
    font-size: 16px;
    cursor: pointer;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

export default StyledTimePicker;