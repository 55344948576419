import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { LoaderButton } from "../../../../../components/Button/LoaderButton"
import { ToastContext } from "../../../../../context/ToastContext"
import api from "../../../../../services/api"

export const DeletePostModal = props => {

    const toast = useContext(ToastContext)
    
    const [state, setState] = useState({ loading: false })

    function deletePost() {
        setState({ loading: true })
        api.delete(`/v1/posts/${props.postId}`)
            .then(_ => handleSuccess())
            .catch(_ => handleError())
            .finally(_ => setState({ loading: false }))
    }

    function handleSuccess() {
        props.toggle()
        props.reload()
        toast.success('Postagem excluida com sucesso')
    }

    function handleError() {
        toast.error('Desculpe ocorreu um erro, tente novamente!')
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                Excluir postagem
            </ModalHeader>
            <ModalBody>
                Deseja realmente excluir essa postagem?
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="danger" loading={state.loading} onClick={deletePost}>Excluir</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={props.toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}