const ButtonTab = props => {

    const active = props.active || false

    var className = 'text-button d-flex align-items-center justify-content-center fw-bold text-uppercase w-100 '

    if (active) {
        className += 'bg-primary text-white border-none'
    } else {
        className += 'bg-white text-dark border'
    }

    return (
        <div onClick={props.onClick} className={className} style={{ height: 36, borderRadius: 32, cursor: 'pointer', fontSize: 14 }}>{props.children}</div>
    )
}

export default ButtonTab