import { useState, useContext } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { ProductForm } from "./ProductForm"
import { StoreContext } from "../../../../../context/StoreContext"
import { ToastContext } from "../../../../../context/ToastContext"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import AlohaApi from "../../../../../services/aloha-api"

const CreateProductModal = (props) => {

    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const [data, setData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(false)

    function save() {
        setLoading(true)

        const onSuccess = () => {

            /**
             * important, update store
             */
            store.places.refresh()

            toast.success('Produto adicionado com sucesso')
            props.reload()
            props.toggle()
        }

        const onError = () => {
            toast.error('Desculpe, ocorreu um erro ao adicionar o produto, tente novamente')
            props.toggle()
        }

        AlohaApi.createProduct(data)
            .then(_ => onSuccess())
            .catch(_ => onError())
            .finally(_ => setLoading(false))
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} scrollable={true} size='lg'>
            <ModalHeader toggle={props.toggle}>Novo produto</ModalHeader>
            <ModalBody>
                <ProductForm
                    places={store.places.get()}
                    typeSuggestionList={props.typeSuggestion}
                    onFormValid={data => { setData(data); setIsValid(true) }}
                    onFormInvalid={data => { setData(data); setIsValid(false) }} />
            </ModalBody>
            <ModalFooter>
                <LoaderButton loading={isLoading} disabled={!isValid} onClick={save}>Salvar</LoaderButton>
                <Button className="text-button ms-2" disabled={isLoading} color="secondary" onClick={props.toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateProductModal