import { run } from "../../../../utils/func"
import { useContext, useEffect, useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { AccountCard, EditButton } from "./Common"
import { InputImage } from "../../../../components/Input/Input"
import { ToastContext } from "../../../../context/ToastContext"
import api from '../../../../services/api'
import Avatar from "../../../../components/Avatar"
import ImageView from "../../../../components/ImageView/ImageView"

const INITIAL_INFO = {
    hero_image_url: null,
    hotel_name: null,
    description: null,
    main_email: null
}

const AccountHeader = _ => {

    const toast = useContext(ToastContext)

    const [info, setInfo] = useState({ data: INITIAL_INFO, loading: true, error: false })
    const [infoVersion, setInfoVersion] = useState(1)

    const [updateModal, setUpdateModal] = useState({ open: false, loading: false })
    const [formData, setFormData] = useState({ logo: null, name: null, description: null })

    const setImage = (image) => setFormData(data => ({ ...data, logo: image }))
    const setName = (name) => setFormData(data => ({ ...data, name }))
    const setDescription = (description) => setFormData(data => ({ ...data, description }))

    const toogleModal = () => setUpdateModal(state => ({ open: !state.open }))
    const setModalLoading = (loading) => setUpdateModal(s => ({ ...s, loading }))

    const postUpdateForm = () => {
        setModalLoading(true)

        const onSuccess = () => {
            setInfoVersion(v => v += 1)
            toogleModal()
            toast.success('Perfil atualizado com sucesso!')
        }

        const onError = () => {
            toast.error('Ocorreu um erro, tente novamente!')
        }

        api.put('/info', formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(_ => onSuccess())
            .catch(_ => onError())
            .finally(_ => setModalLoading(false))
    }

    useEffect(_ => {
        const onSuccess = (res) => {
            setInfo({ data: res.data, loading: false, error: false })
            setFormData({
                logo: res.data.hero_image_url,
                name: res.data.hotel_name,
                description: res.data.description
            })
        }

        const onError = () => {
            setInfo({ data: null, loading: false, error: true })
        }

        api.get('info')
            .then(res => onSuccess(res))
            .catch(_ => onError())
    }, [infoVersion])

    const isSaveButtonEnabled = run(() => {
        let logo = formData.logo
        let name = formData.name
        let desc = formData.description
        return (logo && name && desc && !updateModal.loading)
    })

    const preview = run(_ => {

        let object = {
            logo: null,
            name: formData.name ? formData.name : '',
            description: formData.description ? formData.description : ''
        }

        if (formData.logo) {
            if (formData.logo === info.data.hero_image_url) {
                object.logo = formData.logo
            } else {
                object.logo = URL.createObjectURL(formData.logo)
            }
        }

        return object
    })

    return (
        <>
            <AccountCard loading={info.loading} error={info.error} className="position-relative">
                <div className="d-flex justify-content-between">
                    <h5 className="body2 fw-bold text-gray1">Detalhes</h5>
                    <EditButton onClick={_ => toogleModal()} />
                </div>
                <div className="center flex-column">
                    {info.data && info.data.hero_image_url ? <ImageView className="border rounded-circle" alt="logo" src={info.data.hero_image_url} width={120} height={120} /> : <Avatar letter="G" width={90} height={90} />}
                    <h4 className="h1 mt-3">{info.data ? info.data.hotel_name : ''}</h4>
                    <span>{info.data && info.data.main_email}</span>
                </div>
            </AccountCard>

            <AccountCard loading={info.loading} error={info.error}>
                <div className="d-flex justify-content-between">
                    <h5 className="body2 fw-bold text-gray1">Sobre</h5>
                    <EditButton onClick={_ => toogleModal()} />
                </div>
                <p className="m-0 mt-4 text-gray3">{info.data && info.data.description ? info.data.description : 'Nenhuma descrição'}</p>
            </AccountCard>

            <Modal isOpen={updateModal.open} toggle={toogleModal} scrollable={true} size='lg'>
                <ModalHeader toggle={toogleModal}>Editar perfil</ModalHeader>
                <ModalBody>
                    <Form>
                        {preview.logo && <div className="text-center"><img className="border rounded" src={preview.logo} alt="Produto" width={150} /></div>}
                        <FormGroup>
                            <Label for='inp-image'>Imagem</Label>
                            <InputImage id="inp-image" name="image" onImage={image => setImage(image)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for='inp-name'>Nome</Label>
                            <Input value={preview.name} id='inp-name' name='name' type='text' placeholder='Digite aqui' onChange={e => setName(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for='inp-desc'>Sobre</Label>
                            <Input value={preview.description} id='inp-desc' name='desc' type='textarea' rows="5" placeholder='Digite aqui' onChange={e => setDescription(e.target.value)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={postUpdateForm} disabled={!isSaveButtonEnabled}>
                        {(updateModal.loading) ? <><span className="pe-3">Salvando</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>
                    <Button color="secondary" onClick={toogleModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AccountHeader