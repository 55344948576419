import { useContext, useState } from "react"
import { Alert, Card, CardBody, Col, Form, FormFeedback, Input, Modal, ModalBody, Row } from "reactstrap"
import { StoreContext } from "../../../../../context/StoreContext"
import { RequestsContext } from "../../../../../context/requests/RequestsContext"
import { dateToStr, getDatefrom, parseToDate } from "../../../../../utils/func"
import { ToastContext } from "../../../../../context/ToastContext"
import SearchableRoom from "../components/SearchableRoom"
import AlohaApi from "../../../../../services/aloha-api"
import LoaderButton from "../../../../../components/Button/LoaderButton"

const ApproveGuestRequestModal = (props) => {

    const store = useContext(StoreContext)
    const requests = useContext(RequestsContext)
    const toast = useContext(ToastContext)

    const [room, setRoom] = useState({ value: 555, valid: true })
    const [checkin, setCheckin] = useState({ value: '', valid: true })
    const [checkout, setCheckout] = useState({ value: '', valid: true })
    const [customFields, setCustomFields] = useState({})
    const [guestId, setGuestId] = useState(undefined)

    const [approveLoading, setApproveLoading] = useState(false)
    const [refuseLoading, setRefuseLoading] = useState(false)

    const approve = () => {
        let checkinDate = parseToDate(dateToStr(getDatefrom(checkin.value)))
        let checkoutDate = parseToDate(dateToStr(getDatefrom(checkout.value)))

        setApproveLoading(true)
        AlohaApi.approveGuest(guestId, checkinDate, checkoutDate, room.value, customFields)
            .then(_ => handleCheckedRequest())
            .then(_ => toast.success('Hóspede aprovado'))
            .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
            .finally(_ => setApproveLoading(false))
    }

    const refuse = () => {
        setRefuseLoading(true)
        AlohaApi.refuseGuest(guestId)
            .then(_ => handleCheckedRequest())
            .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
            .finally(_ => setRefuseLoading(false))
    }

    const onChangeRoom = (roomId) => {
        setRoom({ value: roomId, valid: store.rooms.get().some(e => e.id === roomId) })
    }

    const onChangeCheckin = (event) => {
        let value = event.target.value
        setCheckin({ value, valid: true })
        guestStayIntervalValidation(value, checkout.value)
    }

    const onChangeCheckout = (event) => {
        let value = event.target.value

        // first, check if checkin was filled
        if (!checkin.value) {
            setCheckout({ value, valid: true })
            return
        }

        guestStayIntervalValidation(checkin.value, value)
    }

    const onChangeCustomField = (event, id) => {
        let value = event.target.value
        let newCustomFields = { ...customFields }
        newCustomFields[id] = value

        setCustomFields(newCustomFields)
    }

    const guestStayIntervalValidation = (checkinValue, checkoutValue) => {
        if (checkinValue !== '' && checkoutValue !== '') {
            try {
                let startDate = new Date(checkinValue)
                let endDate = new Date(checkoutValue)
                let isValid = startDate.getTime() < endDate.getTime() // compare checkin and checkout dates
                setCheckout({ value: checkoutValue, valid: isValid })
            } catch (error) {
                setCheckout({ value: checkoutValue, valid: false })
            }
        }
    }

    const toInputDate = (dateStr) => {
        const data = new Date(dateStr);
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, "0");
        const day = String(data.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`
    }

    const loadValues = () => {
        onChangeRoom(requests.checkIns.pending[0].guest.room_id)
        setCheckin({ value: toInputDate(requests.checkIns.pending[0].guest.checkin), valid: true })
        setCheckout({ value: toInputDate(requests.checkIns.pending[0].guest.checkout), valid: true })
        setCustomFields(requests.checkIns.pending[0].guest.custom_fields)
        setGuestId(requests.checkIns.pending[0].guest.id)
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleCheckedRequest = () => {
        sleep(300).then(() => {
            if (requests.checkIns.pending.length !== 0) {
                loadValues()
            } else {
                props.toggle()
            }
        });
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" toggle={props.toggle} centered={true} scrollable onOpened={loadValues}>
            <ModalBody className="background1">
                <p className="m-0 mb-1 headline2">Solicitação de estadia</p>
                <p className="m-0 mb-3 body1 text-gray2">Confirme ou rejeite as possíveis estadias abaixo: </p>

                <Card className="mb-3 body1">
                    <CardBody>
                        <Row>
                            <Col xs={3} className="text-gray2">Name: </Col>
                            <Col xs={9} className="text-gray1">{requests.checkIns.pending[0].name}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={3} className="text-gray2">Quarto: </Col>
                            <Col xs={9} className="text-gray1">
                                <SearchableRoom value={room.value} rooms={store.rooms.get()} onChangeRoomHandler={item => onChangeRoom(item.value)} />
                            </Col>
                        </Row>
                        {
                            requests.checkIns.pending[0].customFields.map((customField, idx) => (
                                <div key={`ct-field-${idx}`}>
                                    <hr />
                                    <Row>
                                        <Col xs={3} className="text-gray2">{customField.title}</Col>
                                        <Col xs={9} className="text-gray1">
                                            <Input id="inp-1" type="text" value={customFields[customField.id]} onChange={e => onChangeCustomField(e, customField.id)}></Input>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                        <hr />
                        <Row>
                            <Col xs={3} className="text-gray2">Check-in: </Col>
                            <Col xs={9} className="text-gray1">
                                <Input id='inp-checkin' type="date" placeholder="Data de checkin" value={checkin.value} invalid={!checkin.valid} onChange={onChangeCheckin} />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={3} className="text-gray2">Check-out: </Col>
                            <Col xs={9} className="text-gray1">
                                <Form>
                                    <Input id='inp-checkout' type="date" placeholder="Data de checkout" value={checkout.value} invalid={!checkout.valid} onChange={onChangeCheckout} />
                                    <FormFeedback>A data de check-out deve ser após o check-in</FormFeedback>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Alert color="primary">
                    <p><b>Importante!</b></p>
                    <p className="mb-0">Após aprovação o cliente poderá usar o aplicativo sem nenhuma restrição. Por isso é importante confirmar todos os dados informados pelo cliente antes da aprovação.</p>
                </Alert>

                <div className="w-100 d-flex justify-content-between gap-2">
                    <LoaderButton block color="secondary" loading={refuseLoading} onClick={refuse}>Rejeitar</LoaderButton>
                    <LoaderButton block color="success" loading={approveLoading} onClick={approve}>Aprovar (1/{requests.checkIns.pending.length})</LoaderButton>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ApproveGuestRequestModal