import { useState, useContext, useEffect} from "react"
import { Alert, Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { dateToStr, getDatefrom, parseToDate } from "../../../../../utils/func"
import { validateEmail } from "../../../../../utils/strings"
import { StoreContext } from "../../../../../context/StoreContext"
import { ToastContext } from "../../../../../context/ToastContext"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import AlohaApi from "../../../../../services/aloha-api"
import SearchableRoom from "../components/SearchableRoom"

let initialState = {
    email: { value: '', valid: true },
    room: { value: '', valid: true },
    checkin: { value: '', valid: true },
    checkout: { value: '', valid: true }
}

const EditGuestModal = (props) => {

    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)

    const [email, setEmail] = useState({ value: '', valid: false })
    const [room, setRoom] = useState({ value: '', valid: false })
    const [checkin, setCheckin] = useState({ value: '', valid: false })
    const [checkout, setCheckout] = useState({ value: '', valid: false })

    useEffect(_ => {
        validateCheckinCheckoutDates()
    }, [checkin, checkout])

    const onChangeEmail = (event) => {
        let value = event.target.value
        let valid = value.length > 0 && validateEmail(value)
        setEmail({ value, valid })
    }

    const onChangeRoom = (roomId) => {
        setRoom({ value: roomId, valid: store.rooms.get().some(e => e.id === roomId) })
    }

    const onChangeCheckin = (event) => {
        let value = event.target.value
        setCheckin({ value, valid: true })
    }

    const onChangeCheckout = (event) => {
        let value = event.target.value
        setCheckout({ value, valid: true })
    }

    const onClickSaveGuest = () => {
        showLoading()

        function onSuccess() {
            props.toggleForm()
            props.reload()
            resetState()
        }

        let checkinDate = parseToDate(dateToStr(getDatefrom(checkin.value)))
        let checkoutDate = parseToDate(dateToStr(getDatefrom(checkout.value)))

        let whatsapp = null // for the future

        AlohaApi.updateGuest(props.guest.id, email.value, whatsapp, room.value, checkinDate, checkoutDate)
            .then(onSuccess)
            .catch(handleResponseError)
            .finally(hideLoading)
    }

    function validateForm() {
        if (props.guest) {
            let guest = getGuestFromProps()
            let form = { email: email.value, room: room.value, checkin: checkin.value, checkout: checkout.value }

            /**
             * if equals, we disable form button because because there was no change
             */
            if (areEquals(guest, form)) {
                return false
            }
        }
        return [email, room, checkin, checkout].every(e => e.valid)
    }

    function setupGuestState() {
        if (props.guest) {
            let guest = getGuestFromProps()
            setEmail({ value: guest.email, valid: true })
            setRoom({ value: guest.room, valid: true })
            setCheckin({ value: guest.checkin, valid: true })
            setCheckout({ value: guest.checkout, valid: true })
        }
    }

    function resetState() {
        setEmail(initialState.email)
        setRoom(initialState.room)
        setCheckin(initialState.checkin)
        setCheckout(initialState.checkout)
    }

    function onModalOpen() {
        setupGuestState()
    }

    function getGuestFromProps() {
        if (props.guest) {
            return {
                email: props.guest.customer_email,
                room: props.guest.room_id,
                checkin: toInputDate(props.guest.checkin),
                checkout: toInputDate(props.guest.checkout)
            }
        }
    }

    function validateCheckinCheckoutDates() {
        if (checkin.value && checkout.value) {
            try {
                let startDate = new Date(checkin.value)
                let endDate = new Date(checkout.value)
                let valid = startDate.getTime() < endDate.getTime() // compare checkin and checkout dates

                if (checkout.valid !== valid) {
                    setCheckout(state => ({ ...state, valid }))
                }
            } catch (error) {
                console.log('Error')
            }
        }
    }

    /**
     * Toast an especific or generic error message base on response of throwable 
     * 
     * @param {Error} throwable 
     */
    function handleResponseError(throwable) {
        try {
            if (!throwable) {
                return
            }

            let toastedErrorsCount = 0
            let errors = throwable.response.data.errors

            errors.filter(item => item.error === 'GUEST_OVERLAPPED').forEach(error => {
                let errorEmail = error.error_details.guest.customer_email.value
                toast.error(`Já existe uma hospedagem para o email ${errorEmail}`)
                toastedErrorsCount += 1
            })

            errors.filter(item => item.error === "GUEST_INVALID_EMAIL").forEach(error => {
                let errorEmail = error.error_details.email.value
                toast.error(`O email ${errorEmail} é inválido`)
                toastedErrorsCount += 1
            })

            if (toastedErrorsCount === 0) {
                throw Error('No error toasted to the user')
            }

        } catch (error) {
            toast.error('Desculpe, ocorreu um erro!')
        }
    }

    return (
        <Modal isOpen={props.isFormOpen} toggle={props.toggleForm} onClosed={resetState} onOpened={onModalOpen} scrollable size='lg'>
            <ModalHeader toggle={props.toggleForm}>Editar hóspede</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for='inp-email' className="text-body2 mb-1">Email</Label>
                    <Input id='inp-email' name='email' type="email" placeholder="Digite o email" invalid={!email.valid} value={email.value} onChange={onChangeEmail} />
                </FormGroup>
                <FormGroup>
                    <Label for='inp-room' className="text-body2 mb-1">Quarto</Label>
                    <SearchableRoom value={room.value} rooms={store.rooms.get()} onChangeRoomHandler={item => onChangeRoom(item.value)} />
                </FormGroup>
                <Row>
                    <Col xs={12} lg={6}>
                        <FormGroup>
                            <Label for='inp-checkin' className="text-body2 mb-1">CheckIn</Label>
                            <Input id='inp-checkin' type="date" placeholder="Data de checkin" value={checkin.value} invalid={!checkin.valid} onChange={onChangeCheckin} />
                            <FormFeedback>A data de check-out deve ser após o check-in</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                        <FormGroup>
                            <Label for='inp-checkout' className="text-body2 mb-1">CheckOut</Label>
                            <Input id='inp-checkout' type="date" placeholder="Data de checkout" value={checkout.value} invalid={!checkout.valid} onChange={onChangeCheckout} />
                            <FormFeedback>A data de check-out deve ser após o check-in</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Alert color="secondary">
                    <p className="m-0">Após a edição, todos os hóspedes da mesma reserva terão a data de check-in ou check-out alterada(s).</p>
                </Alert>

            </ModalBody>
            <ModalFooter>
                <LoaderButton loading={loading} onClick={onClickSaveGuest} disabled={!validateForm()}>Salvar</LoaderButton>
                <Button onClick={props.toggleForm} className="ms-2 text-button" color="secondary">Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

function toInputDate(dateStr) {
    const data = new Date(dateStr);
    const year = data.getFullYear();
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const day = String(data.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`
}

function areEquals(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export default EditGuestModal