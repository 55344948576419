import { X } from "react-feather"
import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row, FormText, Alert } from "reactstrap"
import { validateEmail } from "../../../../../utils/strings"
import { dateToStr, getDatefrom, parseToDate, today, tomorrow } from "../../../../../utils/func"
import { ToastContext } from "../../../../../context/ToastContext"
import { OperatorContext } from "../../../../../context/OperatorContext"
import { StoreContext } from "../../../../../context/StoreContext"
import InputPhone from "../../../../../components/Input/InputPhone"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import AlohaApi from "../../../../../services/aloha-api"
import SearchableRoom from "../components/SearchableRoom"
import moment from "moment"
import OperatorSwitch from "../../../../../components/Button/OperatorSwitchButton"
import AlohaSettings from "../../../../../services/aloha-settings"

let nextAdditionalGuestId = 1
let nextAdditionalGuestIdLabel = 1

function buildInitialState() {
    return {
        canDisplayError: false,
        email: { value: '', valid: false },
        whatsapp: { value: '', valid: true },
        room: { value: '', valid: false },
        checkin: { value: moment(today()).format().slice(0, 10), valid: true },
        checkout: { value: moment(tomorrow()).format().slice(0, 10), valid: true },
        additional: []
    }
}

const CreateGuestModal = (props) => {

    const store = useContext(StoreContext)
    const operatorContext = useContext(OperatorContext)

    const toast = useContext(ToastContext)

    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)

    const [email, setEmail] = useState({ value: '', valid: false, canDisplayError: false })
    const [whatsapp, setWhatsapp] = useState({ value: '', valid: true })
    const [room, setRoom] = useState({ value: '', valid: false })
    const [checkin, setCheckin] = useState({ value: moment(today()).format().slice(0, 10), valid: true })
    const [checkout, setCheckout] = useState({ value: moment(tomorrow()).format().slice(0, 10), valid: true })
    const [additional, setAdditional] = useState([])

    const [autoClearOperator, setAutoClearOperator] = useState(AlohaSettings.operators.isAutoClearEnabled())

    const onChangeEmail = (event) => {
        let value = event.target.value
        let valid = value.length > 0 && validateEmail(value)
        setEmail({ value, valid, canDisplayError: true })
    }

    const onChangeWhatsapp = (event) => {
        let value = event.target.value.replace(/\D/g, '')
        let valid = value.length === 0 || value.length === 11
        setWhatsapp({ value, valid })
    }

    const onChangeRoom = (roomId) => {
        setRoom({ value: roomId, valid: store.rooms.get().some(e => e.id === roomId) })
    }

    const onChangeCheckin = (event) => {
        let value = event.target.value
        setCheckin({ value, valid: true })
        guestStayIntervalValidation(value, checkout.value)
    }

    const onChangeCheckout = (event) => {
        let value = event.target.value

        // first, check if checkin was filled
        if (!checkin.value) {
            setCheckout({ value, valid: true })
            return
        }

        // after verify if checkin value exists, we can compare dates
        // inside try to solve crash on parse date
        guestStayIntervalValidation(checkin.value, value)
    }

    const guestStayIntervalValidation = (checkinValue, checkoutValue) => {
        if(checkinValue !== '' && checkoutValue !== '') {
            try {
                let startDate = new Date(checkinValue)
                let endDate = new Date(checkoutValue)
                let isValid = startDate.getTime() < endDate.getTime() // compare checkin and checkout dates
                setCheckout({ value: checkoutValue, valid: isValid })
            } catch (error) {
                setCheckout({ value: checkoutValue, valid: false })
            }
        }
    }

    const onChangeAdditionalEmail = (event, index) => {
        let value = event.target.value
        const newState = [...additional]
        newState[index].email.value = value
        newState[index].email.valid = value.length > 0 && validateEmail(value)
        setAdditional(newState)
    }

    const onChangeAdditionalWhatsapp = (event, index) => {
        let value = event.target.value.replace(/\D/g, '')
        let valid = value.length === 0 || value.length === 11
        const newState = [...additional]
        newState[index].whatsapp.value = value
        newState[index].whatsapp.valid = valid
        setAdditional(newState)
    }

    const onClickAddAdditionalGuest = () => {
        let id = nextAdditionalGuestId++
        let idLabel = nextAdditionalGuestIdLabel++
        let newAdditional = { id, label: idLabel, email: { value: '', valid: false }, whatsapp: { value: '', valid: true } }
        setAdditional(data => [...data, newAdditional])
    }

    const onClickRemoveAdditionalGuest = (index) => {
        let newState = [...additional]
        newState.splice(index, 1)

        if (newState.length === 0) {
            nextAdditionalGuestIdLabel = 1
        }

        setAdditional(newState)
    }

    const onClickSaveGuest = () => {
        showLoading()

        function onCreateGuestSuccess() {
            props.toggle()
            props.reload()
            resetState()

            toast.success('Hóspede adicionado com sucesso!')

            if (AlohaSettings.operators.isAutoClearEnabled()) {
                operatorContext.clear()
            }
        }

        let escorts = additional.map(e => ({ email: e.email.value, whatsapp: e.whatsapp.value }))
        let checkinDate = parseToDate(dateToStr(getDatefrom(checkin.value)))
        let checkoutDate = parseToDate(dateToStr(getDatefrom(checkout.value)))

        AlohaApi.createGuest(room.value, email.value, whatsapp.value, checkinDate, checkoutDate, escorts)
            .then(onCreateGuestSuccess)
            .catch(handleResponseError)
            .finally(hideLoading)
    }

    function resetState() {
        let initialState = buildInitialState()
        setEmail(initialState.email)
        setWhatsapp(initialState.whatsapp)
        setRoom(initialState.room)
        setCheckin(initialState.checkin)
        setCheckout(initialState.checkout)
        setAdditional(initialState.additional)
    }

    function validateForm() {
        let guestFieldsValid = [email, whatsapp, room, checkin, checkout].every(e => e.valid)
        let additionalFieldsValid = additional.every(e => e.email.valid && e.whatsapp.valid)
        return guestFieldsValid && additionalFieldsValid
    }

    function toggleAutoClearOperatorEnabled() {
        let enabled = !autoClearOperator
        setAutoClearOperator(enabled)
        AlohaSettings.operators.setAutoClearEnabled(enabled)
    }

    /**
     * Toast an especific or generic error message base on response of throwable 
     * 
     * @param {Error} throwable 
     */
    function handleResponseError(throwable) {
        try {
            if (!throwable) {
                return
            }

            let toastedErrorsCount = 0
            let errors = throwable.response.data.errors

            errors.filter(item => item.error === 'GUEST_OVERLAPPED').forEach(error => {
                let errorEmail = error.error_details.guest.customer_email.value
                toast.error(`Já existe uma hospedagem para o email ${errorEmail}`)
                toastedErrorsCount += 1
            })

            errors.filter(item => item.error === "GUEST_INVALID_EMAIL").forEach(error => {
                let errorEmail = error.error_details.email.value
                toast.error(`O email ${errorEmail} é inválido`)
                toastedErrorsCount += 1
            })

            if (toastedErrorsCount === 0) {
                throw Error('No error toasted to the user')
            }

        } catch (error) {
            toast.error('Desculpe, ocorreu um erro!')
        }
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" toggle={props.toggle} scrollable>
            <ModalHeader toggle={props.toggle}>Novo hóspede</ModalHeader>
            <ModalBody>

                <Form>
                    <Row>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <Label for='inp-email' className="text-body2 mb-1">Email</Label>
                                <Input id='inp-email' name='email' type="email" placeholder="Digite o email" invalid={email.canDisplayError && !email.valid} value={email.value} onChange={onChangeEmail} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <Label for='inp-whats' className="text-body2 mb-1">Whatsapp</Label>
                                <InputPhone id='inp-whats' name='whatsapp' placeholder="Digite o número do whatsapp" invalid={!whatsapp.valid} value={whatsapp.value} onChange={onChangeWhatsapp} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for='inp-room' className="text-body2 mb-1">Quarto</Label>
                        <SearchableRoom rooms={store.rooms.get()} onChangeRoomHandler={item => onChangeRoom(item.value)} />
                    </FormGroup>

                    <Row>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <Label for='inp-checkin' className="text-body2 mb-1">CheckIn</Label>
                                <Input id='inp-checkin' type="date" placeholder="Data de checkin" value={checkin.value} invalid={!checkin.valid} onChange={onChangeCheckin} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup>
                                <Label for='inp-checkout' className="text-body2 mb-1">CheckOut</Label>
                                <Input id='inp-checkout' type="date" placeholder="Data de checkout" value={checkout.value} invalid={!checkout.valid} onChange={onChangeCheckout} />
                                <FormFeedback>A data de check-out deve ser após o check-in</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    {additional.length > 0 && <hr />}

                    {additional.map((item, index) => {
                        return (
                            <AdditionalFormItem
                                key={`additional-${item.id}-${index}`}
                                index={index}
                                guest={item}
                                handleChangeEmail={event => onChangeAdditionalEmail(event, index)}
                                handleChangeWhatsapp={event => onChangeAdditionalWhatsapp(event, index)}
                                handleClickRemove={_ => onClickRemoveAdditionalGuest(index)} />
                        )
                    })}

                </Form>

                <hr className="mt-2 mb-3" />

                <div className="m-0 mb-2 d-flex align-items-center">
                    <span className="text-body2">Quem está realizando o cadastro do hóspede?</span>
                </div>
                <Alert hidden={!validateForm() || operatorContext.operator} color="danger" className="p-2 w-100 text-body2 text-center mb-2">Selecione o colaborador que está realizando a operação</Alert>
                <OperatorSwitch className="mb-2" />
                <FormGroup switch onClick={toggleAutoClearOperatorEnabled}>
                    <Input type="switch" checked={!autoClearOperator} readOnly />
                    <Label className="ms-1 text-caption text-gray2" check>Manter operador após cadastro</Label>
                </FormGroup>

            </ModalBody>
            <ModalFooter>
                <div className="w-100 d-flex justify-content-between">
                    <Button onClick={onClickAddAdditionalGuest} className="text-button" color="primary">Adicionar hóspede na estadia</Button>
                    <div className="d-flex gap-2">
                        <LoaderButton loading={loading} onClick={onClickSaveGuest} disabled={!validateForm() || !operatorContext.operator}>Salvar</LoaderButton>
                        <Button onClick={props.toggle} className="text-button" color="secondary">Cancelar</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

/**
 * Item view of each additional guest
 */
const AdditionalFormItem = (props) => {


    function handleChangeEmail(event) {
        if (props.handleChangeEmail) {
            props.handleChangeEmail(event)
        }
    }

    function handleChangeWhatsapp(event) {
        if (props.handleChangeWhatsapp) {
            props.handleChangeWhatsapp(event)
        }
    }

    function handleClickRemove() {
        if (props.handleClickRemove) {
            props.handleClickRemove()
        }
    }

    return (
        <div {...props}>
            <p className="m-0 mb-1 text-body2 text-gray2">Hóspede adicional {props.guest.label}</p>
            <Row>
                <Col xs={12} lg={6}>
                    <FormGroup>
                        <Input
                            id={`inp-email-${props.index}`}
                            name='email'
                            type="email"
                            placeholder="Digite o email"
                            invalid={!props.guest.email.valid}
                            value={props.guest.email.value}
                            onChange={handleChangeEmail} />
                        <FormText className="text-caption">Email do hóspede adicional</FormText>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={5}>
                    <FormGroup>
                        <InputPhone
                            id={`inp-whats-${props.index}`}
                            name='whatsapp'
                            placeholder="Digite o número do whatsapp"
                            invalid={!props.guest.whatsapp.valid}
                            value={props.guest.whatsapp.value}
                            onChange={handleChangeWhatsapp} />
                        <FormText className="text-caption">Whatsapp do hóspede adicional</FormText>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={1}>
                    <Button color="dark" className="border" outline={true} onClick={_ => handleClickRemove()}>
                        <X size={16} />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default CreateGuestModal