import { useNavigate } from 'react-router-dom'
import AlohaLogo from './../../assets/images/aloha-130.png'

const Logo = ({ onClick, className = '' }) => {

    const navigate = useNavigate()

    return (
        <div className={`d-flex flex-column center cursor-pointer ${className}`} onClick={onClick}>
            <img src={AlohaLogo} alt="aloha" width={130} />
            <div className='d-none d-md-block text-center'>
                <h2 className='mt-3 h1 cursor-pointer' onClick={e => navigate('/')}>Aloha</h2>
                <p className='body2 text-center' style={{ width: 200 }}>Uma nova experiência em viagens e hotéis</p>
            </div>
        </div>
    )
}

export default Logo