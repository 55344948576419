import PropTypes from 'prop-types'

const ImageView = (props) => {

    // contain, cover, fill, none, scale-down
    let fit = props.fit || 'cover'

    let customClasses = props.className || null

    let imgUrl = props.src && typeof props.src !== 'string' ? URL.createObjectURL(props.src) : props.src

    if (!imgUrl) {
        return <></>
    }

    return <img alt={props.alt || ''} {...props} className={customClasses} src={imgUrl} style={{ objectFit: fit }} />
}

ImageView.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string
}

export default ImageView