import { useEffect, useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import api from "../../../../../../services/api"

export const UpdateTimeSlotModal = props => {
    const timeSlotId = props.timeSlotId
    const scheduleId = props.scheduleId
    const [timeSlot, setTimeSlot] = useState({})

    function handleError() {}

    useEffect(() => {
        api.get(`/v1/schedules/${scheduleId}/time-slots/${timeSlotId}`)
        .then(res => {
            setTimeSlot(res.data)
        })
        .catch(_ => handleError())
    }, [timeSlotId, scheduleId])

    function onClose() {
        props.toggle()
    }
    
    function closeAndClean() {
        props.toggle()
    }

    function toFormatedStr(digit) {
        if(digit<10){
            return `0${digit}`
        }

        return `${digit}`
    }

    function getModalHeader(startDateStr) {
        var startDateTime = new Date(startDateStr);
        return `${toFormatedStr(startDateTime.getDate())}/${toFormatedStr(startDateTime.getMonth())}/${toFormatedStr(startDateTime.getFullYear())} às ${toFormatedStr(startDateTime.getHours())}:${toFormatedStr(startDateTime.getMinutes())}`
    }

    function isSaveButtonDisabled() {
        if(timeSlot.available_places > -1) {
            return false
        }
        return true
    }

    function setAvailablePlaces(places) {
        setTimeSlot({...timeSlot, available_places: parseInt(places)})
    }

    function updateTimeSlot() {
        api.put(`/v1/schedules/${scheduleId}/time-slots/${timeSlotId}`, {
            places: timeSlot.available_places
        }).then(res => {
            closeAndClean()
        }).catch(_ => handleError())
    }

    return (
        <>
            <Modal isOpen={props.isOpen} toggle={closeAndClean} size="lg" onClosed={props.onClosed}>
                <ModalHeader toggle={onClose}><b>{getModalHeader(timeSlot.start_date_time)}</b></ModalHeader>
                <ModalBody>
                    <div className="mt-3 mb-4">
                        Preencha com o número de vagas disponíveis que deseja atualizar:
                    </div>
                    <Form>
                        <FormGroup>
                            <Label><small>Vagas disponíveis</small></Label>
                            <Input type="number" value={timeSlot.available_places} onChange={(event) => setAvailablePlaces(event.target.value)}></Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={isSaveButtonDisabled()} color="primary" onClick={updateTimeSlot}>Salvar</Button>
                    <Button color="secondary" onClick={closeAndClean}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}