import { useEffect, useState } from "react"
import { Calendar, Clock, Target } from "react-feather"
import ImageView from "../../../../../../components/ImageView/ImageView"
import api from "../../../../../../services/api"

export const DetailsPanel = props => {

    const data = props.data
    const hideTag = props.hideTag === true ? true : false
    const [scheduleOptions, setScheduleOptions] = useState([])
    const [schedulePage, setSchedulePage] = useState(1)
    const start = new Date().toISOString().replace('Z', '').replace('/', '-').slice(0, -4)
    const end = new Date().toISOString().replace('Z', '').replace('/', '-').slice(0, -4)

    useEffect(_ => {
        api.get(`/v1/schedules?page=${schedulePage}&start=${start}&end=${end}`)
            .then(res => {
                setScheduleOptions([...scheduleOptions, ...res.data.result])

                if (res.data.has_next_page) {
                    setSchedulePage(schedulePage + 1)
                }
            })
    }, [schedulePage])

    function scheduleTagContent() {
        if (data.calendar.type === 'REGISTERED') {
            return "Agenda Existente"
        }

        return "+ Nova Agenda"
    }

    function getScheduleName() {
        if (data.type === 'REGISTERED') {
            return scheduleOptions.find(element => element.schedule.id === data.calendar.id).schedule.name
        }

        return data.calendar.name
    }

    return (
        <div>
            <ImageView src={data.image} alt="Serviço" className="d-block mt-3 mb-5 m-auto w-100" />

            <div className="d-flex ">
                <div className="flex-fill">
                    <h2 className="text-h5 mb-2">{data.name}</h2>
                    <div className="d-flex align-items-center gap-2 mb-2">
                        <Clock size={16} /><span className="text-body2 text-muted">{data.time} min</span>
                    </div>
                    <p className="text-body2">{data.description}</p>
                </div>
                <div className="text-right">
                    <h5 className="text-h5 text-success mt-3">R$ {data.price.value}</h5>
                </div>
            </div>

            <div className="p-4 rounded border mt-3" style={data.type === 'scheduled' ? {} : { display: 'none' }}>
                <div className="d-flex">
                    <div className="flex-fill">
                        <Calendar width={18} />
                        <span className="px-1">Com agendamento</span>
                    </div>
                    <div hidden={hideTag} className="px-2 py-1 selected-card">
                        <small>{scheduleTagContent()}</small>
                    </div>
                </div>

                <div>"{getScheduleName()}"</div>
            </div>

            <div className="p-4 rounded border mt-3" style={data.type === 'normal' ? {} : { display: 'none' }}>
                <Target width={18} />
                <span className="text-body2 px-1">Sem agendamento</span>
            </div>

        </div>
    )
}