export const OpenClosedView = (props) => {
    const isPlaceOpen = props.open
    return (
        <div {...props}>
            <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4.22656" r="4" fill={isPlaceOpen ? '#6FCF97' : '#EB5757'} />
            </svg>
            <span className="text-body2 text-gray2 px-2">{isPlaceOpen ? 'Aberto' : 'Fechado'} para pedidos</span>
        </div>
    )
}