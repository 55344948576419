import { useContext, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { State, StateMachine } from "../../../../components/StateMachine"
import { StoreContext } from "../../../../context/StoreContext"
import { Row, Col, Badge } from "reactstrap"
import { RequestsContext } from "../../../../context/requests/RequestsContext"
import { OrderProductCard } from "./components/OrderProductCard"
import { OrderServiceCard } from "./components/OrderServiceCard"
import { ArrowLeft } from "react-feather"
import EditOrderModal from "./modals/EditOrderModal"
import OrderDetailModal from "./modals/OrderDetailModal"
import ButtonTab from "./components/ButtonTab"
import { OpenClosedView } from "./components/OpenClosedView"

const PlaceRequestsKanban = _ => {

    const { placeId } = useParams()

    const store = useContext(StoreContext)
    const requests = useContext(RequestsContext)
    const navigate = useNavigate()

    const place = store.places.get().find((item) => item.id === Number(placeId))

    const [machineState, setMachineState] = useState('products')
    const [orderDetailModal, setOrderDetailModal] = useState({ open: false, order: null })
    const [orderEditModal, setOrderEditModal] = useState({ open: false, order: null })

    const ordersPending = requests.orders.pending.filter((item) => item.placeId === Number(placeId))
    const ordersAccepted = requests.orders.accepted.filter((item) => item.placeId === Number(placeId))
    const ordersDone = requests.orders.concluded.filter((item) => item.placeId === Number(placeId))

    const servicesPending = requests.services.pending.filter(item => item.placeId === Number(placeId))
    const servicesAccepted = requests.services.accepted.filter(item => item.placeId === Number(placeId))
    const servicesConcluded = requests.services.concluded.filter(item => item.placeId === Number(placeId))

    const setEditOrder = (newOrder) => setOrderEditModal({ ...orderEditModal, order: newOrder })

    function onClickOrderDetail(order) {
        setOrderDetailModal({ open: true, order })
    }

    function onClickEditOrder(order) {
        setOrderEditModal({ open: true, order })
    }

    return (
        <div className="h-100 d-flex flex-column">

            <h2 className="center-y text-gray1 gap-2 cursor-pointer" onClick={_ => navigate(-1)}>
                <ArrowLeft color="var(--gray1)" size={20} /> {place.name}
            </h2>

            <OpenClosedView open={place.enabled} className="ms-1" />

            <div className="d-flex justify-content-between align-items-center gap-3 mt-4 mb-3">
                <ButtonTab
                    active={machineState === 'products'}
                    onClick={() => { setMachineState('products') }}>
                    <span className="text-button px-3">Produtos</span>
                    {ordersPending.length > 0 && <Badge color="warning" className="text-caption" pill>{ordersPending.length}</Badge>}
                </ButtonTab>
                <ButtonTab
                    active={machineState === 'services'}
                    onClick={() => { setMachineState('services') }}>
                    <span className="text-button px-3">Serviços</span>
                    {servicesPending.length > 0 && <Badge color="warning" className="text-caption" pill>{servicesPending.length}</Badge>}
                </ButtonTab>
            </div>

            <div className="flex-fill">
                <StateMachine currentStateKey={machineState}>

                    <State stateKey={'products'}>
                        <Row className="g-2 h-100">
                            <Col md={4} className="light-warning-background">
                                <p className="text-body2 text-center text-gray2 p-3">Pendentes ({ordersPending.length})</p>
                                {
                                    ordersPending.map((order, index) => (
                                        <OrderProductCard
                                            key={`orders-pending-${index}`}
                                            className="mx-2 my-3"
                                            onClickOrderDetail={_ => onClickOrderDetail(order)}
                                            onClickEditOrder={_ => onClickEditOrder(order)}
                                            data={order} />
                                    ))
                                }
                            </Col>
                            <Col md={4} className="light-blue-background">
                                <p className="text-body2 text-center text-gray2 p-3">Confirmados ({ordersAccepted.length})</p>
                                {
                                    ordersAccepted.map((order, index) => (
                                        <OrderProductCard
                                            key={`orders-accepted-${index}`}
                                            className="mx-2 my-3"
                                            onClickOrderDetail={_ => onClickOrderDetail(order)}
                                            onClickEditOrder={_ => onClickEditOrder(order)}
                                            data={order} />
                                    ))
                                }
                            </Col>
                            <Col md={4} className="light-green-background">
                                <p className="text-body2 text-center text-gray2 p-3">Finalizados ({ordersDone.length})</p>
                                {
                                    ordersDone.map((order, index) => (
                                        <OrderProductCard
                                            key={`orders-concluded-${index}`}
                                            className="mx-2 my-3"
                                            onClickOrderDetail={_ => onClickOrderDetail(order)}
                                            onClickEditOrder={_ => onClickEditOrder(order)}
                                            data={order} />
                                    ))
                                }
                            </Col>
                        </Row>
                    </State>

                    <State stateKey={'services'}>
                        <Row className="gx-2 h-100">
                            <Col md={4} className="light-warning-background">
                                <p className="text-body2 text-center text-gray2 p-3">Pendentes ({servicesPending.length})</p>
                                {servicesPending.map((serviceRequest, index) => {
                                    return (
                                        <OrderServiceCard
                                            key={`service-pending-${index}`}
                                            className="m-1"
                                            service={serviceRequest} />
                                    )
                                })}
                            </Col>
                            <Col md={4} className="light-blue-background">
                                <p className="text-body2 text-center text-gray2 p-3">Confirmados ({servicesAccepted.length})</p>
                                {servicesAccepted.map((serviceRequest, index) => {
                                    return (
                                        <OrderServiceCard
                                            key={`service-accepted-${index}`}
                                            service={serviceRequest} />
                                    )
                                })}
                            </Col>
                            <Col md={4} className="light-green-background">
                                <p className="text-body2 text-center text-gray2 p-3">Finalizados ({servicesConcluded.length})</p>
                                {servicesConcluded.map((serviceRequest, index) => {
                                    return (
                                        <OrderServiceCard
                                            key={`service-concluded-${index}`}
                                            service={serviceRequest} />
                                    )
                                })}
                            </Col>
                        </Row>
                    </State>

                </StateMachine>
            </div>

            <OrderDetailModal
                toggle={_ => setOrderDetailModal({ open: false, order: null })}
                isOpen={orderDetailModal.open}
                order={orderDetailModal.order} />

            <EditOrderModal
                toggle={_ => setOrderEditModal({ open: false, order: null })}
                isOpen={orderEditModal.open}
                order={orderEditModal.order}
                setOrder={setEditOrder}
            />
        </div>
    )
}

export default PlaceRequestsKanban