const DEFAULT_SIZE = 48

const Avatar = props => {
    var classes = "rounded-circle p-0 bg-light text-white d-flex align-items-center justify-content-center "
    classes += props.className ? props.className : ""

    var width = props.width ? props.width : DEFAULT_SIZE
    var height = props.height ? props.width : DEFAULT_SIZE

    if (width !== height) {
        width = DEFAULT_SIZE
        height = DEFAULT_SIZE
    }

    if (props.image) {
        return (
            <div className={classes}>
                <img 
                    className="rounded-circle p-0 m-0" 
                    src={props.image} style={{ width, height, objectFit: "cover" }} 
                    alt="Avatar" />
            </div>
        )
    }
    
    
    var letter = props.letter

    if (letter) {
        letter = letter.toUpperCase()
        classes += ' border p-1'
    }

    return (
        <div
            className={classes}
            style={{ width, height }} >
            <h4 className="h1 m-0 p-0 text-cyber-dark">{letter[0]}</h4>
        </div>
    )
}

export default Avatar