import axios from "axios";
import { useState } from "react";
import { Edit, MapPin } from "react-feather"
import { Card, CardBody } from "reactstrap"
import { RejectOrderItemConfirmationModal } from "../modals/RejectOrderItemConfirmationModal";
import LoaderButton from "../../../../../components/Button/LoaderButton";
import api from "../../../../../services/api";
import Center from "../../../../../components/Center/Center";
import moment from "moment";

export const OrderProductCard = props => {

    const order = {
        ...props.data,
        isAccepted: props.data.status === 'ACCEPTED',
        isRequested: props.data.status === 'REQUESTED',
        isDone: props.data.status === 'DONE',
    }

    const [state, setState] = useState({ loading: false, error: false, isRejectModalOpen: false })
    const [cancelLoading, setCancelLoading] = useState(false)

    const rejectModalToggle = (rejected) => {
        setState({ ...state, isRejectModalOpen: !state.isRejectModalOpen })
        if (rejected === true) {
            reject()
        }
    }

    function reject() {
        setState(state => ({ ...state, error: false }))
        setCancelLoading(true)

        let requests = order.items.map((item) => api.put(`/v1/orders/${item.orderId}/order-items/${item.id}/reject`))

        axios.all(requests)
            .then(_ => setState(state => ({ ...state, error: false, isRejectModalOpen: false })))
            .catch(_ => setState({ ...state, error: true, isRejectModalOpen: false }))
            .finally(_ => setCancelLoading(false))
    }

    function accept() {
        setState({ loading: true, error: false })
        let requests = order.items.map((item) => api.put(`/v1/orders/${item.orderId}/order-items/${item.id}/accept`))
        axios.all(requests)
            .then(_ => setState({ ...state, loading: false, error: false }))
            .catch(_ => setState({ ...state, loading: false, error: true }))
    }

    function finish() {
        setState({ loading: true, error: false })
        let requests = order.items.map((item) => api.put(`/v1/orders/${item.orderId}/order-items/${item.id}/finish`))
        axios.all(requests)
            .then(_ => setState({ ...state, loading: false, error: false }))
            .catch(_ => setState({ ...state, loading: false, error: true }))
    }

    return (
        <>
            <Card {...props}>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div hidden={!order.deliveryMode}>
                                <Center>
                                    <MapPin color="#666" width={18} />
                                    <span className="text-body1 text-gray1 px-2">{order.deliveryMode}</span>
                                </Center>
                            </div>
                        </div>
                        <div className="cursor-pointer" onClick={_ => props.onClickEditOrder(props.data)}>
                            <Edit width={20} color="#666" />
                        </div>
                    </div>
                    <div className="d-flex flex-column py-3 gap-2">
                        {
                            order.items.map((item, index) => <OrderItem key={`order-item-${order.id}-${item.id}-${index}`} item={item} />)
                        }
                    </div>
                    <div className="d-flex flex-column gap-1">
                        <span
                            className="text-body2 primary-text text-button cursor-pointer"
                            onClick={_ => props.onClickOrderDetail(props.data)}>
                            <b>Pedido #{order.id}</b>
                        </span>
                        <span className="text-body2">{moment(order.createdAt).subtract(3, 'hours').fromNow()}</span>
                    </div>
                    <div className="mt-3 d-flex gap-2">
                        <LoaderButton className="w-100 text-uppercase fw-bold" outline={order.isDone} color={order.isDone ? 'secondary' : 'danger'} onClick={rejectModalToggle} loading={cancelLoading}>{order.isDone ? 'Cancelar' : 'Rejeitar'}</LoaderButton>
                        <LoaderButton className="w-100 text-uppercase fw-bold blue-background blue-border-color" color="primary" hidden={!order.isRequested} loading={state.loading} onClick={accept}>Aceitar</LoaderButton>
                        <LoaderButton className="w-100 text-uppercase fw-bold" color="success" onClick={finish} hidden={!order.isAccepted} loading={state.loading}>Finalizar</LoaderButton>
                    </div>
                </CardBody>
            </Card>
            <RejectOrderItemConfirmationModal
                isOpen={state.isRejectModalOpen}
                toggle={rejectModalToggle} />
        </>
    )
}

const OrderItem = ({ item }) => {
    return (
        <div className="border-radius-4 border p-3">
            <div className="text-body1 text-gray1">{item.quantity}x {item.name}</div>
            {item.details && <div className="text-body2 text-gray2" style={{ marginTop: 12 }}><i>"{item.details}"</i></div>}
        </div>
    )
}