import './index.css'

const OutlinedButton = (props) => {

    let parentClassName = props.className
    let className = parentClassName + ' w-100 OutlinedButton'

    return (
        <button {...props} className={className}>
            {props.children}
        </button>
    )
}

export default OutlinedButton