import { useEffect } from "react"
import { useContext, useState } from "react"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { InputImage } from "../../../../../components/Input/Input"
import { StoreContext } from "../../../../../context/StoreContext"
import Center from "../../../../../components/Center/Center"
import ImageView from "../../../../../components/ImageView/ImageView"
import ProductTypeSuggestionList from "./ProductTypeSuggestionList"

const DELIVERY_OPTIONS = [
    {
        id: 1,
        type: "ROOM",
        description: "Quarto"
    },
    {
        id: 2,
        type: "PLACE",
        description: "Restaurante"
    },
    {
        id: 3,
        type: "HOTEL",
        description: "Portaria do Hotel"
    }
]

const DEFAULT_INITIAL_DATA = {
    id: null,
    image: null,
    name: '',
    price: '',
    place: null,
    type: '',
    description: '',
    delivery: []
}

function buildInitialFormData(product, places) {
    if (!product) {

        let initialData = DEFAULT_INITIAL_DATA

        // set default place
        if (places.length > 0) {
            initialData.place = places[0].id
        }

        return initialData
    }

    let delivery = []
    DELIVERY_OPTIONS.forEach(del => {
        if (product.delivery_modes.filter(e => e.id === del.type).length > 0) {
            delivery.push(del.type)
        }
    })

    let holtePlaceId = (product.hotel_place && product.hotel_place.id) || 0
    return ({
        id: product.id,
        name: product.name,
        image: product.image,
        type: product.product_type,
        price: product.price.number,
        description: product.description,
        place: holtePlaceId,
        delivery: delivery
    })
}

export const ProductForm = ({ product, typeSuggestionList, onFormValid, onFormInvalid }) => {

    const store = useContext(StoreContext)
    const initialData = buildInitialFormData(product, store.places.get())

    const [data, setData] = useState(initialData)

    const setImage = (image) => setData(data => ({ ...data, image }))
    const setName = (name) => setData(data => ({ ...data, name: name }))
    const setPrice = (price) => setData(data => ({ ...data, price: price ? Number(price) : '' }))
    const setPlace = (placeId) => setData(data => ({ ...data, place: placeId }))
    const setDescription = (description) => setData(data => ({ ...data, description: description }))
    const setType = (type) => setData(data => ({ ...data, type: type }))
    const setDelivery = (delivery) => setData(data => ({ ...data, delivery }))

    function handlePrice(event) {
        const price = event.target.value
        const newPrice = (price >= 0) ? price : 0
        setPrice(newPrice)
    }

    function validateForm() {
        let isFormValid = (
            initialData !== data &&
            data.image !== null &&
            data.place !== null &&
            data.name.length > 0 &&
            data.type.length > 0 &&
            data.description.length > 0 &&
            data.price > 0 &&
            data.delivery.length > 0
        )

        if (isFormValid) {
            onFormValid(data)
        } else {
            onFormInvalid(data)
        }
    }

    useEffect(_ => {
        validateForm()
    }, [data])

    return (
        <Form>
            <Center>
                <ImageView src={data.image} alt="Produto" width={300} />
            </Center>

            <FormGroup>
                <Label for='inp-image'>Imagem</Label>
                <InputImage id="inp-image" name="image" onImage={image => setImage(image)} />
            </FormGroup>

            <FormGroup>
                <Label for='inp-name'>Nome</Label>
                <Input value={data.name} id='inp-name' name='name' type='text' placeholder='Digite aqui' onChange={e => setName(e.target.value.trim())} />
            </FormGroup>

            <FormGroup>
                <Label for='inp-type'>Tipo do produto</Label>
                <Input value={data.type} id='inp-type' name='type' type='text' placeholder='Digite aqui' onChange={e => setType(e.target.value.trim())} />
            </FormGroup>

            <ProductTypeSuggestionList
                onClickSuggestion={type => setType(type)}
                visible={data.type.length === 0}
                suggestions={typeSuggestionList} />

            <FormGroup>
                <Label for="inp-place">Local</Label>
                <Input
                    value={data.place || -1}
                    id="inp-place" name="select" type="select" onChange={e => setPlace(e.target.value)}>
                    {store.places.get().map(place => <option key={`place-option-${place.id}`} value={place.id}>{place.name}</option>)}
                </Input>
            </FormGroup>

            <div>
                <Label>Entrega</Label>
                <div className="pb-2">
                    {DELIVERY_OPTIONS.map(i => (
                        // improve me, please :)
                        <FormGroup key={`delivery-option-${i.id}`} check inline>
                            <Input
                                type="checkbox"
                                checked={data.delivery.includes(i.type)}
                                onChange={e => {
                                    let delivery = data.delivery

                                    if (!e.target.checked && delivery.includes(i.type)) {
                                        delivery.splice(delivery.indexOf(i.type), 1)
                                    } else {
                                        delivery.push(i.type)
                                    }

                                    setDelivery(delivery)
                                }} />
                            <Label>{i.description}</Label>
                        </FormGroup>
                    ))}
                </div>
            </div>

            <FormGroup>
                <Label for='inp-price'>Preço</Label>
                <Input value={data.price} id='inp-price' name='price' type='number' placeholder='0,00' step="0.01" onChange={handlePrice} />
            </FormGroup>

            <FormGroup>
                <Label for='inp-description'>Descrição</Label>
                <Input value={data.description} id='inp-description' rows="4" name='description' type="textarea" placeholder="Digite aqui" onChange={e => setDescription(e.target.value)} />
            </FormGroup>
        </Form>
    )
}