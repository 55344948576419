import { Button, Spinner } from "reactstrap"

export const LoaderButton = (props) => {

    let finalClassName = (props.className || "") + " text-button"

    return (
        <Button color="primary" {...props} disabled={props.loading || props.disabled || false} className={finalClassName}>
            {(props.loading) ? <><span className="text-button pe-3">Aguarde..</span><Spinner size="sm">Loading...</Spinner></> : props.children}
        </Button>
    )
} 

export default LoaderButton