import './style.css'

export const Menu = props => {
    return (
        <ul className={"list-group w-100 " + props.className}>{props.children}</ul>
    )
}

export const MenuItem = props => {
    var defaultClasses = "MenuItem body2"

    if (props.active) {
        defaultClasses += " Active"
    }

    return (
        <>
            <li
                onClick={props.onClick}
                className={defaultClasses}>
                <div className='full align-items-center'>
                    {props.icon}
                    <span className='px-3'>{props.name}</span>
                    <span className='float-right'>{props.children !== undefined && props.children.type !== undefined && props.children.type === MenuBadge ? props.children : undefined}</span>
                </div>
            </li>
            {props.children !== undefined && props.children.type !== undefined && props.children.type === MenuBadge ? undefined : props.children}
        </>
    )
}

export const MenuSubItem = props => {
    var defaultClasses = "MenuSubItem"

    if (props.active) {
        defaultClasses = defaultClasses + ' Active body2'
    }

    return (
        <li hidden={!props.visible} className={defaultClasses} onClick={props.onClick}>
            <span className='text-body2 px-3'>{props.name}</span>
        </li>
    )
}

export const MenuBadge = props => {
    var defaultClasses = "MenuBadge " + props.backgroundColor
    return (
        <span style={{ fontSize: 14 }} hidden={props.hidden} className={defaultClasses}>{props.value}</span>
    )
}