import { Card, CardBody } from "reactstrap"
import ImageView from "../../../../components/ImageView/ImageView"

const CampaingImage = ({ image }) => <ImageView src={image} width={80} height={80} className='rounded' alt='Campaign image' />

const CampaignDetailButton = () => (
    <div className="center">
        <span className="text-primary text-button me-3">Ver detalhes</span>
    </div>
)

const CampaignContent = ({ campaign }) => (
    <div className="flex-fill flex-column d-flex justify-content-center px-3 py-1 gap-2">
        <h6 className="m-0 text-subtitle1 text-gray1">{campaign.title}</h6>
        <p className="m-0 text-body2 text-gray2">{campaign.short_description}</p>
        <span className="m-0 text-overline text-gray3">{campaign.segmentation}</span>
    </div>
)

const CampaignRow = ({ campaign, onClick }) => {
    return (
        <Card className="cursor-pointer mb-2" onClick={onClick}>
            <CardBody>
                <div className="d-flex  justify-content-between">
                    <CampaingImage image={campaign.image} />
                    <CampaignContent campaign={campaign} />
                    <CampaignDetailButton />
                </div>
            </CardBody>
        </Card>
    )
}

export default CampaignRow