const SETTINGS_PLACES_KEY = 'settings-notifications-places'
const SETTINGS_OPERATOR_AUTO_CLEAR_KEY = 'settings-operator-auto-clear'

/**
 * Helper function - Map the place to add settings information inside place object 
 */
export const mapPlaceSettings = (place) => {

    let notifications = {
        active: true,
        timeActive: false,
        startTime: null,
        endTime: null
    }

    getPlaces().forEach(mPlace => {
        if (mPlace.id === place.id) {
            notifications.active = mPlace.active
            notifications.timeActive = (mPlace.start_time !== null && mPlace.end_time !== null && mPlace.start_time.length > 0 && mPlace.end_time.length > 0)
            notifications.startTime = mPlace.start_time
            notifications.endTime = mPlace.end_time
        }
    })

    return { ...place, notifications }
}

/**
 * 
 * Get the places settings from the local storage
 * 
 * @returns array of places 
 */
const getPlaces = () => {
    try {
        let content = localStorage.getItem(SETTINGS_PLACES_KEY)
        let stored = JSON.parse(content)
        return Array.isArray(stored) ? stored : []
    } catch (error) {
        return []
    }
}

/**
 * 
 * Sets the place settings at local storage
 * 
 */
const savePlaces = (places) => {
    localStorage.setItem(SETTINGS_PLACES_KEY, JSON.stringify(places))
}

/**
 * Operator Auto Clear
 */
const isOperatorAutoClearEnabled = () => {
    try {
        return localStorage.getItem(SETTINGS_OPERATOR_AUTO_CLEAR_KEY) === 'true'
    } catch (error) {
        return false
    }
}

const setAutoClearEnabled = (enabled) => {
    localStorage.setItem(SETTINGS_OPERATOR_AUTO_CLEAR_KEY, enabled)
}

/**
 * Exportable object
 */
const AlohaSettings = {
    operators: {
        isAutoClearEnabled:  isOperatorAutoClearEnabled,
        setAutoClearEnabled: setAutoClearEnabled
    },
    notifications: {
        getPlaces,
        savePlaces
    }
}

export default AlohaSettings