import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Whatsapp = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg ref={ref} {...rest} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24ZM39.0621 23.0815C39.059 30.9483 32.6595 37.3493 24.7911 37.3525H24.7852C22.3967 37.3517 20.0498 36.7524 17.9653 35.6155L10.4 37.6L12.4246 30.2048C11.1757 28.0405 10.5186 25.5855 10.5196 23.0702C10.5228 15.2017 16.9248 8.8 24.7909 8.8C28.6086 8.80165 32.1918 10.2879 34.8862 12.9854C37.5806 15.6828 39.0637 19.2683 39.0621 23.0815Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.827 34.2096L18.3164 33.0319L18.7495 33.289C20.5707 34.3697 22.6583 34.9415 24.7868 34.9424H24.7916C31.3294 34.9424 36.6504 29.6212 36.653 23.0807C36.6543 19.9112 35.4217 16.9311 33.1822 14.689C30.9427 12.4469 27.9645 11.2115 24.7963 11.2104C18.2534 11.2104 12.9323 16.5311 12.9297 23.0711C12.9288 25.3124 13.5559 27.4951 14.7432 29.3836L15.0253 29.8324L13.827 34.2096ZM31.4929 26.1541C31.7416 26.2743 31.9096 26.3554 31.9813 26.4751C32.0704 26.6238 32.0704 27.3378 31.7734 28.1709C31.4761 29.0038 30.0516 29.764 29.3664 29.8663C28.7521 29.9582 27.9747 29.9965 27.1205 29.725C26.6025 29.5607 25.9384 29.3413 25.0876 28.9739C21.7447 27.5304 19.4855 24.2904 19.0586 23.678C19.0287 23.6351 19.0077 23.6051 18.9961 23.5896L18.9932 23.5857C18.8045 23.3339 17.54 21.6468 17.54 19.9008C17.54 18.2583 18.3469 17.3973 18.7183 17.001C18.7437 16.9739 18.7671 16.9489 18.7881 16.926C19.1149 16.569 19.5012 16.4798 19.739 16.4798C19.9766 16.4798 20.2146 16.482 20.4224 16.4924C20.448 16.4937 20.4747 16.4935 20.5023 16.4934C20.7101 16.4921 20.9691 16.4906 21.2247 17.1045C21.323 17.3408 21.4669 17.691 21.6186 18.0603C21.9254 18.8074 22.2645 19.6328 22.3241 19.7522C22.4133 19.9307 22.4727 20.1389 22.3538 20.377C22.336 20.4127 22.3195 20.4464 22.3037 20.4785C22.2145 20.6608 22.1488 20.7949 21.9973 20.9718C21.9377 21.0414 21.8761 21.1164 21.8145 21.1913C21.6918 21.3407 21.5692 21.4901 21.4624 21.5965C21.2839 21.7743 21.098 21.9673 21.306 22.3243C21.514 22.6813 22.2297 23.8489 23.2898 24.7945C24.4293 25.8109 25.4197 26.2405 25.9217 26.4583C26.0197 26.5008 26.0991 26.5352 26.1574 26.5644C26.5138 26.7429 26.7219 26.7131 26.9299 26.4751C27.1379 26.2372 27.8213 25.4339 28.059 25.0769C28.2966 24.7201 28.5345 24.7795 28.8612 24.8985C29.1882 25.0176 30.9413 25.8801 31.2979 26.0586C31.3675 26.0935 31.4326 26.1249 31.4929 26.1541Z" fill={color}/>
        </svg>
    );
});

Whatsapp.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Whatsapp.displayName = 'Whatsapp';

export default Whatsapp;