import axios from "axios";
import { getToken, logout } from "./auth";
import { LOGIN_URL } from "./aloha-api";

/**
 * @deprecated Please try to use the method inside AlohaApi
 * 
 * @see {@link AlohaApi}
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});

api.interceptors.response.use(
  response => response,
  error => {
      /**
       * Whenever a request was not authorized, login is required
       */
      if (error.response.status === 401 && error.config.url !== LOGIN_URL) {
          logout() // Clear the the stored token
          window.location.href = '/login'; // force navigation to login screen
      } else {
          return Promise.reject(error);
      }
  }
)

api.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = '@l0h4'
  }

  return config;
});

export default api;