import { useEffect, useState } from "react"
import { Button, Pagination, PaginationItem, PaginationLink, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Center from "../../../../components/Center/Center"
import StateView from "../../../../components/StateView"
import AlohaApi from "../../../../services/aloha-api"
import CampaignRegisterModal from "./CampaignRegisterModal"
import CampaignRow from "./CampaignRow"

function getSegmentationByType(type) {
    switch (type) {
        case 'ALL':
            return 'Todos'
        case 'HOSTED':
            return 'Somente hóspedes'
        case 'NOT_HOSTED':
            return 'Somente ex-hóspedes'
        default:
            return ''
    }
}

const Campaigns = () => {

    const [page, setPage] = useState(1)
    const [version, setVersion] = useState(1)
    const [campaignId, setCampaignId] = useState(0)

    const [campaigns, setCampaigns] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [registerOpen, setRegisterOpen] = useState(false)
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasNext, setHasNext] = useState(false)

    const openRegisterModal = () => setRegisterOpen(true)
    
    const navigate = useNavigate()
    
    const nextPage = () => setPage(v => v + 1)
    const previousPage = () => setPage(v => v - 1)
    const refresh = () => setVersion(v => v + 1)

    useEffect(_ => {
        setLoading(true)
        setHasPrevious(page > 1)

        AlohaApi.fetchCampaigns({ page: page })
            .then(onSuccess)
            .catch(_ => setError(true))
            .finally(_ => setLoading(false))
    }, [page, version])

    useEffect(_ => {
        if(campaignId !== 0){
            navigate(`${campaignId}`)
        }
    }, [campaignId])

    const onSuccess = (res) => {
        let campaigns = res.data.result.map(el => {
            let segmentation = getSegmentationByType(el.audience_segmentation)
            return { ...el, segmentation }
        })

        setCampaigns(campaigns)

        if (res.data.has_next_page === true) {
            setHasNext(true)
        } else {
            setHasNext(false)
        }
    }

    const shouldShowData = !loading && campaigns.length > 0
    const shouldShowLoadingState = loading
    const shouldShowEmptyState = !loading && !error && campaigns.length === 0
    const shouldShowErrorState = !loading && error

    return (
        <>
            <StateView show={shouldShowData}>
                <div>
                    <div className="d-flex justify-content-end mb-4">
                        <Button onClick={openRegisterModal} color="primary" className="text-button">Nova campanha</Button>
                    </div>
                    {campaigns.map((campaign, index) => {
                        return (
                            <CampaignRow
                                key={`campaign-item-${campaign.id}-${index}`}
                                campaign={campaign}
                                divider={index < (campaign.length - 1)}
                                onClick={() => { setCampaignId(campaign.id) }} />
                        )
                    })}
                    <Pagination className="mt-auto ms-auto p-3 display-inline-block float-right">
                        <PaginationItem disabled={!hasPrevious}>
                            <PaginationLink previous onClick={e => { e.preventDefault(); previousPage() }}>Anterior</PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!hasNext}>
                            <PaginationLink next onClick={e => { e.preventDefault(); nextPage() }}>Próximo</PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </div>
            </StateView>

            <StateView show={shouldShowEmptyState}>
                <Center className="h-100 flex-column">
                    <p className="text-body1 text-gray2 mb-2">Nenhuma campanha cadastrada</p>
                    <Button onClick={_ => setRegisterOpen(true)} className="text-button" color="primary">+ Adicionar campanha</Button>
                </Center>
            </StateView>

            <StateView show={shouldShowLoadingState}>
                <Center className="h-100 flex-column">
                    <Spinner />
                </Center>
            </StateView>

            <StateView show={shouldShowErrorState}>
                <Center className="h-100 flex-column">
                    <span>Desculpe, ocorreu um erro!</span>
                </Center>
            </StateView>

            <CampaignRegisterModal
                open={registerOpen}
                onRegister={_ => refresh()}
                toggle={_ => setRegisterOpen(!registerOpen)} />
        </>
    )
}

export default Campaigns