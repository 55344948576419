import axios from "axios";
import { useState } from "react";
import { Minus, Plus, Trash2 } from "react-feather";
import { Button, Row, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Select, { createFilter } from "react-select";
import ImageView from "../../../../../components/ImageView/ImageView";
import api from "../../../../../services/api";
import LoaderButton from "../../../../../components/Button/LoaderButton";

const EditOrderModal = props => {

    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [updatedItems, setUpdatedItems] = useState([])
    const [removedItems, setRemovedItems] = useState([])
    const [addedItems, setAddedItems] = useState([])
    const [order, setOrder] = useState(undefined)
    const [orderDetails, setOrderDetails] = useState({})


    function loadInformations() {
        setOrder(props.order)
        loadLoadProduct(0, [])
    }

    function loadLoadProduct(page, result) {
        
        api.get(`/v1/orders/${props.order.id}`)
            .then(res => setOrderDetails(res.data))

        const onSuccess = (res, tmp) => {
            let allProducts = res.data.products
            let nextPage = res.data.next_page

            if (nextPage !== null) {
                loadLoadProduct(nextPage, [...tmp, ...allProducts])
            } else {
                setProducts([...tmp, ...allProducts])
            }
        }

        let pathUrl = `/v1/products?page=${page}`

        api.get(pathUrl)
            .then((res) => {
                onSuccess(res, result)
                setLoading(false)
            })
            .catch(_ => { })
    }

    function onSelectProduct(product) {
        let productId = Number(product.value)
        let newItem = {
            'id': undefined,
            'index': addedItems.length,
            'externalId': productId,
            'orderId': order.id,
            'quantity': 1,
            'name': products.find((product) => product.id == productId).name,
            'imageUrl': products.find((product) => product.id == productId).image,
            'unitPrice': products.find((product) => product.id == productId).price.number,
            'itemPrice': products.find((product) => product.id == productId).price.number,
            'state': order.status,
            'deliveryModeId': order.deliveryModeId
        }

        setAddedItems(
            [
                ...addedItems,
                newItem
            ]
        )

        setOrder(
            {
                ...order,
                items: [
                    ...order.items,
                    newItem
                ]
            }
        )
    }

    function onClickRemoveItem(item) {
        if(item.id == undefined) {
            removeNewItem(item)
        } else {
            removeAlreadyExists(item)
        }
    }

    function removeNewItem(item) {
        setAddedItems(
            [
                ...addedItems.filter((added) => added.index != item.index)
            ]
        )

        setOrder(
            {
                ...order,
                items: [
                    ...order.items.filter((orderItem) => orderItem.index != item.index)
                ]
            }
        )
    }

    function removeAlreadyExists(item) {
        setRemovedItems(
            [
                ...removedItems,
                {
                    "orderId": item.orderId,
                    "orderItemId": item.id
                }
            ]
        )

        setOrder({
            ...order,
            items: order.items.filter((orderItem) => orderItem.id !== item.id)
        })

        setUpdatedItems([...updatedItems.filter((updatedItem) => updatedItem.orderItemId != item.id)])
    }

    function onClickDecrementQuantity(item) {
        if(item.quantity > 1) {
            let updatedItem = {
                ...item,
                quantity: item.quantity - 1,
                itemPrice: item.itemPrice - item.unitPrice
            }

            updateItemList(updatedItem);
        }
    }

    function onClickIncrementQuantity(item) {
        let updatedItem = {
            ...item,
            quantity: item.quantity + 1,
            itemPrice: item.itemPrice + item.unitPrice
        }

        updateItemList(updatedItem);
    }

    function updateItemList(item) {
        let orderItemId = item.id
        let orderId = item.orderId
        
        if(orderItemId === undefined) {
            newItemUpdate(item)
        } else {
            alreadyExistsItemUpdate(orderItemId, orderId, item)
        }
    }

    function newItemUpdate(newItem) {
        setAddedItems(
            [
                ...addedItems.map((item) => {
                    if(item.index == newItem.index){
                        return newItem
                    }

                    return item
                })
            ]
        )

        setOrder(
            {
                ...order,
                items: [
                    ...order.items.map((item) => {
                        if(item.index == newItem.index){
                            return newItem
                        }

                        return item
                    })
                ]
            }
        )
    }

    function alreadyExistsItemUpdate(orderItemId, orderId, item) {
        if(updatedItems.some((item) => item.orderItemId == orderItemId)) {
            setUpdatedItems(
                [
                    ...updatedItems.map((updatedItem) => {
                        if(updatedItem.orderItemId == item.id) {
                            return {
                                "orderId": orderId,
                                "orderItemId": orderItemId,
                                "quantity": item.quantity,
                                "details": item.details
                            }
                        }

                        return updatedItem
                    })
                ]
            )
        } else {
            setUpdatedItems(
                [
                    ...updatedItems,
                    {
                        "orderId": orderId,
                        "orderItemId": orderItemId,
                        "quantity": item.quantity,
                        "details": item.details
                    }
                ]
            )
        }
    
        setOrder(
            {
                ...order,
                items: [
                    ...order.items.map((orderItem) => {
                        if(orderItem.id == item.id) {
                            return item
                        }

                        return orderItem
                    })
                ]
            }
        )
    }

    function save() {
        let requests = [
            ...updatedItems.map((updateItem) => {
                api.put(`/v1/orders/${updateItem.orderId}/order-items/${updateItem.orderItemId}`, {
                    details: updateItem.details,
                    quantity: updateItem.quantity
                })
            }),
            ...removedItems.map((removedItem) => api.put(`/v1/orders/${removedItem.orderId}/order-items/${removedItem.orderItemId}/reject`)),
            ...addedItems.map((addItem) => api.post(
                `/v1/orders/${addItem.orderId}/order-items`, 
                {
                    guest_id: orderDetails.guest_info.id,
                    order_item: {
                        external_id: addItem.externalId,
                        quantity: addItem.quantity,
                        unit_price : {
                            currency: "BRL",
                            number: addItem.unitPrice
                        },
                        delivery_mode_id: addItem.deliveryModeId,
                        state: addItem.state
                    }
                }
            ))
        ]

        setLoading(true)
        axios.all(requests).then(res => {
            setLoading(false)
            props.toggle()
        })
    }

    function cleanUp() {
        setUpdatedItems([])
        setRemovedItems([])
        setAddedItems([])
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" onOpened={loadInformations} onClosed={cleanUp}>
            <ModalHeader toggle={props.toggle}>
                <span className="text-h6">Pedido #{order ? order.id : ''}</span>
            </ModalHeader>
            <ModalBody className="p-4">
                <Label>Adicione um produto ao pedido</Label>

                <SearchableProductSelect
                    products={products}
                    onSelectProduct={onSelectProduct} />

                {order && order.items.map((item) => (
                    <ProductItem
                        key={item.id}
                        item={item}
                        onClickIncrementQuantity={onClickIncrementQuantity}
                        onClickDecrementQuantity={onClickDecrementQuantity}
                        onClickRemoveItem={onClickRemoveItem} />
                ))}

            </ModalBody>
            <ModalFooter>
                <LoaderButton onClick={save} color="primary" loading={loading}>Salvar</LoaderButton>
                <Button onClick={props.toggle} className="ms-2 text-button">Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const ProductItem = ({ item, onClickRemoveItem, onClickIncrementQuantity, onClickDecrementQuantity }) => {

    if (!item) {
        return <></>
    }

    return (
        <>
            <hr />
            <Row style={{ minHeight: 80 }}>
                <Col xs="auto">
                    <ImageView className="rounded" width={60} height={60} src={item.imageUrl} />
                </Col>
                <Col>
                    <div className="d-flex flex-column gap-3">
                        <span className="px-1 text-body1 text-gray1">{item.name}</span>
                        <span className="px-1 text-body2 text-gray2"><i>{item.details}</i></span>
                        <div className="d-flex align-items-center gap-2">
                            <span className="rounded cursor-pointer px-1 py-1" onClick={_ => onClickDecrementQuantity(item)} ><Minus /></span>
                            <span className="px-2">{item.quantity}</span>
                            <span className="rounded cursor-pointer px-1 py-1" onClick={_ => onClickIncrementQuantity(item)}><Plus /></span>
                        </div>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="h-100 d-flex d-flex flex-column align-items-end justify-content-between">
                        <span className="text-body1 text-gray1">R$ {item.itemPrice}</span>
                        <div className="border rounded text-center cursor-pointer py-2" style={{ width: 44 }} onClick={_ => onClickRemoveItem(item)}>
                            <Trash2 color="#D84B4B" width={18} />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const SearchableProductSelect = ({ products, onSelectProduct }) => {

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
        stringify: option => `${option.label}`,
    }

    const productOptions = products.map(item => ({ 'value': item.id, 'label': item.name }))

    return (
        <Select
            isSearchable
            isClearable
            options={productOptions}
            filterOption={createFilter(filterConfig)}
            onChange={(item) => onSelectProduct(item)} />
    )
}

export default EditOrderModal