import { useContext, useEffect } from "react"
import { useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { run } from "../../../../utils/func"
import { AccountCard, EditButton } from "./Common"
import { ToastContext } from "../../../../context/ToastContext"
import api from "../../../../services/api"
import AlohaApi from "../../../../services/aloha-api"

const AccountAddress = _ => {

    const toast = useContext(ToastContext)

    const [state, setState] = useState({ data: null, loading: true, error: false })
    const [stateVersion, setStateVersion] = useState(1)

    const [updateData, setUpdateData] = useState({ city: '', street: '', number: '' })
    const [updateModal, setUpdateModal] = useState({ open: false, loading: false })
    const setUpdateCity = (city) => setUpdateData(s => ({ ...s, city }))
    const setUpdateStreet = (street) => setUpdateData(s => ({ ...s, street }))
    const setUpdateNumber = (number) => setUpdateData(s => ({ ...s, number }))
    const toogleUpdateModal = () => setUpdateModal(s => ({ ...s, open: !s.open }))

    const isValidUpdateForm = run(_ => {
        let validCity = updateData.city && updateData.city.trim().length > 3
        let validStreet = updateData.street && updateData.city.trim().length > 3
        let validNumber = updateData.number && updateData.city.trim().length > 0
        return validCity && validStreet && validNumber
    })

    const postUpdateData = () => {
        setUpdateModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Endereço atualizado com sucesso!')
            setUpdateData({ city: '', street: '', number: '' })
            setStateVersion(v => v += 1)
        }

        //  non used for now
        delete updateData.latitude
        delete updateData.longitude
        delete updateData.country
        delete updateData.post_code
        delete updateData.neighborhood

        api.put('address', updateData)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setUpdateModal(s => ({ ...s, loading: false, open: false })))

    }

    useEffect(_ => {
        AlohaApi.fetchAddress()
            .then(res => {
                setUpdateData(res.data)
                setState({ data: res.data, loading: false, error: false })
            })
            .catch(_ => setState({ data: null, loading: false, error: true }))
    }, [stateVersion])

    return (
        <>
            <AccountCard loading={state.loading} error={state.error}>
                <div className="d-flex justify-content-between">
                    <h5 className="body2 fw-bold text-gray1">Endereço</h5>
                    <EditButton onClick={toogleUpdateModal} />
                </div>
                <div className="mt-2">
                    <span className="body2 text-gray2">Cidade</span>
                    <p className="mt-1 text-gray3">{state.data && state.data.city}</p>

                    <span className="body2 text-gray2">Rua</span>
                    <p className="mt-1 text-gray3">{state.data && state.data.street}</p>

                    <span className="body2 text-gray2">Número</span>
                    <p className="mt-1 mb-0 text-gray3">{state.data && state.data.number}</p>
                </div>
            </AccountCard>

            <Modal isOpen={updateModal.open} toggle={toogleUpdateModal}>
                <ModalHeader toggle={toogleUpdateModal}>Atualizar endereço</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="inp-city">Cidade</Label>
                            <Input value={updateData.city} id="inp-city" type="text" placeholder="Digite aqui" onChange={e => setUpdateCity(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="inp-street">Rua</Label>
                            <Input value={updateData.street} id="inp-street" type="text" placeholder="Digite aqui" onChange={e => setUpdateStreet(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="inp-number">Número</Label>
                            <Input value={updateData.number} id="inp-number" type="text" placeholder="Digite aqui" onChange={e => setUpdateNumber(e.target.value)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={postUpdateData} color="primary" disabled={!isValidUpdateForm || updateModal.loading}>
                        {(updateModal.loading) ? <><span className="pe-3">Salvando</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>
                    <Button color="secondary" onClick={toogleUpdateModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AccountAddress