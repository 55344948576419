import { useState } from "react"
import { Card } from "reactstrap"
import { toTimeAgo } from "../../../../../utils/func"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import api from "../../../../../services/api"
import moment from "moment"

export const OrderServiceCard = (props) => {

    const serviceRequest = props.service

    const [state, setState] = useState({ loading: false, error: false, isRejectModalOpen: false })
    const [cancelLoading, setCancelLoading] = useState(false)

    function reject() {
        setState({ loading: true, error: false })
        setCancelLoading(true)
        api.put(`/v1/service-requests/${serviceRequest.id}/reject`)
            .then(_ => setState({ ...state, loading: false, error: false, isRejectModalOpen: false }))
            .catch(_ => setState({ ...state, loading: false, error: true, isRejectModalOpen: false }))
            .finally(_ => setCancelLoading(false))
    }

    function accept() {
        setState({ loading: true, error: false })
        api.put(`/v1/service-requests/${serviceRequest.id}/accept`)
            .then(_ => setState({ ...state, loading: false, error: false }))
            .catch(_ => setState({ ...state, loading: false, error: true }))
    }

    function finish() {
        setState({ loading: true, error: false })
        api.put(`/v1/service-requests/${serviceRequest.id}/finish`)
            .then(_ => setState({ ...state, loading: false, error: false }))
            .catch(_ => setState({ ...state, loading: false, error: true }))
    }

    return (
        <Card className="m-2" {...props}>
            <div className="p-3">
                <h6 className="text-body2 mb-1">Solicitação #{serviceRequest.id}</h6>
                <span className="text-body2 text-gray3 fw-light">{toTimeAgo(serviceRequest.createdAt)}</span>
            </div>

            <div className="bg-light p-3">
                <h6 className="text-body2 mb-1"><span className="text-caption text-uppercase text-gray2">Serviço:</span> {serviceRequest.title}</h6>
                <ServiceScheduleInfo service={serviceRequest} />
                <div className="mt-1">
                    <span className="text-body2 text-gray2 fw-light">
                        {(serviceRequest.detail === "") || (serviceRequest.detail === undefined) || (serviceRequest.detail === null) ? "Sem detalhes adicionais" : <i>"{serviceRequest.detail}"</i>}
                    </span>
                </div>
            </div>

            <div className="mt-3 px-3">
                <span className="text-body2"><span className="text-gray2">Hóspede:</span> {serviceRequest.guest.name}</span> <br />
            </div>

            <div className="p-3 d-flex gap-2">
                <LoaderButton className="w-100 text-uppercase fw-bold" outline={serviceRequest.isConcluded} color={serviceRequest.isConcluded ? 'secondary' : 'danger'} loading={cancelLoading} onClick={reject}>{serviceRequest.isConcluded ? 'Cancelar' : 'Rejeitar'}</LoaderButton>
                <LoaderButton className="w-100 text-uppercase fw-bold blue-background blue-border-color" color="primary" hidden={!serviceRequest.isPending} loading={state.loading} onClick={accept}>Aceitar</LoaderButton>
                <LoaderButton className="w-100 text-uppercase fw-bold" color="success" onClick={finish} hidden={!serviceRequest.isAccepted} loading={state.loading} >Finalizar</LoaderButton>
            </div>
        </Card>
    )
}

const ServiceScheduleInfo = ({ service }) => {

    if (service.scheduleInfo && service.scheduleInfo.startDateTime && service.scheduleInfo.endDateTime) {
        let startTime = moment(service.scheduleInfo.startDateTime).format('lll')
        let endTime = moment(service.scheduleInfo.endDateTime).format('lll')
        return (
            <div>
                <span className="text-body2"><span className="text-caption text-uppercase text-gray2">Início:</span> {startTime}</span> <br />
                <span className="text-body2"><span className="text-caption text-uppercase text-gray2">Término:</span> {endTime}</span> <br />
            </div>
        )
    }

    return <></>
}