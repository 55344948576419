import { ToastProvider } from '../context/ToastContext';
import React from 'react';
import AppRouter from './router';
import ToastContainer from '../components/Toast';
import moment from 'moment';
import ptLocale from 'moment/locale/pt-br'

const App = () => {

  /**
   * Set default moment locale
   */
  moment().locale('pt', [ptLocale])

  return (
    <ToastProvider>
      <AppRouter />
      <ToastContainer />
    </ToastProvider>
  )
}

export default App;
