import { Button, Spinner } from "reactstrap";
import React, { useState } from "react";
import AlohaApi from "../services/aloha-api";
import StateView from "../components/StateView";
import ConnectionError from "../components/Icons/ConnectionError";

const defaultListStore = {
    get: () => [],
    set: (_) => { },
    refresh: () => { }
}

const initialStore = {
    operators: defaultListStore,
    rooms: defaultListStore,
    places: defaultListStore,
}

export const StoreContext = React.createContext(initialStore)

export const StoreProvider = ({ children }) => {

    const [storeVersion, setStoreVersion] = useState(1)
    const [storeState, setStoreState] = useState({ initialized: false, loading: false, error: false })

    const showStoreLoading = () => setStoreState({ initialized: false, loading: true, error: false, })
    const hideStoreLoading = () => setStoreState(store => ({ ...store, loading: false }))
    const setStoreError = () => setStoreState(store => ({ ...store, error: true }))
    const setStoreInitialized = () => setStoreState(store => ({ ...store, initialized: true }))

    // operators region

    const operatorsRef = React.useRef([])
    const roomsRef = React.useRef([])
    const placesRef = React.useRef([])

    // #### --- fetch functions

    const fetchOperators = () => AlohaApi.fetchOperators().then(operators => { operatorsRef.current = operators })
    const fetchRooms = () => AlohaApi.fetchRooms().then(res => { roomsRef.current = res.data })
    const fetchPlaces = () => AlohaApi.fetchPlaces().then(places => { placesRef.current = places })

    // #### --- init store

    const createStoreObject = (ref, request) => ({
        get: () => ref.current,
        set: (data) => { ref.current = data },
        refresh: () => request()
    })

    const initialStore = {
        operators: createStoreObject(operatorsRef, fetchOperators),
        rooms: createStoreObject(roomsRef, fetchRooms),
        places: createStoreObject(placesRef, fetchPlaces)
    }

    React.useEffect(_ => {
        showStoreLoading()

        let requests = [
            fetchOperators(),
            fetchRooms(),
            fetchPlaces()
        ]

        Promise.all(requests)
            .then(_ => setStoreInitialized())
            .catch(_ => setStoreError())
            .finally(_ => hideStoreLoading())

    }, [storeVersion])

    return (
        <StoreContext.Provider value={initialStore}>
            <StateView show={storeState.initialized}>
                {children}
            </StateView>
            <StateView show={storeState.loading}>
                <div className="full center">
                    <Spinner>Carregando...</Spinner>
                </div>
            </StateView>
            <StateView show={storeState.error}>
                <div className="full center flex-column">
                    <ConnectionError size={48} color={"#CCCCCC"} />
                    <h6 className="my-4">Não foi possível carregar as informações</h6>
                    <Button color="primary" onClick={_ => setStoreVersion(v => (v + 1))}>Tentar novamente</Button>
                </div>
            </StateView>
        </StoreContext.Provider>
    )
}