import ImageView from "../../ImageView/ImageView"

import OfferProductImage from './../../../assets/images/offer/post-product.jpg'

export const CardOffer = ({ className, visible, onClick }) => {
    return (
        <div hidden={!visible} className={className}>
            <div className="d-flex rounded bg-white shadow-sm ripple cursor-pointer" onClick={onClick}>
                <div className="d-none d-md-block py-3 ms-3">
                    <ImageView src={OfferProductImage} width={130} height={130} className="rounded" />
                </div>
                <div className="d-flex flex-column p-3">
                    <div className="flex-fill">
                        <h6 className="text-subtitle1 text-gray1 mb-3">Crie postagens exclusivas e aumente suas vendas</h6>
                        <p className="text-body2 text-gray2 mb-3">Maximize o potencial de vendas por meio de postagens exclusivas para seus hóspedes!<br />Mostre os benefícios e destaque as ofertas especiais disponíveis.</p>
                    </div>
                    <div className="text-button text-primary">Criar postagem</div>
                </div>
            </div>
        </div>
    )
}