import React, { useEffect, useState } from "react";
import { Edit, Minimize, MoreVertical, Trash } from "react-feather";
import { Button, Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from "reactstrap";
import { State, StateMachine } from "../../../../components/StateMachine";
import { CreatePostModal } from "./modals/CreatePostModal";
import { DeletePostModal } from "./modals/DeletePostModal";
import { EditPostModal } from "./modals/EditPostModal";
import { ViewPostDetailsModal } from "./modals/ViewPostDetailsModal";
import { useLocation } from "react-router-dom";
import api from "../../../../services/api";
import ImageView from "../../../../components/ImageView/ImageView";

const typeName = {
    'HOTEL_SERVICE': 'Serviço do hotel',
    'HOTEL_PRODUCT': 'Produto do hotel',
    'HOTEL_GENERAL': 'Post genérico',
    'EXTERNAL_LINK': 'Link externo',
    'PARTNER_SERVICE': 'Serviço de parceiro',
    'PARTNER_PRODUCT': 'Produto de parceiro'
}

const tagName = {
    'RESTAURANT': 'Restaurante',
    'TRIP': 'Viagem',
    'PLACE': 'Lugar'
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Posts = () => {

    const query = useQuery()
    const hasCreateQuery = query.get('action') === 'create'

    const [page, setPage] = useState(1)
    const [version, setVersion] = useState(1)

    const [posts, setPosts] = useState([])
    const [hasNextPage, setHasNextPage] = useState(true)
    const [isCreatePostOpen, setCreatePostOpen] = useState(hasCreateQuery || false)
    const [isEditPostOpen, setEditPostOpen] = useState(false)
    const [isDeletePostOpen, setDeletePostOpen] = useState(false)
    const [isDetailsPostOpen, setDetailsPostOpen] = useState(false)
    const [selectedPostId, setSelectedPostId] = useState(0)

    const createPostToggle = () => setCreatePostOpen(!isCreatePostOpen)
    const editPostToggle = () => setEditPostOpen(!isEditPostOpen)
    const deletePostToggle = () => setDeletePostOpen(!isDeletePostOpen)
    const detailsPostToggle = () => setDetailsPostOpen(!isDetailsPostOpen)

    const [machineState, setMachineState] = useState('loading')

    useEffect(fetchPosts, [page, version])

    function fetchPosts() {
        // show loading before request
        setMachineState('loading')
        api.get('/v1/posts?page=' + page)
            .then(res => {
                let data = res.data.result
                let isEmpty = data.length === 0

                // change state
                setMachineState(isEmpty ? 'empty' : 'data')

                setPosts(data)
                setHasNextPage(res.data.has_next_page)
            })
            .catch(err => {
                // show error
                setMachineState('error')
            })
    }

    function reload() {
        setVersion(version + 1)
    }

    function goToPreviousPage() {
        setPage(page - 1)
    }

    function goToNextPage() {
        setPage(page + 1)
    }

    function openEditModal(postId) {
        setSelectedPostId(postId)
        editPostToggle()
    }

    function openDeleteModal(postId) {
        setSelectedPostId(postId)
        deletePostToggle()
    }

    function openDetailsModal(postId) {
        setSelectedPostId(postId)
        detailsPostToggle()
    }

    return (
        <Card className="h-100">
            <Container className="p-4">
                <Row>
                    <Col></Col>
                    <Col md="auto">
                        <Button className="text-button px-4" block color='primary' onClick={createPostToggle}>Nova postagem</Button>
                    </Col>
                </Row>
            </Container>

            <StateMachine currentStateKey={machineState}>

                <State stateKey={'loading'}>
                    <div className="position-absolute top-50 w-100 center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </State>

                <State stateKey={'error'}>
                    <div className="h-100 center">
                        Desculpe, ocorreu um erro!
                    </div>
                </State>

                <State stateKey={'empty'}>
                    <div className="h-100 center">
                        Nenhuma postagem adicionada
                    </div>
                </State>

                <State stateKey={'data'}>
                    <Table data-testid="posts-table" responsive>
                        <thead>
                            <tr>
                                <th scope="col"><span className="ps-3">Título</span></th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Tags</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                posts.map((post, index) => {
                                    return (
                                        <tr key={`post-item-${index}`}>
                                            <td>
                                                <ImageView className="rounded-circle ms-4" src={post.images[0]} alt={post.title} width={36} height={36} />
                                                <span className="text-subtitle2 px-3">{post.title}</span>
                                            </td>
                                            <td>
                                                {typeName[post.relation.type]}
                                            </td>
                                            <td>
                                                {post.tags.map((tag) => tagName[tag]).join(', ')}
                                            </td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className='btn-icon caret-off' color='transparent' size='sm' caret>
                                                        <MoreVertical size={15} />
                                                    </DropdownToggle>
                                                    <DropdownMenu container='body' end>
                                                        <DropdownItem onClick={_ => { openDetailsModal(post.id) }}>
                                                            <Minimize className='mr-50' size={15} />
                                                            <span className='ms-2 align-middle'>Detalhes</span>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={_ => { openEditModal(post.id) }}>
                                                            <Edit className='mr-50' size={15} /> <span className='ms-2 align-middle'>Editar</span>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => { openDeleteModal(post.id) }}>
                                                            <Trash className='mr-50' size={15} /> <span className='ms-2 align-middle'>Excluir</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </State>

            </StateMachine>

            <CreatePostModal isOpen={isCreatePostOpen} toggle={createPostToggle} reload={reload}></CreatePostModal>
            <EditPostModal isOpen={isEditPostOpen} toggle={editPostToggle} reload={reload} postId={selectedPostId}></EditPostModal>
            <DeletePostModal isOpen={isDeletePostOpen} toggle={deletePostToggle} reload={reload} postId={selectedPostId}></DeletePostModal>
            <ViewPostDetailsModal isOpen={isDetailsPostOpen} toggle={detailsPostToggle} postId={selectedPostId}></ViewPostDetailsModal>

            <Pagination className="mt-auto ms-auto p-3">
                <PaginationItem data-testid="previous-button" disabled={page <= 1}>
                    <PaginationLink previous onClick={e => { e.preventDefault(); goToPreviousPage() }} />
                </PaginationItem>
                <PaginationItem data-testid="next-button" disabled={!hasNextPage}>
                    <PaginationLink next onClick={e => { e.preventDefault(); goToNextPage() }} />
                </PaginationItem>
            </Pagination>

        </Card>
    )
}

export default Posts