import { useContext, useEffect } from "react"
import { useState } from "react"
import { AccountCard } from "./Common"
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { LoaderButton } from "../../../../components/Button/LoaderButton"
import { Eye, Plus, Trash2 } from "react-feather"
import { InputImage } from "../../../../components/Input/Input"
import { ToastContext } from "../../../../context/ToastContext"
import api from '../../../../services/api'
import ImageView from "../../../../components/ImageView/ImageView"

const MAX_IMAGES = 20
const GALLERY_IMAGE_SIZE = 120

const GalleryListView = ({ gallery, onClickDetail, onClickDelete }) => {
    if (gallery.length === 0) {
        return (
            <div className="rouded border text-center py-4 mt-3">
                <span className="text-muted">Nenhuma imagem do estabelecimento</span>
            </div>
        )
    }

    return (
        <div className="d-flex flex-wrap gap-2 mt-3" style={{ boxSizing: 'border-box' }}>
            {gallery.map((image, index) => (
                <div className="mb-2" style={{ width: GALLERY_IMAGE_SIZE, boxSizing: 'border-box', overflow: 'hidden' }} key={`gallery-item-${index}`}>

                    <ImageView
                        className="rounded"
                        key={`gallery-image-${index}`}
                        src={image} alt={`Hotel ${index}`}
                        height={GALLERY_IMAGE_SIZE}
                        width={GALLERY_IMAGE_SIZE} />

                    <div className="d-flex mt-2 gap-2">
                        <Button color="light" block onClick={_ => onClickDetail({ image, open: true })}><Eye size={16} /></Button>
                        <Button color="light" block onClick={_ => onClickDelete(image)}><Trash2 size={16} /></Button>
                    </div>

                </div>
            ))}
        </div>
    )
}

const AddButton = ({ gallery, onClick }) => {
    if (gallery && gallery.length < MAX_IMAGES) {
        return (
            <Button onClick={onClick} color="dark" className="border fw-bold px-2 py-1" outline>
                <Plus size={16} /> <small>Adicionar</small>
            </Button>
        )
    } else {
        return <></>
    }
}

const AccountGallery = _ => {

    const toast = useContext(ToastContext)

    const [gallery, setGallery] = useState({ data: [], loading: true, error: false })
    const [galleryVersion, setGalleryVersion] = useState(1)

    const [registerImage, setRegisterImage] = useState({ image: null })
    const [registerModal, setRegisterModal] = useState({ open: false, loading: false })
    const toogleRegisterModal = () => setRegisterModal(s => ({ ...s, open: !s.open }))

    const [deleteData, setDeleteData] = useState({ image: null })
    const [deleteModal, setDeleteModal] = useState({ open: false, loading: false })
    const toogleDeleteModal = () => setDeleteModal(s => ({ open: !s.open }))

    const [detailImage, setDetailImage] = useState({ image: null, open: false })
    const hideDetail = () => setDetailImage({ image: null, open: false })

    const postRegisterImage = () => {
        if (!registerImage.image) {
            toast.error('Ocorreu um erro, tente novamente!')
            return
        }

        const onSuccess = () => {
            toast.success('Imagem adicionada com sucesso!')
            setRegisterImage({ image: null })
            setGalleryVersion(v => v += 1)
        }

        setRegisterModal(s => ({ ...s, loading: true }))

        const config = { headers: { "Content-Type": "multipart/form-data" } }
        const body = { image: registerImage.image }
        api.post('v1/gallery', body, config)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setRegisterModal(s => ({ ...s, open: false, loading: false })))
    }

    const postDelete = () => {
        if (!deleteData.image) { return }
        setDeleteModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Imagem excluída com sucesso!')
            setGalleryVersion(v => v += 1)
        }

        api.delete(`v1/gallery`, { data: { url: deleteData.image } })
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setDeleteModal(s => ({ ...s, open: false, loading: false })))
    }

    function confirmDelete(image) {
        <div className="d-flex flex-wrap justify-content-around gap-3 mt-3">
            {gallery.data.map((image, index) => (
                <div style={{ width: 136 }} key={`gallery-item-${index}`}>
                    <img key={`gallery-image-${index}`} src={image} alt={`Hotel ${index}`} width={136} height={136} className="rounded" />
                    <div className="d-flex mt-2">
                        <Button color="light" block className="me-1" onClick={_ => setDetailImage({ image, open: true })}><Eye size={16} /></Button>
                        <Button color="light" block onClick={_ => confirmDelete(image)}><Trash2 size={16} /></Button>
                    </div>
                </div>
            ))}
        </div>
        setDeleteData({ image })
        toogleDeleteModal()
    }

    useEffect(_ => {
        api.get('v1/gallery')
            .then(res => setGallery({ data: res.data, loading: false, error: false }))
            .catch(_ => setGallery({ data: [], loading: false, error: true }))
    }, [galleryVersion])

    return (
        <>
            <AccountCard loading={gallery.loading} error={gallery.error}>
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="body2 fw-bold text-gray1">Galeria</h5>
                    <AddButton
                        gallery={gallery.data}
                        onClick={toogleRegisterModal} />
                </div>

                <GalleryListView
                    gallery={gallery.data}
                    onClickDetail={setDetailImage}
                    onClickDelete={confirmDelete} />

            </AccountCard>

            <Modal isOpen={registerModal.open} toggle={toogleRegisterModal} scrollable={true} size="lg">
                <ModalHeader toggle={toogleRegisterModal}>Galeria</ModalHeader>
                <ModalBody>
                    <Form>
                        <div className="text-center">
                            <ImageView width={500} src={registerImage.image} />
                        </div>
                        <FormGroup className="mt-3">
                            <Label for="inp-image">Imagem</Label>
                            <InputImage id="inp-image" maxWidth={800} maxHeight={800} onImage={image => setRegisterImage({ image })} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <LoaderButton color="primary" disabled={!registerImage.image} loading={registerModal.loading} onClick={postRegisterImage}>Salvar</LoaderButton>
                    <Button color="secondary" onClick={toogleRegisterModal}>Fechar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={detailImage.open} toggle={hideDetail} size="lg">
                <ModalHeader toggle={hideDetail}>Imagem</ModalHeader>
                <ModalBody>
                    <img src={detailImage.image} className="rounded w-100" alt="Detail" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={hideDetail}>Fechar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal.open} toggle={toogleDeleteModal}>
                <ModalHeader toggle={toogleDeleteModal}>Importante</ModalHeader>
                <ModalBody>
                    Deseja realmente excluir esta imagem?
                </ModalBody>
                <ModalFooter>
                    <LoaderButton color="danger" loading={deleteModal.loading} onClick={postDelete}>Excluir</LoaderButton>
                    <Button color="secondary" onClick={toogleDeleteModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AccountGallery