const ProductTypeSuggestionList = ({ visible, suggestions, onClickSuggestion }) => {

    if (suggestions.length <= 0 || !visible) {
        return <></>
    }

    return (
        <>
            <div className="d-flex flex-wrap gap-2 mt-2">
                {suggestions.map(type =>
                    <span
                        key={`pro-type-sugges-${type}`}
                        onClick={_ => onClickSuggestion(type)}
                        className="rounded border cursor-pointer p-1 text-body2 text-muted">{type}</span>
                )}
            </div>
            <hr />
        </>
    )
}

export default ProductTypeSuggestionList