import { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, Legend, XAxis } from "recharts"
import api from "../../../../services/api"

export const ServicesStatistics = props => {

    const days = props.days

    const [state, setState] = useState({ loading: false, error: false, data: [] })

    useEffect(_ => {
        setState({ loading: true, error: false, data: [] })

        api.get(`v1/dashboards/services-requested?days=${days}`)
            .then((res) => {
                let data = []

                // create default data to graph
                for (let index = days; index >= 0; index--) {
                    var date = new Date();
                    date.setDate(date.getDate() - index);
                    data.push({ date: date.toLocaleDateString(), Solicitados: 0 })
                }

                // populate graph
                res.data.forEach(r => {
                    let items = r.date.split("/")
                    let day = items[0]
                    let month = items[1]
                    let year = items[2]

                    let date = new Date(year, month - 1, day)
                    let found = data.find(e => e.date === date.toLocaleDateString())
                    if (found) {
                        found.Solicitados = r.requested
                    }
                })

                setState({ loading: false, error: false, data: data })

                setState({ loading: false, error: false, data: data })

                // let data = res.data.map(e => ({ date: e.date, Solicitados: e.requested }))
                // setState({ loading: false, error: false, data: data })
            })
            .catch(_ => setState({ loading: false, error: true, data: [] }))
    }, [days])


    const Content = () => {
        if (state.loading) {
            return <Spinner size={'sm'}></Spinner>
        }

        if (state.error && !state.loading) {
            return <span>Desculpe, ocorreu um erro!</span>
        }

        return (
            <ResponsiveContainer>
                <AreaChart data={state.data}
                    margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                    <XAxis dataKey="date" />
                    <AreaChart />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="Solicitados" stroke="var(--bs-primary)" fill="var(--bs-primary)" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    return (
        <Card {...props}>
            <CardBody>
                <h5 className="p-2">Solicitação de serviços</h5>
                <div className="mt-5 center" style={{ height: 200, maxWidth: '100%' }}>
                    {Content()}
                </div>
            </CardBody>
        </Card>
    )

}