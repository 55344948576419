import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const RejectOrderItemConfirmationModal = props => {

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onClosed={props.reload}>
            <ModalHeader toggle={props.toggle}>Importante</ModalHeader>
            <ModalBody className="py-4">
                <h5 className="mb-3">Deseja realmente rejeitar a solicitação?</h5>
                <span className="text-muted">Essa ação não poderá ser revertida</span>
            </ModalBody>
            <ModalFooter>
                <Button className="text-button" color="danger" onClick={() => { props.toggle(true) }}>Rejeitar</Button>
                <Button className="text-button ms-2" color="secondary" onClick={() => { props.toggle(false) }}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}