import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap"
import { CreateAvailabilityModal } from "./modals/CreateAvailabilityModal"
import { UpdateTimeSlotModal } from "./modals/UpdateTimeSlotModal"
import FullCalendar from "@fullcalendar/react"
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt'
import api from "../../../../../services/api"

const AgendaAvailability = () => {
    const {agendaId} = useParams()
    const [calendarApi, setCalendarApi] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [agendaTitle, setAgendaTitle] = useState("")
    const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null)
    const [isCreateAvailabilityOpen, setCreateAvailabilityOpen] = useState(false)
    const [isUpdateTimeSlotOpen, setUpdateTimeSlotOpen] = useState(false)
    const createAvailabilityToggle = () => setCreateAvailabilityOpen(!isCreateAvailabilityOpen)
    const updateTimeSlotToggle = () => setUpdateTimeSlotOpen(!isUpdateTimeSlotOpen)
    
    useEffect(_ => {
        if(startDate !== null && endDate !== null && startDate < endDate) {
            api.get(`/v1/schedules/${agendaId}?start=${startDate.toISOString().replace('Z', '').replace('/', '-').slice(0, -4)}&end=${endDate.toISOString().replace('Z', '').replace('/', '-').slice(0, -4)}`)
            .then(res => {
                setAgendaTitle(res.data.name)
                calendarApi.removeAllEvents()
                res.data.time_slots.map((time_slot, index) => {
                    slotToEvent(time_slot)
                })
            })
            .catch(_ => handleError())
        }
    }, [agendaId, startDate, endDate])

    function handleError() {}

    function getSlotColor(available_places) {
        if (available_places > 3) {
            return "green"
        } else if (available_places > 1 && available_places <= 3) {
            return "orange"
        } else {
            return "red"
        }
    }

    function slotToEvent(slot) {
        calendarApi.addEvent({
            id: slot.time_slot_id,
            title: `${slot.available_places} vaga(s)`,
            start: new Date(slot.start_date_time),
            end: new Date(slot.end_date_time),
            allDay: false,
            color: getSlotColor(slot.available_places)
        })
    }

    // ** Refs
    const calendarRef = useRef(null)

    const calendarsColor = {
        Business: 'primary',
        Holiday: 'success',
        Personal: 'danger',
        Family: 'warning',
        ETC: 'info'
    }

    const blankEvent = {
        title: '',
        start: '',
        end: '',
        allDay: false,
        url: '',
        extendedProps: {
          calendar: '',
          guests: [],
          location: '',
          description: ''
        }
    }
      
    // ** UseEffect checks for CalendarAPI Update
    useEffect(() => {
        if (calendarApi === null) {
            setCalendarApi(calendarRef.current.getApi())
            setStartDate(calendarRef.current.getApi().view.activeStart)
            setEndDate(calendarRef.current.getApi().view.activeEnd)   
        }
    }, [calendarApi])
      
    // ** calendarOptions(Props)
    const calendarOptions = {
        events: [],
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
            start: 'customprev,customnext',
            end: 'timeGridWeek,timeGridDay'
        },
        editable: false,
        eventResizableFromStart: true,
        dragScroll: true,
        locale: ptLocale,
        navLinks: true,
        eventClassNames({ event: calendarEvent }) {
            const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]
      
            return [
              `bg-light-${colorName}`
            ]
        },
        eventClick({ event: clickedEvent }) {
            setSelectedTimeSlotId(clickedEvent.id)
            updateTimeSlotToggle()
        },
        customButtons: {
            customprev: {
                text: '<',
                click: function() {
                  calendarApi.prev();
                  setStartDate(calendarApi.view.currentStart)
                  setEndDate(calendarApi.view.currentEnd)
                }
              },
              customnext: {
                text: '>',
                click: function() {
                  calendarApi.next();
                  setStartDate(calendarApi.view.currentStart)
                  setEndDate(calendarApi.view.currentEnd)
                }
              }
        },
        dateClick(info) {
            const ev = blankEvent
            ev.start = info.date
            ev.end = info.date
        },
        eventDrop({ event: droppedEvent }) {},
        eventResize({ event: resizedEvent }) {},
        ref: calendarRef,
        direction: 'ltr'
    }

    function reload() {
        calendarApi.refetchEvents()
        setStartDate(calendarApi.view.currentStart)
        setEndDate(calendarApi.view.currentEnd)
    }


    return (
        <div>
            <div className="d-flex justify-content-between mb-3 mt-3">
                <div><b>Adicione disponibilidade para {agendaTitle}</b></div>
                <div><Button className="mb-3" color="primary" onClick={createAvailabilityToggle}>Adicionar disponibilidade</Button></div>
            </div>
            <Card className="h-300 shadow-none border-0 mb-0 rounded-0">
                <CardBody>
                    <FullCalendar {...calendarOptions}></FullCalendar>{' '}
                </CardBody>
            </Card>

            <CreateAvailabilityModal isOpen={isCreateAvailabilityOpen} scheduleId={agendaId} toggle={createAvailabilityToggle} onClosed={reload}></CreateAvailabilityModal>
            <UpdateTimeSlotModal isOpen={isUpdateTimeSlotOpen} scheduleId={agendaId} timeSlotId={selectedTimeSlotId} toggle={updateTimeSlotToggle} onClosed={reload}></UpdateTimeSlotModal>
        </div>
    )
}

export default AgendaAvailability