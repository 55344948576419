import { useContext } from "react"
import { OperatorContext } from "../../context/OperatorContext"
import Avatar from "../Avatar"
import ChangeCircle from "../Icons/ChangeCircle"
import StateView from "../StateView"
import OutlinedButton from "./OutlinedButton"

const OperatorSwitch = (props) => {

    const { operator, openOperatorSwitchModal} = useContext(OperatorContext)
    const hideLabel = props.hideLabel === "true"
    const breakLine = props.breakLine === "true"

    return (
        <OutlinedButton {...props} onClick={_ => openOperatorSwitchModal()} block>

            <StateView show={operator !== null}>
                <div className="px-2 py-1 d-flex align-items-center">
                    <Avatar image={(operator && operator.image)} letter={(operator && operator.name) || ''} width={40} height={40} className="me-1" />
                    <div className="flex-fill text-start px-1">
                        <span hidden={hideLabel} className="text-gray2 text-caption">Colaborador</span>
                        <p className="text-body2 text-gray1 m-0 p-0">{(operator && operator.name) || ''}</p>
                    </div>
                    <ChangeCircle color="var(--gray2)" />
                </div>
            </StateView>

            <StateView show={!operator}>
                <div className="px-2 py-2">
                    <span className="text-body2 text-gray2 text-center">Clique aqui para {breakLine ? <br /> : <></>}selecionar um colaborador</span>
                </div>
            </StateView>

        </OutlinedButton>
    )
}

export default OperatorSwitch