import React from 'react'
import { Check } from 'react-feather'
import './index.css'

/**
 * Usage
 * 
 * create a <Stepper> with <Steppers> as parent
 * 
 * each <Stepper> must have a child as content 
 */
export const Steppers = ({ children, activeTab }) => {

    return (
        <div className="Steppers">
            <div className='Header'>
                {
                    React.Children.map(children, (el, index) => {
                        if (!React.isValidElement(el)) return
                        let isComplete = (index < activeTab)
                        let isActive = (index === activeTab)
                        let activeClass = isActive ? ' Active' : ''
                        let completeClass = isComplete ? 'Complete' : ''
                        return (
                            <div className={`Option ${activeClass} ${completeClass}`}>
                                {isComplete && <Check />}
                                <span className='px-2'>{el.props.title}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className='StepperContent'>
                {React.Children.map(children, (child, index) => {
                    return (index === activeTab) && (
                        child.props.children
                    )
                })}
            </div>
        </div>
    )
}

export const Stepper = _ => { }