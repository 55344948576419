import { useEffect, useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { LoaderButton } from "../../../../../components/Button/LoaderButton"
import { LoadingState } from "../States"
import { getToken } from "../../../../../services/auth";
import { useJwt } from "react-jwt"
import { InputImage } from "../../../../../components/Input/Input";
import api from "../../../../../services/api"
import ImageView from "../../../../../components/ImageView/ImageView";


export const CreatePostModal = props => {

    const token = getToken()
    const { decodedToken } = useJwt(token);

    const [title, setTitle] = useState({
        value: "",
        errorMessage: "",
        isEditionStarted: false
    })
    const [body, setBody] = useState({
        value: "",
        errorMessage: "",
        isEditionStarted: false
    })
    const [type, setType] = useState("HOTEL_GENERAL")
    const [products, setProducts] = useState([])
    const [services, setServices] = useState([])
    const [typeOptions, setTypeOptions] = useState([])
    const [typeId, setTypeId] = useState(null)
    const [images, setImages] = useState({
        value: [],
        errorMessage: "",
        isEditionStarted: false
    })
    const [tags, setTags] = useState([])
    const [registerState, setRegisterState] = useState({ loading: false })

    useEffect(_ => {
        if (decodedToken && type) {
            onSelectType(type)
        }
    }, [decodedToken])

    function loadGeneralInfo() {
        loadHotelProducts(1).then(res => setProducts(res))

        loadHotelServices(1).then(res => setServices(res))

        function loadHotelProducts(page) {
            return api.get(`/v1/products?page=${page}`).then(res => {
                if (res.data.next_page) {
                    return loadHotelProducts(res.data.next_page).then(
                        items => [...res.data.products, ...items]
                    )
                }

                return res.data.products
            })
        }

        function loadHotelServices(page) {
            return api.get(`/v1/services?page=${page}`).then(res => {
                if (res.data.has_next_page) {
                    return loadHotelServices(page + 1).then(
                        items => [...res.data.result, ...items]
                    )
                }

                return res.data.result
            })
        }
    }

    function onSelectType(type) {
        setTypeOptions([])

        if (type == 'HOTEL_SERVICE') {
            setHotelServices()
        }

        if (type == 'HOTEL_PRODUCT') {
            setHotelProducts()
        }

        if (type == 'HOTEL_GENERAL') {
            setTypeId(decodedToken['hotel_id'])
        }

        if (type == 'EXTERNAL_LINK') {
            setTypeId("")
        }

        setType(type)
    }

    function setHotelProducts() {
        setTypeOptions(products.map((product, i) => { return { id: product.id, name: product.name } }))

        if (products.length > 0) {
            setTypeId(products[0].id)
        } else {
            setTypeId(null)
        }
    }

    function setHotelServices() {
        setTypeOptions(services.map((service, i) => { return { id: service.id, name: service.name } }))

        if (services.length > 0) {
            setTypeId(services[0].id)
        } else {
            setTypeId(null)
        }
    }

    function handleSaveError() {
        // TODO handle error
    }

    function save() {
        var formData = new FormData()
        images.value.forEach((image) => formData.append("image", image))
        formData.append("body", JSON.stringify(
            {
                "tags": tags,
                "title": title.value,
                "body": body.value,
                "relation": {
                    "type": type,
                    "id": typeId
                }
            }
        ))

        const config = { headers: { "Content-Type": "multipart/form-data" } }

        setRegisterState({ loading: true })
        api.post('/v1/posts', formData, config)
            .then(_ => {
                props.reload()
                onClose()
            })
            .catch(_ => handleSaveError())
            .finally(_ => setRegisterState({ loading: false }))
    }

    function onClose() {
        setTitle({
            value: "",
            errorMessage: "",
            isEditionStarted: false
        })
        setBody({
            value: "",
            errorMessage: "",
            isEditionStarted: false
        })
        setType("HOTEL_GENERAL")
        setProducts([])
        setServices([])
        setTypeOptions([])
        setImages({
            value: [],
            errorMessage: "",
            isEditionStarted: false
        })
        setTags([])
        props.toggle()
    }

    function hasTagValue(value) {
        return tags.filter(item => item === value).length > 0
    }

    function checkTagValue(value) {
        if (hasTagValue(value)) {
            setTags(tags.filter(item => item !== value))
        } else {
            setTags([...tags, value])
        }
    }

    function isValidForm() {
        return title.isEditionStarted &&
            title.errorMessage.length === 0 &&
            body.isEditionStarted &&
            body.errorMessage.length === 0 &&
            images.value.length > 0
    }

    function handleTitle(event) {
        const value = event.target.value;
        const errorMessage = value === "" ? "Título não pode ser vazio" : ""

        setTitle({
            value: value,
            errorMessage: errorMessage,
            isEditionStarted: true
        })
    }

    function handleBody(event) {
        const value = event.target.value;
        const errorMessage = value === "" ? "A descrição não pode ser vazia" : ""

        setBody({
            value: value,
            errorMessage: errorMessage,
            isEditionStarted: true
        })
    }

    function handleImages(files) {
        const errorMessage = files.length === 0 ? "Deve conter ao menos uma imagem" : ""

        setImages({
            value: files,
            errorMessage: errorMessage,
            isEditionStarted: true
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={onClose} scrollable={true} size='lg' onOpened={loadGeneralInfo}>
            <ModalHeader toggle={onClose}>Nova postagem</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="d-flex justify-content-center flex-wrap gap-3 text-center">
                        {images.value.map((image, index) => <ImageView key={`pt-image-key-${index}`} src={URL.createObjectURL(image)} alt="Produto" width={124} className="mb-2" />)}
                    </div>

                    <FormGroup>
                        <Label>Imagem</Label>
                        <InputImage id="inp-image" name="image" className={images.errorMessage === '' ? '' : 'is-invalid'} onImage={image => handleImages([image])} />
                    </FormGroup>
                    <FormGroup>
                        <Label for='inp-title'>Título</Label>
                        <Input id='inp-title' className={title.errorMessage === '' ? '' : 'is-invalid'} name='name' type='text' placeholder='Digite aqui' onChange={handleTitle} />
                        <div className="invalid-feedback">{title.errorMessage}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label for='inp-post'>Descrição</Label>
                        <Input id='inp-post'
                            className={body.errorMessage === '' ? '' : 'is-invalid'}
                            name='name'
                            type='textarea'
                            style={{ minHeight: 120 }}
                            placeholder='Descreva seu post' onChange={handleBody} />
                        <div className="invalid-feedback">{body.errorMessage}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label for='inp-post'>Tipo</Label>
                        <select id='slt-room' className='form-select' onChange={(event) => onSelectType(event.target.value)} value={type}>
                            <option value="HOTEL_GENERAL">Genérico</option>
                            <option value="HOTEL_SERVICE">Serviço do hotel</option>
                            <option value="HOTEL_PRODUCT">Produto do hotel</option>
                            <option value="EXTERNAL_LINK">Link externo</option>
                        </select>
                    </FormGroup>
                    <FormGroup hidden={type != 'HOTEL_SERVICE' && type != 'HOTEL_PRODUCT'}>
                        <Label>Referência da postagem</Label>
                        {
                            typeOptions.length > 0 ?
                                <select id='slt-room' className='form-select' onChange={(event) => setTypeId(event.target.value)} value={typeId}>
                                    {
                                        typeOptions.map((t, i) => <option key={t.id} value={t.id}>{t.name}</option>)
                                    }
                                </select> : <LoadingState show="true" />
                        }
                    </FormGroup>
                    <FormGroup hidden={type != 'EXTERNAL_LINK'}>
                        <Label for='inp-ext-link'>Link externo</Label>
                        <Input id='inp-ext-link' value={typeId} name='name' type='text' placeholder='Digite a url aqui' onChange={(event) => setTypeId(event.target.value)} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Tags</Label>
                        <br />
                        <Label>
                            <Input id='inp-rest-tag' type='checkbox' checked={hasTagValue('RESTAURANT')} onChange={() => checkTagValue('RESTAURANT')}></Input> Restaurante
                        </Label>
                        <br />
                        <Label>
                            <Input id='inp-trip-tag' type='checkbox' checked={hasTagValue('TRIP')} onChange={() => checkTagValue('TRIP')}></Input> Viagem
                        </Label>
                        <br />
                        <Label>
                            <Input id='inp-place-tag' type='checkbox' checked={hasTagValue('PLACE')} onChange={() => checkTagValue('PLACE')}></Input> Lugar
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="primary" onClick={save} disabled={!isValidForm()} loading={registerState.loading}>Salvar</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={onClose}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}