export const EmptyState = ({ show }) => (
    show &&
    <div className="position-absolute top-50 w-100 center">
        <span>Nenhuma agenda para mostrar</span>
    </div>
)


export const LoadingState = props => (
    props.show &&
    <div className="position-absolute top-50 w-100 center">
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
)