import { Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { useContext, useState } from "react"
import { firebaseAuth, firebaseDb } from "../../../../firebase";
import { ToastContext } from "../../../../context/ToastContext";
import LoaderButton from "../../../../components/Button/LoaderButton"

export const LeadCaptureFormModal = (props) => {

    const toast = useContext(ToastContext)

    const [nameField, setName] = useState({ value: '', error: null })
    const [emailField, setEmail] = useState({ value: '', error: null })
    const [phoneField, setPhone] = useState({ value: '', error: null })
    const [companyField, setCompany] = useState({ value: '', error: null })
    const [positionField, setPosition] = useState({ value: '', error: null })
    const [isFormLoading, setFormLoading] = useState(false)

    function onChangeName(event) {
        let text = event.target.value
        setName({ value: text, error: null })
    }

    function onChangeEmail(event) {
        let text = event.target.value
        setEmail({ value: text, error: null })
    }

    function onChangePhone(event) {
        let text = event.target.value
        setPhone({ value: text, error: null })
    }

    function onChangeCompany(event) {
        let text = event.target.value
        setCompany({ value: text, error: null })
    }

    function onChangePosition(event) {
        let text = event.target.value
        setPosition({ value: text, error: null })
    }

    function handleSubmit(event) {
        event.preventDefault();

        let name = nameField.value.trim()
        let email = emailField.value.trim()
        let phone = phoneField.value.trim()
        let company = companyField.value.trim()
        let position = positionField.value.trim()

        if (name.length < 3) {
            setName({ value: name, error: 'O Nome é obrigatório' })
        }

        if (email.length < 3) {
            setEmail({ value: email, error: 'O E-mail é obrigatório' })
        }

        if (phone.length < 3) {
            setPhone({ value: phone, error: 'O Telefone é obrigatório' })
        }

        if (company.length < 3) {
            setCompany({ value: company, error: 'O Hote/Empresa é obrigatório' })
        }

        if (position.length < 3) {
            setPosition({ value: position, error: 'O Cargo é obrigatório' })
        }

        let fields = [nameField, emailField, phoneField, companyField, positionField]
        let isValidForm = fields.every(field => field.error === null)

        if (isValidForm) {
            setFormLoading(true)
            let lead = { name, email, phone, company, position }
            firebaseAuth.signInAnonymously()
                .then(_ => firebaseDb.collection("leads").add(lead))
                .then(_ => onSubmitFormSuccess())
                .catch(e => toast.error())
                .finally(_ => setFormLoading(false))

        }
    }

    function onSubmitFormSuccess() {
        props.successCallback()
        setName({ value: '', error: null })
        setEmail({ value: '', error: null })
        setPhone({ value: '', error: null })
        setCompany({ value: '', error: null })
        setPosition({ value: '', error: null })
    }

    return (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg">
            <ModalHeader toggle={props.toggle} className="border-0" />
            <ModalBody className="py-4 px-4 px-md-5">

                <h5 className="text-title text-center text-gray1 mb-2">Descubra como nosso aplicativo pode aumentar a receita do seu hotel</h5>
                <p className="text-center text-body1 text-gray2">Preencha o formulário abaixo e um de nossos especialistas entrará <br />em contato para uma demonstração.</p>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label className="text-body2 text-gray2 mb-2">Nome Completo</Label>
                        <Input required invalid={nameField.error} className="text-body1 text-gray1 py-2" placeholder="Ex. João Silva" value={nameField.value} onChange={onChangeName} />
                        <FormFeedback>{nameField.error}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label className="text-body2 text-gray2 mb-2">E-mail Corporativo</Label>
                        <Input required invalid={emailField.error} className="text-body1 text-gray1 py-2" placeholder="Ex. joao.silva@hotelx.com.br" type="email" value={emailField.value} onChange={onChangeEmail} />
                        <FormFeedback>{emailField.error}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label className="text-body2 text-gray2 mb-2">Número de Telefone</Label>
                        <Input required invalid={phoneField.error} className="text-body1 text-gray1 py-2" placeholder="Ex. (11) 98765-4321" type="tel" value={phoneField.value} onChange={onChangePhone} />
                        <FormFeedback>{phoneField.error}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label className="text-body2 text-gray2 mb-2">Nome do Hotel/Empresa</Label>
                        <Input required invalid={companyField.error} className="text-body1 text-gray1 py-2" placeholder="Ex. Hotel X" value={companyField.value} onChange={onChangeCompany} />
                        <FormFeedback>{companyField.error}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label className="text-body2 text-gray2 mb-2">Cargo</Label>
                        <Input required invalid={positionField.error} className="text-body1 text-gray1 py-2" placeholder="Ex. Gerente de Operações" value={positionField.value} onChange={onChangePosition} />
                        <FormFeedback>{positionField.error}</FormFeedback>
                    </FormGroup>

                    <LoaderButton loading={isFormLoading} type="submit" className="text-button w-100 py-2 mt-4" color="success">Quero uma Demonstração</LoaderButton>
                    <p className="text-center text-caption mt-4 text-gray2">Seus dados serão usados exclusivamente para entrarmos em contato com você sobre a demonstração do nosso aplicativo. Não compartilharemos suas informações com terceiros.</p>
                </Form>

            </ModalBody>
        </Modal>
    )
}