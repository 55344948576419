import { useEffect, useState } from "react"
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { ToastContext } from "../../../../context/ToastContext"
import { Edit, Trash2 } from "react-feather"
import { useContext } from "react"
import { OperatorContext } from "../../../../context/OperatorContext"
import { StoreContext } from "../../../../context/StoreContext"
import Center from "../../../../components/Center/Center"
import api from "../../../../services/api"
import OperatorRegisterModal from "./OperatorRegisterModal"
import StateView from "../../../../components/StateView"
import Avatar from "../../../../components/Avatar"
import LoaderButton from "../../../../components/Button/LoaderButton"
import OperatorUpdateModal from "./OperatorUpdateModal"
import AlohaApi from "../../../../services/aloha-api"


const OperatorPage = () => {

    const store = useContext(StoreContext)
    const operatorContext = useContext(OperatorContext)
    const toast = useContext(ToastContext)
    
    const [version, setVersion] = useState(1)

    const [operators, setOperators] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [registerOpen, setRegisterOpen] = useState(false)

    const [deleteItem, setDeleteItem] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const toggleDeleteModal = () => setDeleteModalOpen(open => !open)

    const [updateItem, setUpdateItem] = useState(null)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const toggleUpdateModal = () => setUpdateModalOpen(open => !open)

    const openRegisterModal = () => setRegisterOpen(true)
    const refreshPage = () => setVersion(v => v + 1)

    useEffect(_ => {
        setLoading(true)
        AlohaApi.fetchOperators()
            .then(operators => {

                // update store
                store.operators.set(operators)

                // update the operator at store, if the user has made any changes, such as changing the photo
                if (operatorContext.operator) {
                    let refreshedOperator = operators.find(op => op.id === operatorContext.operator.id)
                    if (JSON.stringify(operatorContext.operator) !== JSON.stringify(refreshedOperator)) {
                        operatorContext.setOperator(refreshedOperator)
                    }
                }

                setOperators(operators)
            })
            .catch(_ => setError(true))
            .finally(_ => setLoading(false))
    }, [version, store.operators])

    const shouldShowData = !loading && operators.length > 0
    const shouldShowLoadingState = loading
    const shouldShowEmptyState = !loading && !error && operators.length === 0
    const shouldShowErrorState = !loading && error

    function confirmDeleteOperator() {
        if (deleteItem) {
            setDeleteLoading(true)
            api.delete(`/v1/operators/${deleteItem.id}`)
                .then(_ => {
                    toggleDeleteModal()
                    toast.success('Colaborador excluido')
                    refreshPage()
                })
                .catch(_ => toast.error('Desculpe, ocorreu um erro, tente novamente!'))
                .finally(_ => setDeleteLoading(false))
        }
    }

    return (
        <>
            <StateView show={shouldShowData}>
                <Card className="w-100">
                    <CardBody>

                        <div className="d-flex gap-3 mb-4">
                            <div className="flex-fill">
                                <Input placeholder="Pesquisar" />
                            </div>
                            <Button onClick={openRegisterModal} color="primary" className="text-button">
                                Novo colaborador
                            </Button>
                        </div>

                        <div>
                            {operators.map((operator, index) => {
                                let showDivider = index < (operators.length - 1)

                                function onClickDelete() {
                                    setDeleteItem(operator)
                                    setDeleteModalOpen(true)
                                }

                                function onClickEdit() {
                                    setUpdateItem(operator)
                                    setUpdateModalOpen(true)
                                }

                                return (
                                    <OperatorRow
                                        key={`operator-row-key-${index}`}
                                        onClickEdit={onClickEdit}
                                        onClickDelete={onClickDelete}
                                        operator={operator}
                                        divider={showDivider} />
                                )
                            })}
                        </div>
                    </CardBody>
                </Card>
            </StateView>

            <StateView show={shouldShowEmptyState}>
                <Center className="h-100 flex-column">
                    <p className="text-body1 text-gray2 mb-2">Nenhum colaborador cadastrado</p>
                    <Button onClick={_ => setRegisterOpen(true)} className="text-button" color="primary">+ Adicionar colaborador</Button>
                </Center>
            </StateView>

            <StateView show={shouldShowLoadingState}>
                <Center className="h-100 flex-column">
                    <Spinner />
                </Center>
            </StateView>

            <StateView show={shouldShowErrorState}>
                <Center className="h-100 flex-column">
                    <span>Desculpe, ocorreu um erro!</span>
                </Center>
            </StateView>

            <StateView show={updateItem}>
                <OperatorUpdateModal
                    open={updateModalOpen}
                    onRegister={_ => refreshPage()}
                    toggle={toggleUpdateModal}
                    operator={updateItem} />
            </StateView>

            <OperatorRegisterModal
                open={registerOpen}
                onRegister={_ => refreshPage()}
                toggle={_ => setRegisterOpen(!registerOpen)} />

            <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Excluir colaborador</ModalHeader>
                <ModalBody>
                    <p className="m-0 mb-3 text-body2">Deseja realmente excluir {deleteItem ? deleteItem.name : ''}?</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="text-button me-2" color="secondary" onClick={toggleDeleteModal}>Cancelar</Button>
                    <LoaderButton loading={deleteLoading} className="text-button" color="danger" onClick={confirmDeleteOperator}>Excluir</LoaderButton>
                </ModalFooter>
            </Modal>
        </>
    )
}

const OperatorRow = ({ operator, divider, onClickEdit, onClickDelete }) => {

    const operatorContext = useContext(OperatorContext);

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                    <Avatar image={operator.image} letter={operator.name} width={40} height={40} />
                    <span className="text-body2 text-gray1">{operator.name}</span>
                </div>
                <div>
                    <Button color="light" className="me-2" onClick={onClickEdit}>
                        <Edit size={20} />
                    </Button>
                    <Button disabled={operatorContext.operator && operatorContext.operator.id === operator.id} color="light" className="text-gray1" onClick={onClickDelete}>
                        <Trash2 size={20} />
                    </Button>
                </div>
            </div>
            {divider && <hr />}
        </>
    )
}

export default OperatorPage