import { useContext } from "react"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { ToastContext } from "../../context/ToastContext"

const ToastContainer = _ => {

    const toaster = useContext(ToastContext)

    return (
        <div className="toast-container position-fixed top-0 end-0 p-3">
            {toaster.toasts.map(toast => (
                <Toast key={toast.id} isOpen={toast.isOpen}>
                    <ToastHeader icon={toast.icon} toggle={_ => toaster.hide(toast.id)}>{toast.title}</ToastHeader>
                    <ToastBody>{toast.message}</ToastBody>
                </Toast>
            ))}
        </div>
    )
}

export default ToastContainer