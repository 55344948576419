export function run(func) {
    return func()
}

export function toPhoneNumber(number) {
    if (number.length === 8) {
        return `${number.slice(0, 4)} ${number.slice(4, 8)}`
    }

    if (number.length === 10) {
        return `(${number.slice(0, 2)}) ${number.slice(2, 6)} ${number.slice(6, 10)}`
    }

    if (number.length === 11) {
        return `(${number.slice(0, 2)}) ${number.slice(2, 3)} ${number.slice(3, 7)} ${number.slice(7, 11)}`
    }

    return number
}

export function toSocialMediaUrl({ type, url }) {
    if (url.startsWith('http')) {
        return url
    }

    let lowerType = type.toLowerCase()

    if (lowerType === 'instagram') {
        return `https://www.instagram.com/${url.replace('@', '')}`
    }

    if (lowerType === 'facebook') {
        return `https://www.facebook.com/${url.replace('@', '')}`
    }

    if (lowerType === 'twitter') {
        return `https://twitter.com/${url.replace('@', '')}`
    }

    if (lowerType === 'tripadvisor') {
        return `https://www.tripadvisor.com/${url}`
    }

    return url
}

export function dateToStr(date) {
    return date.toLocaleDateString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
}

export function getDatefrom(dateStrEn) {
    let inSplit = dateStrEn.split('-')
    let year = inSplit[0]
    let month = inSplit[1] - 1
    let day = inSplit[2]
    return new Date(year, month, day)
}

export function parseToDate(dateString) {
    return new Date(
        parseInt(dateString.split('/')[2]),
        parseInt(dateString.split('/')[1]) - 1,
        parseInt(dateString.split('/')[0])
    ).toISOString().replace(".000Z", '')
}

export const today = _ => new Date()
export const tomorrow = _ => {
    var tomorrow = new Date();
    tomorrow.setDate(today().getDate() + 1)
    return tomorrow
}

export function toTimeAgo(dateStr) {

    const now = new Date()
    now.setTime(now.getTime() + (3 * 60 * 60 * 1000))
    
    const date = new Date(dateStr)
    const seconds = Math.floor((now - date) / 1000);
    const years = Math.floor(seconds / 31536000);

    if (years > 1) {
        return years + " anos atrás";
    }
    if (years === 1) {
        return years + " ano atrás";
    }

    const months = Math.floor(seconds / 2628000);

    if (months > 1) {
        return months + " meses atrás";
    }

    if (months === 1) {
        return months + " mês atrás";
    }

    const days = Math.floor(seconds / 86400);

    if (days > 1) {
        return days + " dias atrás";
    }

    if (days === 1) {
        return days + " dia atrás";
    }

    const hours = Math.floor(seconds / 3600);

    if (hours > 1) {
        return hours + " horas atrás";
    }

    if (hours === 1) {
        return hours + " hora atrás";
    }

    const minutes = Math.floor(seconds / 60);

    if (minutes > 1) {
        return minutes + " minutos atrás";
    }

    if (minutes === 1) {
        return minutes + " minuto atrás";
    }

    return "Agora mesmo";
}