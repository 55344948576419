import { Edit2, Trash } from "react-feather";
import { Card, CardBody } from "reactstrap"
import ImageView from "../../../../../components/ImageView/ImageView";
import './index.css'

const DAYS_OF_WEEK_NAME = {
    "MONDAY": "Segundas",
    "TUESDAY": "Terças",
    "WEDNESDAY": "Quartas",
    "THURSDAY": "Quintas",
    "FRIDAY": "Sextas",
    "SATURDAY": "Sábados",
    "SUNDAY": "Domingos"
}

const EventCard = (props) => {
    const data = props.event

    function subtitle() {
        if (data.days_of_week.length === 7) {
            return "Todos os dias da semana"
        } else {
            let mappedNames = data.days_of_week.map(value => DAYS_OF_WEEK_NAME[value])

            if (mappedNames.length > 1) {
                let lastName = mappedNames.pop()
                return `Todas as ${mappedNames.join(', ')} e ${lastName}`
            }

            return `Todos(as) ${mappedNames.pop()}`
        }
    }

    function body() {
        let splittedTime = data.time.split('-')
        let splittedHour = splittedTime[0].split(':')

        let newHour = parseInt(splittedHour[0]) + parseInt(data.minutes / 60)
        let newMinutes = parseInt(splittedHour[1]) + parseInt(data.minutes % 60)

        return `Dàs ${splittedHour[0]}:${splittedHour[1]} às ${hourFormat(newHour)}:${hourFormat(newMinutes)}`
    }

    function hourFormat(num) {
        if (num < 10) {
            return `0${num}`
        }

        return `${num}`
    }

    return (
        <Card style={{ maxWidth: 300 }} className="flex-fill">
            <ImageView className="event-card-image" alt="Event image" src={data.image} />
            <CardBody>
                <h6 className="text-h6 mb-3">{data.title}</h6>
                <p className="text-body2 text-secondary mb-2">{subtitle()}</p>
                <p className="text-body2 text-secondary">{body()}</p>
            </CardBody>
            <div className="py-2 px-3 d-flex gap-2">
                <button className="button-gray" onClick={() => { props.openUpdateModal(data) }}><Edit2 size={16} /></button>
                <button className="button-gray" onClick={() => { props.openDeleteModal(data) }}><Trash size={16} /></button>
            </div>
        </Card>
    )
}

export default EventCard;