import { useContext, useState } from "react"
import { useEffect } from "react"
import { Eye, Plus, Trash2 } from "react-feather"
import { AccountCard } from "./Common"
import { Button, Row, Col, Form, FormGroup, Input, Label, List, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { run } from '../../../../utils/func'
import { StoreContext } from "../../../../context/StoreContext"
import { ToastContext } from "../../../../context/ToastContext"
import api from '../../../../services/api'
import moment from "moment/moment"
import AlohaApi from "../../../../services/aloha-api"

const opening = () => {
    const opening = new Date()
    opening.setHours(0)
    opening.setMinutes(0)
    opening.setSeconds(0)
    return opening
}

const closing = () => {
    const closing = new Date()
    closing.setHours(23)
    closing.setMinutes(59)
    closing.setSeconds(59)
    return closing
}

const WEEK_DAYS = [
    { day_of_week: 'SUNDAY', value: 'SUNDAY', label: 'Domingo' },
    { day_of_week: 'MONDAY', value: 'MONDAY', label: 'Segunda' },
    { day_of_week: 'TUESDAY', value: 'TUESDAY', label: 'Terça' },
    { day_of_week: 'WEDNESDAY', value: 'WEDNESDAY', label: 'Quarta' },
    { day_of_week: 'THURSDAY', value: 'THURSDAY', label: 'Quinta' },
    { day_of_week: 'FRIDAY', value: 'FRIDAY', label: 'Sexta' },
    { day_of_week: 'SATURDAY', value: 'SATURDAY', label: 'Sábado' }
]

const INITIAL_REGISTER_DATA = {
    name: '',
    description: '',
    availability: WEEK_DAYS.map(day => ({ ...day, time_zone: -3, opening: opening(), closing: closing(), isValid: true }))
}

const INITIAL_DETAIL_DATA = {
    name: '',
    description: ''
}

const AccountPlaces = () => {

    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const [state, setState] = useState({ data: [], loading: true, error: false })
    const [stateVersion, setStateVersion] = useState(1)

    const [registerData, setRegisterData] = useState({ ...INITIAL_REGISTER_DATA })
    const [registerModal, setRegisterModal] = useState({ open: false, loading: false })
    const setRegisterModalLoading = (loading) => setRegisterModal(s => ({ ...s, loading }))
    const toogleRegisterModal = () => setRegisterModal(s => ({ open: !s.open }))

    const [deleteData, setDeleteData] = useState({ id: null })
    const [deleteModal, setDeleteModal] = useState({ open: false, loading: false })
    const toogleDeleteModal = () => setDeleteModal(s => ({ open: !s.open }))

    const [detailModal, setDetailModal] = useState({ place: INITIAL_DETAIL_DATA, open: false })
    const setDetailData = (wifi) => setDetailModal(s => ({ ...s, place: wifi }))
    const toogleDetailModal = () => setDetailModal(s => ({ ...s, open: !s.open }))

    const postRegister = () => {
        setRegisterModalLoading(true)

        const onSuccess = () => {
            setRegisterData(INITIAL_REGISTER_DATA)
            toast.success('Local adicionado com sucesso!')
            setStateVersion(v => v += 1)
        }

        let request = {
            name: registerData.name,
            description: registerData.description,
            availability: registerData.availability.map(e => ({
                day_of_week: e.day_of_week,
                opening: moment(e.opening).format('HH:mm:ss.000000-03:00'),
                closing: moment(e.closing).format('HH:mm:ss.000000-03:00'),
                time_zone: e.time_zone
            }))
        }

        api.post('places', request)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => toogleRegisterModal())
    }

    const postDelete = () => {
        if (!deleteData.id) { return }
        setDeleteModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Local excluído com sucesso!')
            setStateVersion(v => v += 1)
        }

        api.delete(`places/${deleteData.id}`)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setDeleteModal(s => ({ ...s, open: false, loading: false })))
    }

    function confirmDelete(id) {
        setDeleteData({ id })
        toogleDeleteModal()
    }

    function showDetail(place) {
        setDetailData(place)
        toogleDetailModal()
    }

    function onChangeAvailabilityDay(day) {
        let availability = registerData.availability
        let index = availability.findIndex(e => e.value === day.value)
        if (index >= 0) {
            availability[index] = day
            setRegisterData(state => ({ ...state, availability }))
        }
    }

    useEffect(_ => {
        AlohaApi.fetchPlaces()
            .then(places => {
                store.places.set(places)
                return places
            })
            .then(places => setState({ data: places, loading: false, error: false }))
            .catch(_ => setState({ data: [], loading: false, error: true }))
    }, [stateVersion])

    const PlacesStateView = run(() => {
        if (state.data.length === 0) {
            return (
                <div className="rouded border text-center py-4 mt-3">
                    <span className="text-muted">Nenhum local adicionado</span>
                </div>
            )
        }

        return (
            <ListGroup className="mt-4">
                {state.data.map((place, index) => (
                    <ListGroupItem key={`wifi-item-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="body2">{place.name}</span>
                            <div>
                                <button className="btn btn-light me-1" onClick={_ => showDetail(place)}><Eye size={16} /></button>
                                <button className="btn btn-light" onClick={_ => confirmDelete(place.id)}><Trash2 size={16} /></button>
                            </div>
                        </div>
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    })

    const isSaveButtonEnabled = run(_ => {
        return registerData.name && registerData.name.length > 3 && registerData.availability.every(e => e.isValid)
    })

    return (
        <>
            <AccountCard loading={state.loading} error={state.error}>
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="body2 fw-bold"><span className="ms-2">Locais</span></h6>
                    <Button onClick={toogleRegisterModal} color="dark" className="border fw-bold px-2 py-1" outline style={{ minWidth: 90 }}><Plus size={16} /> <small>Adicionar</small></Button>
                </div>
                {PlacesStateView}
            </AccountCard>

            <Modal isOpen={registerModal.open} toggle={toogleRegisterModal} scrollable={true} size='lg'>
                <ModalHeader toggle={toogleRegisterModal}>Adicionar local</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for='inp-name'>Nome público</Label>
                            <Input value={registerData.name} id='inp-name' name='name' type='text' placeholder='Digite aqui' onChange={e => setRegisterData(s => ({ ...s, name: e.target.value }))} />
                        </FormGroup>
                        <FormGroup>
                            <Label for='inp-description'>Descrição</Label>
                            <Input rows="3" value={registerData.description} id='inp-description' name='description' type='textarea' placeholder='Digite aqui' onChange={e => setRegisterData(s => ({ ...s, description: e.target.value }))} />
                        </FormGroup>

                        <Label>Horário de funcionamento</Label>

                        <div className="border rounded p-3">
                            {registerData.availability && registerData.availability.map((day, index) => (
                                <DayTimeInput
                                    key={`date-input-key-${index}`}
                                    index={index}
                                    day={day}
                                    onChange={onChangeAvailabilityDay} />
                            ))}
                        </div>

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={postRegister} disabled={registerModal.loading || !isSaveButtonEnabled}>
                        {(registerModal.loading) ? <><span className="pe-3">Salvando</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>
                    <Button color="secondary" onClick={toogleRegisterModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal.open} toggle={toogleDeleteModal}>
                <ModalHeader toggle={toogleDeleteModal}>Importante</ModalHeader>
                <ModalBody>
                    Deseja realmente excluir este local?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={deleteModal.loading} onClick={postDelete}>
                        {(deleteModal.loading) ? <><span className="pe-3">Excluindo..</span><Spinner size="sm">Loading...</Spinner></> : 'Excluir'}
                    </Button>
                    <Button color="secondary" onClick={toogleDeleteModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={detailModal.open} toggle={toogleDetailModal}>
                <ModalHeader toggle={toogleDetailModal}>Detalhes</ModalHeader>
                <ModalBody>
                    <List type="unstyled">
                        <li className="mb-1"><b>Nome: </b>{detailModal.place.name}</li>
                        <li className="mb-1"><b>Descrição: </b>{detailModal.place.description || '---'}</li>
                    </List>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toogleDetailModal}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const DayTimeInput = ({ day, onChange, index }) => {

    function onChangeRequest(data) {
        if (onChange) {
            onChange(data)
        }
    }

    function onChangeStartTime(event) {
        let newTime = createNewTime(day.opening, event.target.value)
        if (newTime) {
            onChangeRequest({
                ...day,
                opening: newTime,
                closing: day.closing,
                isValid: isValid(newTime, day.closing)
            })
        }
    }

    function onChangeEndTime(event) {
        let newTime = createNewTime(day.closing, event.target.value)
        if (newTime) {
            onChangeRequest({
                ...day,
                opening: day.opening,
                closing: newTime,
                isValid: isValid(day.opening, newTime)
            })
        }
    }

    function createNewTime(currentTime, value) {
        if (value.length !== 5) {
            return null
        }

        let hours = value.split(':')[0]
        let minutes = value.split(':')[1]

        let newTime = new Date(currentTime.getTime())
        newTime.setHours(hours)
        newTime.setMinutes(minutes)

        return newTime
    }

    function isValid(opening, closing) {
        if (!opening || !closing) {
            return false
        }

        return (opening.getTime()) < (closing.getTime())
    }

    let mStartTime = moment(day.opening).format('HH:mm')
    let mEndTime = moment(day.closing).format('HH:mm')

    let isAllDay = (mStartTime === '00:00' && mEndTime === '23:59')
    let isFirst = index === 0

    return (
        <>
            {isFirst && (
                <Row className="mb-2">
                    <Col style={{ maxWidth: 200 }}></Col>
                    <Col><Label className="text-body2 text-gray3">Início</Label></Col>
                    <Col><Label className="text-body2 text-gray3">Término</Label></Col>
                </Row>
            )}
            <Row className="d-flex align-items-center mb-2">
                <Col style={{ maxWidth: 200 }}>
                    <span className="text-body2 text-gray1">{day.label}</span>
                    <span className="text-caption text-gray3 px-1">{isAllDay ? '(Dia todo)' : ''}</span>
                </Col>
                <Col>
                    <Input type="time" value={mStartTime} onChange={onChangeStartTime} />
                </Col>
                <Col>
                    <Input invalid={!isValid(day.opening, day.closing)} type="time" value={mEndTime} onChange={onChangeEndTime} />
                </Col>
            </Row>
        </>
    )
}

export default AccountPlaces