import { useEffect, useState } from "react";
import { Edit, MoreVertical } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Row, Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table, UncontrolledDropdown } from "reactstrap";
import { CreateAgendaModal } from "./modals/CreateAgendaModal";
import { EmptyState, LoadingState } from "./States";
import api from "../../../../../services/api";

const Agendas = () => {

    const navigate = useNavigate()

    const [schedules, setSchedules] = useState([])
    const [page, setPage] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(true)
    const [loading, setLoading] = useState(true)
    const [isCreateScheduleOpen, setCreateScheduleOpen] = useState(false)

    const [scheduleId, setScheduleId] = useState(0)
    const createScheduleToggle = () => setCreateScheduleOpen(!isCreateScheduleOpen)
    const start = new Date().toISOString().replace('Z', '').replace('/', '-').slice(0, -4)
    const end = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
    ).toISOString().replace('Z', '').slice(0, -4)


    useEffect(_ => {
        if (scheduleId !== 0) {
            navigate(`/dashboard/services/agendas/${scheduleId}`)
        }
    }, [scheduleId])

    useEffect(_ => {
        api.get(`/v1/schedules?page=${page}&start=${start}&end=${end}`)
            .then(res => {
                setLoading(false)
                setSchedules(res.data.result)
                setHasNextPage(res.data.has_next_page)
            })
            .catch(_ => handleError())
    }, [])

    function handlePreviousPage() {
        setPage(page - 1)
        setLoading(true)

        api.get(`/v1/schedules?page=${page - 1}&start=${start}&end=${end}`)
            .then(res => {
                setLoading(false)
                setSchedules(res.data.result)
                setHasNextPage(res.data.has_next_page)
            })
            .catch(_ => handleError())
    }

    function handleNextPage() {
        setPage(page + 1)
        setLoading(true)

        api.get(`/v1/schedules?page=${page + 1}&start=${start}&end=${end}`)
            .then(res => {
                setLoading(false)
                setSchedules(res.data.result)
                setHasNextPage(res.data.has_next_page)
            })
            .catch(_ => handleError())
    }

    function handleError() { }

    return (
        <Card className="h-100">
            <Container className="p-4">
                <Row>
                    <Col></Col>
                    <Col md="auto">
                        <Button className="text-button px-4" block  color='primary' onClick={createScheduleToggle}>Nova agenda</Button>
                    </Col>
                </Row>
            </Container>

            <Table responsive>
                <thead>
                    <tr>
                        <th scope="col"><span className="ps-3">Nome</span></th>
                        <th scope="col">Agendamentos </th>
                        <th scope="col">Disponibilidade</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {schedules.map((schedule, index) =>
                        <tr key={index}>
                            <td><span className="text-subtitle2 px-3">{schedule.schedule.name}</span></td>
                            <td>{schedule.schedules_requests}</td>
                            <td>{schedule.availability}</td>
                            <td>
                                <UncontrolledDropdown>
                                    <DropdownToggle className='btn-icon caret-off' color='transparent' size='sm' caret>
                                        <MoreVertical size={15} />
                                    </DropdownToggle>
                                    <DropdownMenu container='body' end>
                                        <DropdownItem onClick={_ => { setScheduleId(schedule.schedule.id) }}>
                                            <Edit className='mr-50' size={15} />
                                            <span className='ms-2 align-middle'>Disponibilidade</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <Pagination className="mt-auto ms-auto p-3">
                <PaginationItem disabled={page === 1}>
                    <PaginationLink previous onClick={e => { e.preventDefault(); handlePreviousPage() }} />
                </PaginationItem>
                <PaginationItem disabled={!hasNextPage}>
                    <PaginationLink next onClick={e => { e.preventDefault(); handleNextPage() }} />
                </PaginationItem>
            </Pagination>

            <EmptyState show={!loading && schedules.length === 0} />
            <LoadingState show={loading}></LoadingState>

            <CreateAgendaModal isOpen={isCreateScheduleOpen} toggle={createScheduleToggle} setCreatedScheduleId={setScheduleId} />
        </Card>
    )
}

export default Agendas