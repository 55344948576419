import { useEffect, useState } from "react"
import { Button, CloseButton, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import ImageView from "../../../../../components/ImageView/ImageView"
import api from "../../../../../services/api"
import { LoadingState } from "../States"

export const EditPostModal = props => {

    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [type, setType] = useState("HOTEL_GENERAL")
    const [products, setProducts] = useState([])
    const [isProductsLoaded, setIsProductsLoaded] = useState(false)
    const [services, setServices] = useState([])
    const [isServicesLoaded, setIsServicesLoaded] = useState(false)
    const [typeOptions, setTypeOptions] = useState([])
    const [typeId, setTypeId] = useState(null)
    const [images, setImages] = useState([])
    const [imagesToAdd, setImagesToAdd] = useState([])
    const [imagesToDelete, setImagesToDelete] = useState([])
    const [tags, setTags] = useState([])
    const [isPostLoaded, setIsPostLoaded] = useState(false)

    useEffect(_ => {
        if (isProductsLoaded && isServicesLoaded) {
            loadPost(props.postId)
        }
    }, [isProductsLoaded, isServicesLoaded])

    function loadHotelProducts(page) {
        return api.get(`/v1/posts?page=${page}`).then(res => {
            if (res.data.next_page) {
                return loadHotelProducts(res.data.next_page).then(
                    items => [...res.data.products, ...items]
                )
            }

            return res.data.products
        })
    }

    function loadHotelServices(page) {
        return api.get(`/v1/services?page=${page}`).then(res => {
            if (res.data.has_next_page) {
                return loadHotelServices(page + 1).then(
                    items => [...res.data.result, ...items]
                )
            }

            return res.data.result
        })
    }

    function loadPost(postId) {
        api.get(`/v1/posts/${postId}`).then(res => {
            setTitle(res.data.title)
            setBody(res.data.body)
            onSelectType(res.data.relation.type)
            setTypeId(res.data.relation.id)
            setImages(res.data.images)
            setTags(res.data.tags)
            setIsPostLoaded(true)
        })
    }

    function loadGeneralInfo() {
        loadHotelProducts(1).then(
            res => {
                setProducts(res)
                setIsProductsLoaded(true)
            }
        )

        loadHotelServices(1).then(
            res => {
                setServices(res)
                setIsServicesLoaded(true)
            }
        )
    }

    function onSelectType(type) {
        setTypeOptions([])

        if (type === 'HOTEL_SERVICE') {
            setHotelServices()
        }

        if (type === 'HOTEL_PRODUCT') {
            setHotelProducts()
        }

        if (type == 'EXTERNAL_LINK') {
            setTypeId("")
        }

        setType(type)
    }

    function setHotelProducts() {
        setTypeOptions(products.map((product, i) => { return { id: product.id, name: product.name } }))

        if (products.length > 0) {
            setTypeId(products[0].id)
        } else {
            setTypeId(null)
        }
    }

    function setHotelServices() {
        setTypeOptions(services.map((service, i) => { return { id: service.id, name: service.name } }))

        if (services.length > 0) {
            setTypeId(services[0].id)
        } else {
            setTypeId(null)
        }
    }

    function handleError() {

    }

    function save() {
        var formData = new FormData()
        imagesToAdd.forEach((image) => formData.append("image", image))
        formData.append("body", JSON.stringify(
            {
                "tags": tags,
                "title": title,
                "body": body,
                "relation": {
                    "type": type,
                    "id": typeId
                },
                "images_to_remove": imagesToDelete
            }
        ))

        const config = { headers: { "Content-Type": "multipart/form-data" } }

        api.put(`/v1/posts/${props.postId}`, formData, config)
            .then(_ => {
                props.reload()
                onClose()
            })
            .catch(err => handleError())
    }

    function onClose() {
        setTitle("")
        setBody("")
        setType("HOTEL_GENERAL")
        setProducts([])
        setServices([])
        setTypeOptions([])
        setTypeId(null)
        setImages([])
        setImagesToAdd([])
        setImagesToDelete([])
        setTags([])
        setIsProductsLoaded(false)
        setIsServicesLoaded(false)
        setIsPostLoaded(false)
        props.toggle()
    }

    function hasTagValue(value) {
        return tags.filter(item => item === value).length > 0
    }

    function checkTagValue(value) {
        if (hasTagValue(value)) {
            setTags(tags.filter(item => item !== value))
        } else {
            setTags([...tags, value])
        }
    }

    function removeImage(image) {
        setImagesToDelete([...imagesToDelete, image])
    }


    function removeImageFromSelected(image) {
        setImagesToAdd(imagesToAdd.filter(item => item !== image))
    }

    return (
        <Modal isOpen={props.isOpen} toggle={onClose} scrollable={true} size='lg' onOpened={loadGeneralInfo}>
            <ModalHeader toggle={onClose}>Editar postagem</ModalHeader>
            <ModalBody>
                <Form hidden={!isPostLoaded}>
                    <FormGroup>
                        <Label for='inp-title'>Título</Label>
                        <Input id='inp-title' name='name' type='text' placeholder='Título da postagem' value={title} onChange={event => setTitle(event.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for='inp-post'>Postagem</Label>
                        <Input id='inp-post' name='name' type='textarea' placeholder='Sua postagem' value={body} onChange={event => setBody(event.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for='inp-post'>Tipo</Label>
                        <select id='slt-room' className='form-select' onChange={(event) => onSelectType(event.target.value)} value={type}>
                            <option value="HOTEL_GENERAL">Genérico</option>
                            <option value="HOTEL_SERVICE">Serviço do hotel</option>
                            <option value="HOTEL_PRODUCT">Produto do hotel</option>
                        </select>
                    </FormGroup>
                    <FormGroup hidden={!(type === 'HOTEL_SERVICE') && !(type === 'HOTEL_PRODUCT')}>
                        <Label>Referência da postagem</Label>
                        {
                            typeOptions.length > 0 ?
                                <select id='slt-room' className='form-select' onChange={(event) => setTypeId(event.target.value)} value={typeId}>
                                    {
                                        typeOptions.map((t, i) => <option key={t.id} value={t.id}>{t.name}</option>)
                                    }
                                </select> : <div />
                        }
                    </FormGroup>
                    <FormGroup hidden={type != 'EXTERNAL_LINK'}>
                        <Label for='inp-ext-link'>Link externo</Label>
                        <Input id='inp-ext-link' value={typeId} name='name' type='text' placeholder='Digite a url aqui' onChange={(event) => setTypeId(event.target.value)} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Tags</Label>
                        <br />
                        <Label>
                            <Input id='inp-rest-tag' type='checkbox' checked={hasTagValue('RESTAURANT')} onChange={() => checkTagValue('RESTAURANT')}></Input> Restaurante
                        </Label>
                        <br />
                        <Label>
                            <Input id='inp-trip-tag' type='checkbox' checked={hasTagValue('TRIP')} onChange={() => checkTagValue('TRIP')}></Input> Viagem
                        </Label>
                        <br />
                        <Label>
                            <Input id='inp-place-tag' type='checkbox' checked={hasTagValue('PLACE')} onChange={() => checkTagValue('PLACE')}></Input> Lugar
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label>Imagens</Label>
                        <Input id="inp-image" name="image" type="file" multiple accept='image/*' onChange={e => setImagesToAdd(Array.from(e.target.files))} />
                        <hr />
                        <Table responsive className="mr-50 ml-50">
                            <thead>
                                <tr>
                                    <th scope="col"><span className="ps-3">Imagem</span></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    images.filter(item => !imagesToDelete.includes(item)).map((image, index) => {
                                        return (
                                            <tr>
                                                <td colSpan={10}>
                                                    <ImageView className="rounded-circle ms-4" src={image} width={36} height={36} />
                                                </td>
                                                <td>
                                                    <CloseButton onClick={() => { removeImage(image) }}></CloseButton>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    imagesToAdd.map((image, index) => {
                                        return (
                                            <tr>
                                                <td colSpan={10}>
                                                    <img className="rounded-circle ms-4" src={URL.createObjectURL(image)} width={36} height={36} />
                                                </td>
                                                <td>
                                                    <CloseButton onClick={() => { removeImageFromSelected(image) }}></CloseButton>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </FormGroup>
                </Form>
                <div className="mb-6">
                    <LoadingState show={!isPostLoaded} className="mb-3"></LoadingState>
                    <br />
                    <br />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="text-button" color="primary" onClick={save}>Salvar</Button>
                <Button className="text-button ms-2" color="secondary" onClick={onClose}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}