const Center = ({ className, children, vertical, horizontal, style }) => {
    const centerVertical = vertical === undefined || vertical === true
    const centerHorizontal = horizontal === undefined || horizontal === true

    var classValue = 'd-flex '

    if (centerVertical === true) {
        classValue = classValue.concat('align-items-center ')
    }

    if (centerHorizontal === true) {
        classValue = classValue.concat('justify-content-center ')
    }

    classValue = classValue.concat(className)

    return <div className={classValue} style={style}>{children}</div>
}

export default Center