import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useContext, useState } from "react"
import { ToastContext } from "../../../../context/ToastContext"
import api from "../../../../services/api"
import LoaderButton from "../../../../components/Button/LoaderButton"
import OperatorForm from "./OperatorForm"

const OperatorRegisterModal = (props) => {

    const [form, setForm] = useState({ image: null, name: null })
    const [loading, setLoading] = useState(false)

    const toast = useContext(ToastContext)

    function submitForm() {
        if (!isFormValid()) {
            return
        }

        setLoading(true)

        api.post('/v1/operators', { name: form.name })
            .then(_ => {
                props.onRegister()
                props.toggle()
            })
            .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
            .finally(_ => setLoading(false))
    }

    function isFormValid() {
        return form.name != null && form.name.length > 4
    }

    return (
        <Modal isOpen={props.open} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                Novo colaborador
            </ModalHeader>
            <ModalBody>
                <OperatorForm onSubmit={submitForm} onChange={form => setForm(form)} />
            </ModalBody>
            <ModalFooter>
                <Button className="text-button me-2" color="secondary" onClick={props.toggle}>Cancelar</Button>
                <LoaderButton loading={loading} disabled={!isFormValid()} onClick={submitForm} color="primary text-button">Salvar</LoaderButton>
            </ModalFooter>
        </Modal>
    )
}

export default OperatorRegisterModal