import 'react-day-picker/dist/style.css';
import 'rc-time-picker/assets/index.css';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Spinner } from "reactstrap"
import { Calendar } from "react-multi-date-picker";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from '../../../../../../context/ToastContext';
import moment from "moment";
import StyledTimePicker from "./AlohaTimePicker/StyledTimePicker.styled"
import api from "../../../../../../services/api";
import axios from 'axios';

const gregorian_pt_br_lowercase = {
    name: "gregorian_pt_br_lowercase",
    months: [
        ["janeiro", "jan"],
        ["fevereiro", "fev"],
        ["março", "mar"],
        ["abril", "abr"],
        ["maio", "mai"],
        ["junho", "jun"],
        ["julho", "jul"],
        ["agosto", "ago"],
        ["setembro", "set"],
        ["outubro", "out"],
        ["novembro", "nov"],
        ["dezembro", "dez"],
    ],
    weekDays: [
        ["sábado", "sab"],
        ["domingo", "dom"],
        ["segunda", "seg"],
        ["terça", "ter"],
        ["quarta", "qua"],
        ["quinta", "qui"],
        ["sexta", "sex"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
}

export const CreateAvailabilityModal = props => {

    const DEFAULT_START_TIME = moment({ h: 8 })
    const DEFAULT_END_TIME = moment({ h: 11 })

    const [loading, setLoading] = useState(false)

    const [dates, setDates] = useState([])
    const [startTime, setStartTime] = useState(DEFAULT_START_TIME)
    const [endTime, setEndTime] = useState(DEFAULT_END_TIME)

    const [places, setPlaces] = useState(1)
    const [timeError, setTimeError] = useState('')
    
    const toast = useContext(ToastContext)

    useEffect(_ => {
        if (startTime.isBefore(endTime)) {
            setTimeError('')
        } else {
            setTimeError('A hora inicial deve ser após a hora final')
        }
    }, [startTime, endTime])

    function createAvailability() {
        setLoading(true)

        const urlRequests = dates.map(date => ({
            path: `/v1/schedules/${props.scheduleId}/time-slots`,
            params: {
                start_date_time: date.toISOString().split('T')[0] + 'T' + startTime.format("HH:mm:ss") + "." + startTime.milliseconds() + 'Z',
                end_date_time: date.toISOString().split('T')[0] + 'T' + endTime.format("HH:mm:ss") + "." + endTime.milliseconds() + 'Z',
                places: places
            }
        }))

        const requests = urlRequests.map(i => api.post(i.path, i.params))

        axios.all(requests)
            .then(handleSuccess)
            .catch(handleError)
            .finally(_ => setLoading(false))
    }

    function resetForm() {
        setStartTime(DEFAULT_START_TIME)
        setEndTime(DEFAULT_END_TIME)
        setDates([])
        setPlaces(1)
    }

    function handleVacancies(event) {
        const value = event.target.value;
        const newValue = (value >= 0) ? value : 0
        setPlaces(newValue)
    }

    function handleSuccess() {
        if (dates.length > 1) {
            toast.success(`Disponibilidades adicionadas`)
        } else {
            toast.success(`Disponibilidade adicionada`)
        }

        props.toggle()
        resetForm()
    }

    function handleError(e) {
        toast.error('Desculpe, ocorreu um erro!')
    }

    function isFormValid() {
        return dates.length > 0 && startTime && endTime && startTime.isBefore(endTime)
    }

    return (
        <>
            <Modal isOpen={props.isOpen} size="md" toggle={props.toggle} onClosed={props.onClosed}>

                <ModalHeader toggle={props.toggle}>
                    Nova disponibilidade
                </ModalHeader>
                <ModalBody>

                    {loading.visible && <Progress
                        className="mb-3"
                        striped
                        animated
                        color='primary'
                        min={loading.min}
                        max={loading.max}
                        value={loading.value} />
                    }

                    <p className="body1">Preencha o formulário abaixo para adicionar uma disponibilidade na agenda</p>

                    <FormGroup>
                        <Label>Vagas disponíveis</Label>
                        <Input type="number" value={places} onChange={handleVacancies}></Input>
                    </FormGroup>


                    <div className="d-flex gap-2">
                        <FormGroup>
                            <Label>Hora inicial</Label> <br />
                            <StyledTimePicker className={timeError !== '' && "is-invalid"} value={startTime} onChange={setStartTime}></StyledTimePicker>
                        </FormGroup>
                        <FormGroup>
                            <Label>Hora final</Label>
                            <div>
                                <StyledTimePicker className={timeError !== '' && "is-invalid"} value={endTime} onChange={setEndTime} />
                                <div className="invalid-feedback">{timeError}</div>
                            </div>
                        </FormGroup>
                    </div>

                    <Label>Data(s)</Label>
                    <div className="d-flex gap-2">
                        <Calendar
                            shadow={false}
                            multiple={true}
                            value={dates}
                            minDate={new Date()}
                            format={'DD/MM/YYYY'}
                            onChange={items => { setDates(items.map(i => new Date(i)).sort((a, b) => a - b)) }}
                            locale={gregorian_pt_br_lowercase} />

                        <CalendarResult dates={dates} />
                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={_ => createAvailability()} disabled={!isFormValid() || loading}>
                        {(loading) ? <><span className="pe-3">Aguarde</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>

                    <Button color="secondary" onClick={props.toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const CalendarResult = (props) => {
    const dates = props.dates

    function renderDate(date, index) {

        let showMonthHeader = index <= 0 || dates[index - 1].getMonth() !== date.getMonth()
        let month = date.toLocaleString('default', { month: 'long' });

        return (
            <div key={`calendar-result-${index}`}>
                {showMonthHeader && <div className="text-center text-muted border-top border-bottom py-1 my-2"><small>{month}</small></div>}
                <div key={`date-key-sel-${index}`} className="text-center">
                    <small>{date.toLocaleDateString()}</small>
                </div>
            </div>
        )
    }

    return (
        <div className="border rounded flex-fill p-2 overflow-auto h-100" style={{ maxHeight: 300 }}>
            {dates.map((date, index) => renderDate(date, index))}
        </div>
    )
}