import React, { useState } from "react"
import { AlertCircle, Check } from "react-feather";
import { Spinner } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';

export const ToastContext = React.createContext({
    success: () => { },
    error: () => { },
    loading: () => { },
    hide: () => { },
    toasts: []
})

export const ToastProvider = ({ children }) => {

    const [toasts, setToasts] = useState([])

    const saveToasts = (toasts) => setToasts(toasts)

    const showToast = ({ icon, title, message }, autoHide = true) => {
        let id = uuidv4()

        saveToasts(toasts => [...toasts, { id, icon, title, message, isOpen: true }])

        if (autoHide) {
            setTimeout(_ => hide(id), 8000)
        }

        return id
    }

    const success = (message = 'Ação realizada com sucesso') => showToast({
        icon: <Check size={20} color='var(--green1)' />,
        title: 'Sucesso',
        message
    })

    const error = (message = 'Desculpe ocorreu um erro') => showToast({
        icon: <AlertCircle size={20} color='var(--red)' />,
        title: 'Ops',
        message
    })

    const loading = (message) => showToast({
        icon: <Spinner size="sm">Loading...</Spinner>,
        title: 'Aguarde',
        message: message
    })

    const hide = (id) => {
        saveToasts(toasts => toasts.filter(e => e.id !== id))
    }

    return (
        <ToastContext.Provider value={{ success, error, loading, hide, toasts }}>
            {children}
        </ToastContext.Provider>
    )
}