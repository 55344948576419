import { useContext, useState } from "react"
import { useEffect } from "react"
import { Eye, Plus, Trash2 } from "react-feather"
import { AccountCard } from "./Common"
import { Button, Form, FormGroup, Input, Label, List, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { run } from '../../../../utils/func'
import { ToastContext } from "../../../../context/ToastContext"
import api from '../../../../services/api'


const INITIAL_REGISTER_DATA = {
    security_type: "WPA",
    ssi: '',
    password: '',
    public_name: ''
}

const INITIAL_DETAIL_DATA = {
    security_type: '',
    ssi: '',
    password: '',
    public_name: ''
}

const AccountWifi = () => {

    const toast = useContext(ToastContext)

    const [wifiState, setWifiState] = useState({ data: [], loading: true, error: false })
    const [wifiStateVersion, setWifiStateVersion] = useState(1)

    const [registerData, setRegisterData] = useState(INITIAL_REGISTER_DATA)
    const [registerModal, setRegisterModal] = useState({ open: false, loading: false })
    const setRegisterModalLoading = (loading) => setRegisterModal(s => ({ ...s, loading }))
    const toogleRegisterModal = () => setRegisterModal(s => ({ open: !s.open }))

    const [deleteData, setDeleteData] = useState({ id: null })
    const [deleteModal, setDeleteModal] = useState({ open: false, loading: false })
    const toogleDeleteModal = () => setDeleteModal(s => ({ open: !s.open }))

    const [detailModal, setDetailModal] = useState({ wifi: INITIAL_DETAIL_DATA, open: false })
    const setDetailData = (wifi) => setDetailModal(s => ({ ...s, wifi }))
    const toogleDetailModal = () => setDetailModal(s => ({ ...s, open: !s.open }))

    const postRegister = () => {
        setRegisterModalLoading(true)

        const onSuccess = () => {
            setRegisterData(INITIAL_REGISTER_DATA)
            toast.success('Wi-Fi adicionado com sucesso!')
            setWifiStateVersion(v => v += 1)
        }

        api.post('v1/wifi', registerData)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => toogleRegisterModal())
    }

    const postDelete = () => {
        if (!deleteData.id) { return }
        setDeleteModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Wi-Fi excluído com sucesso!')
            setWifiStateVersion(v => v += 1)
        }

        api.delete(`v1/wifi/${deleteData.id}`)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setDeleteModal(s => ({ ...s, open: false, loading: false })))
    }

    function confirmDelete(id) {
        setDeleteData({ id })
        toogleDeleteModal()
    }

    function showDetail(wifi) {
        setDetailData(wifi)
        toogleDetailModal()
    }

    useEffect(_ => {
        api.get('v1/wifi')
            .then(res => setWifiState({ data: res.data, loading: false, error: false }))
            .catch(_ => setWifiState({ data: [], loading: false, error: true }))
    }, [wifiStateVersion])

    const WifiStateView = run(() => {
        if (wifiState.data.length === 0) {
            return (
                <div className="rouded border text-center py-4 mt-3">
                    <span className="text-muted">Nenhum WI-FI adicionado</span>
                </div>
            )
        }

        return (
            <ListGroup className="mt-4">
                {wifiState.data.map((wifi, index) => (
                    <ListGroupItem key={`wifi-item-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="body2">{wifi.public_name}</span>
                            <div>
                                <button className="btn btn-light me-1" onClick={_ => showDetail(wifi)}><Eye size={16} /></button>
                                <button className="btn btn-light" onClick={_ => confirmDelete(wifi.id)}><Trash2 size={16} /></button>
                            </div>
                        </div>
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    })

    const isSaveButtonEnabled = run(_ => {
        return (
            registerData.public_name && registerData.public_name.length > 3 &&
            registerData.ssi && registerData.ssi.length > 3 &&
            registerData.security_type && registerData.security_type.length >= 3 &&
            registerData.password && registerData.password.length >= 3
        )
    })

    return (
        <>
            <AccountCard loading={wifiState.loading} error={wifiState.error}>
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="body2 fw-bold"><span className="ms-2">WI-FI</span></h6>
                    <Button onClick={toogleRegisterModal} color="dark" className="border fw-bold px-2 py-1" outline style={{ minWidth: 90 }}><Plus size={16} /> <small>Adicionar</small></Button>
                </div>
                {WifiStateView}
            </AccountCard>

            <Modal isOpen={registerModal.open} toggle={toogleRegisterModal} scrollable={true} size='lg'>
                <ModalHeader toggle={toogleRegisterModal}>Adicionar Wi-Fi</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for='inp-name'>Nome público</Label>
                            <Input value={registerData.public_name} id='inp-name' name='name' type='text' placeholder='Digite aqui' onChange={e => setRegisterData(s => ({ ...s, public_name: e.target.value }))} />
                        </FormGroup>
                        <FormGroup>
                            <Label for='inp-ssi'>SSI</Label>
                            <Input value={registerData.ssi} id='inp-ssi' name='ssi' type='text' placeholder='Digite aqui' onChange={e => setRegisterData(s => ({ ...s, ssi: e.target.value }))} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inp-type">Tipo de segurança</Label>
                            <Input defaultValue={registerData.security_type} id="inp-type" type="select" name="type" onChange={e => setRegisterData(s => ({ ...s, security_type: e.target.value }))} >
                                <option value='WEP'>WEP</option>
                                <option value='WPA'>WPA</option>
                                <option value='WPA2'>WPA 2</option>
                                <option value='WPA3'>WPA 3</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for='inp-pass'>Senha</Label>
                            <Input value={registerData.password} id='inp-pass' name='pass' type='text' placeholder='Digite aqui' onChange={e => setRegisterData(s => ({ ...s, password: e.target.value }))} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={postRegister} disabled={registerModal.loading || !isSaveButtonEnabled}>
                        {(registerModal.loading) ? <><span className="pe-3">Salvando</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>
                    <Button color="secondary" onClick={toogleRegisterModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal.open} toggle={toogleDeleteModal}>
                <ModalHeader toggle={toogleDeleteModal}>Importante</ModalHeader>
                <ModalBody>
                    Deseja realmente excluir este Wi-Fi?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={deleteModal.loading} onClick={postDelete}>
                        {(deleteModal.loading) ? <><span className="pe-3">Excluindo..</span><Spinner size="sm">Loading...</Spinner></> : 'Excluir'}
                    </Button>
                    <Button color="secondary" onClick={toogleDeleteModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={detailModal.open} toggle={toogleDetailModal}>
                <ModalHeader toggle={toogleDetailModal}>{detailModal.wifi.public_name}</ModalHeader>
                <ModalBody>
                    <List type="unstyled">
                        <li className="mb-1"><b>SSI:</b> {detailModal.wifi.ssi}</li>
                        <li className="mb-1"><b>Senha:</b> {detailModal.wifi.password}</li>
                        <li><b>Segurança:</b> {detailModal.wifi.security_type}</li>
                    </List>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toogleDetailModal}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AccountWifi