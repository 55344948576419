export const SidebarContent = (props) => {
    return (
        <div className="w-100 flex-fill px-0 py-4 overflow-auto">
            {props.children}
        </div>
    )
}

export const SidebarBottom = (props) => {
    return (
        <div className="w-100 px-3 py-3" style={{ borderTop: '1px solid #ddd' }}>
            {props.children}
        </div>
    )
}

const SideBar = ({ className = '', children }) => {
    return (
        <div
            className={`bg-white d-none d-md-flex flex-column h-100 border-end ${className}`}
            style={{ minWidth: 300, boxShadow: '4px 0 8px #ddd' }}>
            {children}
        </div>
    )
}

export default SideBar