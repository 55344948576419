import { OperatorProvider } from '../context/OperatorContext'
import { StoreProvider } from '../context/StoreContext'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from "./../services/auth"
import { RequestsProvider } from '../context/requests/RequestsContext'
import AdminContainer from './admin/container/AdminContainer'
import Home from './public/Home'
import Login from './public/Login'
import SignUp from './public/SignUp'
import Dashboard from './admin/pages/dashboard/Dashboard'
import Guests from './admin/pages/guests/Guests'
import AgendaAvailability from './admin/pages/services/agenda/AgendaAvailability'
import Products from './admin/pages/products/Products'
import Events from './admin/pages/events/Events'
import Posts from './admin/pages/posts/Posts'
import Catalog from './admin/pages/services/catalog/Catalog'
import Agendas from './admin/pages/services/agenda/Agendas'
import Account from './admin/pages/account/Account'
import Requests from './admin/pages/requests/Requests'
import PlaceRequestsKanban from './admin/pages/requests/PlaceRequestsKanban'
import OperatorPage from './admin/pages/operators/OperatorPage'
import EspecialLink from './public/Link'
import Campaigns from './admin/pages/campaigns/Campaigns'
import CampaignDetails from './admin/pages/campaigns/CampaignDetails'
import DeleteAccount from './public/HowTo/DeleteAccount'

const AdminWrapper = () => {
    const menu = useLocation()

    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />
    }

    return (
        <StoreProvider>
            <OperatorProvider>
                <RequestsProvider>
                    <AdminContainer activeMenu={menu.pathname} />
                </RequestsProvider>
            </OperatorProvider>
        </StoreProvider>
    )
}

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='login' element={!isAuthenticated() ? <Login /> : <Navigate to="/dashboard/home" />} />
                <Route path='register' element={!isAuthenticated() ? <SignUp /> : <Navigate to="/dashboard/home" />} />
                <Route path='/business/client' element={<EspecialLink />} />
                <Route path='howto/delete-account' element={<DeleteAccount />} />
                <Route path='dashboard/*' element={<AdminWrapper />}>
                    <Route path='home' element={<Dashboard />} />
                    <Route path='guests' element={<Guests />} />
                    <Route path='services/catalog' element={<Catalog />} />
                    <Route path='services/agendas' element={<Agendas />} />
                    <Route path='services/agendas/:agendaId' element={<AgendaAvailability />} />
                    <Route path='products' element={<Products />} />
                    <Route path='events' element={<Events />} />
                    <Route path='campaigns' element={<Campaigns />} />
                    <Route path='campaigns/:campaignId' element={<CampaignDetails />} />
                    <Route path='posts' element={<Posts />} />
                    <Route path='account' element={<Account />} />
                    <Route path='requests' element={<Requests />} />
                    <Route path='requests/:placeId' element={<PlaceRequestsKanban />} />
                    <Route path='operators' element={<OperatorPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter