import './index.css'

export const CustomCheckbox = props => {
    
    function handleStatusChange(checked) {
        if(checked){
            props.setState(
                [...props.state, props.value]
            )
        } else {
            props.setState(
                props.state.filter((item) => item !== props.value)
            )
        }
    }

    const isChecked = props.state !== undefined ? props.state.includes(props.value): false

    const activeClass = isChecked ? 'active' : ''

    return (
        <div className={`custom-ck-button ${activeClass}`} onClick={_ => handleStatusChange(!isChecked)}>
            {props.name}
        </div>
    )
}