export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const validateEmail = (email) => {
    var regex = /\S+@\S+\.\S+/;
    return regex.test(email);
}

export const toCurrency = (value) => {
    return value.toFixed(2).replace('.', ',')
}