import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { login } from './../../../services/auth'
import { validateEmail } from './../../../utils/strings'
import { Spinner } from "reactstrap"
import { WHATSAPP_CONTACT_LINK } from "../../../strings"
import api from './../../../services/api'
import Logo from './../../../components/Logo'

const EMPTY = ""

const Errors = {
    invalidField: "Preencha e-mail e senha para continuar",
    invalidCredentials: "Email ou senha inválida",
    default: "Houve um problema com o login"
}

function Login() {

    const navigate = useNavigate()

    const [email, setEmail] = useState(EMPTY)
    const [password, setPassword] = useState(EMPTY)
    const [state, setState] = useState({ loading: false, error: null })

    function setLoading() {
        setState({ loading: true, error: null })
    }

    function setError(error) {
        setState({ loading: false, error })
    }

    async function attemptLogin(e) {
        e.preventDefault()
        setLoading()

        if (email === EMPTY || password === EMPTY) {
            return setError(Errors.invalidField)
        }

        try {
            const response = await api.post("/v1/login", { email, password })
            login(response.data.token)
            navigate('/dashboard/home')
        } catch (err) {
            let error = (err.response.status === 401) ? Errors.invalidCredentials : Errors.default
            setError(error)
        }
    }

    return (
        <div className="full center">
            <div className='d-flex flex-fill flex-wrap align-items-center justify-content-center'>
                <div className='p-3 mx-auto mx-md-5'>
                    <Logo onClick={_ => navigate('/')} />
                </div>
                <div className='p-3 mx-auto mx-md-5 w-100' style={{ maxWidth: '400px' }}>
                    <div className='text-center'>
                        <h1>Aloha Admin</h1>
                        <p className='body2'>Acesse sua conta de administrador</p>
                        {(state.error) ? <div className='alert alert-danger'>{state.error}</div> : <>   </>}
                    </div>
                    <form method='post' onSubmit={attemptLogin}>
                        <div className='form-group mb-3'>
                            <label className='form-label' htmlFor="inp-email">Email</label>
                            <input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder="seu@email.com"
                                id='inp-email'
                                name='email'
                                className='form-control'
                                type="email" />
                        </div>
                        <div className='form-group'>
                            <label className='form-label' htmlFor="inp-pass">Senha</label>
                            <input
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="********"
                                id='inp-pass'
                                name='password'
                                className='form-control'
                                type="password" />
                        </div>
                        <button
                            disabled={state.loading || !validateEmail(email) || password.length < 3}
                            className='mt-4 btn btn-primary w-100'
                            type='submit'>{(state.loading) ? <Spinner size="sm"></Spinner> : "Entrar"}</button>
                    </form>
                    <div className="pt-3">
                        <p className="text-center m-0">Ainda não tem conta?</p>
                        <p className="text-center m-0 mb-3">Entre em contato para se cadastrar</p>
                        <div className="d-flex justify-content-between">
                            <a target="_blank" rel="noreferrer" className="btn btn-sm btn-outline-success w-100 me-1" href={WHATSAPP_CONTACT_LINK}>WhatsApp</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login