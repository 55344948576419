import { Calendar, Clock, Target } from "react-feather"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ImageView from "../../../../../../components/ImageView/ImageView"

export const DetailsServiceModal = props => {

    const data = mapData(props.data)

    function mapData(service) {
        if (service.type === undefined) return {}
        return {
            image: service.image,
            name: service.name,
            type: service.type.toLowerCase(),
            price: {
                currency: service.price.currency.currency_code,
                value: service.price.number
            },
            description: service.description,
            time: service.duration_in_minutes,
            calendar: {
                type: 'REGISTERED',
                id: service.schedule_id ? service.schedule_id : 1
            }
        }
    }

    if (data.type === undefined) {
        return <></>
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
            <ModalHeader toggle={props.toggle}>Detalhes</ModalHeader>
            <ModalBody>

                <div className="d-flex gap-3">
                    <div>
                        <ImageView src={data.image} alt="Serviço" className="rounded" width="300" />
                    </div>
                    <div className="flex-fill">
                        <h2 className="text-h6">{data.name}</h2>
                        <p className="text-body1 mt-2">{data.description}</p>
                        <h5 className="my-3 text-success fw-bold">R$ {data.price.value}</h5>
                        <p className="text-muted mb-1"><Clock size={16} /> {data.time} min</p>
                        {data.type === 'scheduled' ? <p className="text-muted m-0"><Calendar size={16} /> Agendado</p> : <p className="text-muted m-0"><Target size={16} /> Não agendado</p>}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggle}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}