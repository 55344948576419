import { Spinner } from "reactstrap"
import { AlertCircle, LogOut, Menu } from "react-feather"
import Avatar from "../../../components/Avatar"
import OutlinedButton from "../../../components/Button/OutlinedButton"


const HeaderContainer = props => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center mb-3' style={{ minHeight: 140 }}>
            {props.children}
        </div>
    )
}

const HeaderLoading = () => {
    return (
        <HeaderContainer>
            <Spinner color="secondary" size="sm" >Loading...</Spinner>
            <small className='text-muted p-2'>Carregando..</small>
        </HeaderContainer>
    )
}

const HeaderData = ({ info }) => {
    return (
        <HeaderContainer>
            <Avatar image={info.hero_image_url} letter={info.hotel_name[0]} className="mt-0 mb-2" width={64} height={64} />
            <span className="body2">{info.hotel_name}</span>
            <span className="caption" style={{ color: '#AAAAAA' }}>{info.operator_email}</span>
        </HeaderContainer>
    )
}

const HeaderError = ({ refresh }) => {
    return (
        <HeaderContainer>
            <AlertCircle />
            <p className='text-muted px-2 mt-2 mb-1 m-0'>Ocorreu um erro</p>
            <button onClick={refresh} className='btn btn-link btn-sm'>Tentar novamente</button>
        </HeaderContainer>
    )
}

export const SidebarHeader = ({ state, data, refresh }) => {
    if (state === 'data') {
        return <HeaderData info={data} />
    }

    if (state === 'error') {
        return <HeaderError refresh={refresh} />
    }

    return <HeaderLoading />
}

export const LogoutButton = (props) => {
    return (
        <OutlinedButton onClick={props.onClick}>
            <div className='px-2 py-2 text-body2 d-flex align-items-center justify-content-between'>
                Sair <LogOut size={16} />
            </div>
        </OutlinedButton>
    )
}

export const AdminContent = ({ title, label, children }) => {
    const float = 'right'
    const boxSizing = 'border-box'
    return (
        <div className="background1 w-100 h-100 px-3 py-4 overflow-auto" style={{ float, boxSizing }}>
            <div className="h-100 container d-flex flex-column">
                <header>
                    <p className="text-muted">{label}</p>
                    <h1>{title}</h1>
                </header>
                <div className="py-4 flex-fill">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const AppBarMobile = ({ toggle }) => {
    return (
        <div className="p-3">
            <div className="bg-white shadow p-2 rounded">
                <button className="btn btn-light" onClick={toggle}>
                    <Menu />
                </button>
            </div>
        </div>
    )
}