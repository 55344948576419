import './theme/bootstrap.scss';
import './theme/typography.css';
import './theme/colors.css';
import './assets/css/index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
