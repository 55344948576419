import { Button, FormGroup, Input, Modal, ModalBody } from "reactstrap"
import { OpenClosedView } from "../components/OpenClosedView"
import { useContext, useState } from "react"
import { ToastContext } from "../../../../../context/ToastContext"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import AlohaApi from "../../../../../services/aloha-api"
import { StoreContext } from "../../../../../context/StoreContext"

export const PlaceChangeStateModal = (props) => {

    const place = props.place
    const [isPlaceOpen, setPlaceOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const toast = useContext(ToastContext)
    const store = useContext(StoreContext)

    function getPlaceStatusEnabled() {
        if (isPlaceOpen !== null) {
            return isPlaceOpen
        }

        return place.enabled
    }

    function togglePlaceEnabled() {
        if (isPlaceOpen === place.enabled) {
            return props.toggle()
        }

        setLoading(true)
        let request = isPlaceOpen ? AlohaApi.enablePlace(place.id) : AlohaApi.disablePlace(place.id)

        request
            .catch(_ => toast.error())
            .then(_ => store.places.refresh())
            .finally(_ => setLoading(false))
            .finally(_ => props.toggle())
    }

    function onModalOpened() {
        if (isPlaceOpen !== place.enabled) {
            setPlaceOpen(place.enabled)
        }
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" onOpened={onModalOpened}>
            <ModalBody className="p-4">
                <h6 className="text-h6 text-gray1">Abertura e fechamento de pedidos</h6>
                <p className="text-body2 text-gray2 mt-2">Os clientes não poderam fazer pedidos se o local estiver fechado</p>

                <div className="border rounded center-y justify-content-between mt-4 px-3 py-2">
                    <div className="center-y gap-2">
                        <FormGroup switch>
                            <Input
                                readOnly
                                type="switch"
                                role="switch"
                                checked={getPlaceStatusEnabled()}
                                onClick={_ => setPlaceOpen(!getPlaceStatusEnabled())}
                            />
                        </FormGroup>
                        <span>{place ? place.name : ''}</span>
                    </div>

                    <OpenClosedView open={getPlaceStatusEnabled()} />
                </div>

                <div className="w-100 d-flex justify-content-end gap-2 mt-4">
                    <Button onClick={props.toggle} className="text-button">Cancelar</Button>
                    <LoaderButton color="primary" loading={isLoading} onClick={togglePlaceEnabled}>Salvar</LoaderButton>
                </div>
            </ModalBody>
        </Modal>
    )
}