
import eventSource from '../../node_modules/event-source-polyfill/src/eventsource.js'
import { getToken } from "./auth";

function connect(onmessage, onerror) {
    const token = `Bearer ${getToken()}`;
    const specificationFilter = {
        "type": "EVENT_TYPE",
        "types": ["ORDER_ITEM_CREATED", "ORDER_ITEM_STATUS_CHANGED", "SERVICE_REQUEST_CREATED", "SERVICE_REQUEST_STATUS_CHANGED", "ORDER_ITEM_UPDATED", "SERVICE_REQUEST_CREATED", "CHECK_IN_REQUESTED", "REMOVED_CHECK_IN", "APPROVED_CHECK_IN"]
    }

    const headersObj = {
        'Authorization': token,
        'specification': JSON.stringify(specificationFilter),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    }

    const sseEndpoint = `${process.env.REACT_APP_BASE_URL}events`
    const server = new eventSource.EventSourcePolyfill(sseEndpoint, { headers: headersObj })

    server.onmessage = result => {
        if (onmessage) {
            onmessage(JSON.parse(result.data))
        }
    }

    server.onerror = err => {
        if (onerror) {
            onerror(err)
        }
    }
}

const SseEvents = { connect }

export default SseEvents