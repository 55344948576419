import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap"
import { StoreContext } from "../../../../../context/StoreContext"
import { useContext, useState } from "react"

import { RequestsContext } from "../../../../../context/requests/RequestsContext"
import { ToastContext } from "../../../../../context/ToastContext"
import AlohaSettings from "../../../../../services/aloha-settings"

const DEFAULT_TIME = '--:--'

const ModalRequestSettings = (props) => {

    const requests = useContext(RequestsContext)
    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const getPlacesInitialState = () => {
        return store.places.get().map(place => {
            let defaultPlace = {
                id: place.id,
                name: place.name,
                start_time: null,
                end_time: null,
                active: true
            }

            let placeSettings = AlohaSettings.notifications.getPlaces().find(el => el.id === place.id)
            return placeSettings || defaultPlace
        })
    }

    const [places, setPlaces] = useState(getPlacesInitialState())

    const save = () => {
        AlohaSettings.notifications.savePlaces(places)
        requests.refreshNotifications()
        props.toggle()
        toast.success('Configuração atualizada')
    }

    const cancel = () => {
        props.toggle()
    }

    function setStartTime(place, start_time) {
        place.start_time = start_time
        applyPlaceUpdate(place)
    }

    function setEndTime(place, end_time) {
        place.end_time = end_time
        applyPlaceUpdate(place)
    }

    function toggle(place) {
        place.active = !place.active
        applyPlaceUpdate(place)
    }

    function applyPlaceUpdate(place) {
        setPlaces(places.map(mPlace => (place.id === mPlace.id) ? place : mPlace))
    }

    function resetState() {
        setPlaces(getPlacesInitialState())
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" onClosed={resetState}>
            <ModalBody className="px-4">

                <div className="mt-3 mb-4">
                    <h6 className="text-h6 text-gray1 mb-2">Ativa ou desativar notificações</h6>
                    <p className="text-body1 text-gray2">Marque os locais que desejar receber as notificações neste computador</p>
                </div>

                {places.map(place => (
                    <PlaceItem
                        key={`place-dialog-${place.id}`}
                        place={place}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        toggle={toggle}
                    />
                ))}

            </ModalBody>
            <ModalFooter className="px-4">
                <Button className="text-button me-2" color="primary" onClick={save}>Salvar</Button>
                <Button className="text-button" color="secondary" onClick={cancel}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

const PlaceItem = ({ place, toggle, setStartTime, setEndTime }) => {

    function onChangeStartTime(e) {
        let value = e.target.value
        setStartTime(place, (value !== DEFAULT_TIME) ? value : null)
    }

    function onChangeEndTime(e) {
        let value = e.target.value
        setEndTime(place, (value !== DEFAULT_TIME) ? value : null)
    }

    function onToggle() {
        toggle(place)
    }

    return (
        <div className="d-flex justify-content-between align-items-center rounded border p-2 mb-2">
            <FormGroup switch disabled onClick={onToggle}>
                <Input type="switch" checked={place.active} readOnly />
                <Label className="ms-1 text-body1 text-gray1" check>{place.name}</Label>
            </FormGroup>
            <div className="d-flex gap-3">
                <div className="d-flex align-items-center gap-2" style={{ maxWidth: 140 }}>
                    <label for={`inp-start-time-${place.id}`} className="text-body2 text-gray2">De</label>
                    <Input
                        id={`inp-start-time-${place.id}`}
                        value={(place.active && place.start_time) || null}
                        className="text-body2 text-gray1"
                        type="time"
                        disabled={!place.active}
                        onChange={onChangeStartTime} />
                </div>
                <div className="d-flex align-items-center gap-2" style={{ maxWidth: 140 }}>
                    <label for={`inp-end-time-${place.id}`} className="text-body2 text-gray2">Até</label>
                    <Input
                        id={`inp-end-time-${place.id}`}
                        value={(place.active && place.end_time) || null}
                        className="text-body2 text-gray1"
                        type="time"
                        disabled={!place.active}
                        onChange={onChangeEndTime} />
                </div>
            </div>
        </div>
    )
}

export default ModalRequestSettings