import { useContext, useEffect, useState } from "react"
import { Button, Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Label, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from "reactstrap"
import { EmptyState, LoadingState } from "./States"
import { Clipboard, Edit, MoreVertical } from "react-feather"
import { GuestCheckoutModal } from "./checkout/GuestCheckoutModal"
import { ToastContext } from "../../../../context/ToastContext"
import { StoreContext } from "../../../../context/StoreContext"
import { PendingApprovalGuests } from "./components/PendingApprovalGuests"
import AlohaApi from "../../../../services/aloha-api"
import CreateGuestModal from "./modals/CreateGuestModal"
import SearchableRoom from "./components/SearchableRoom"
import EditGuestModal from "./modals/EditGuestModal"

const guestStatus = {
    'UNREGISTERED': 'Não Registrado',
    'REGISTERED': 'Registrado',
    'CHECKED_OUT': 'Encerrado'
}

const statusColor = {
    'UNREGISTERED': 'bg-warning',
    'REGISTERED': 'bg-success',
    'CHECKED_OUT': 'bg-secondary'
}

const Guests = () => {

    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const [status, setStatus] = useState('')
    const [room_id, setFilterRoom] = useState('')

    const [version, setVersion] = useState(1)
    const [guests, setGuests] = useState([])
    const [page, setPage] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(true)
    const [loading, setLoading] = useState(true)
    const [isFormOpen, setFormOpen] = useState(false)
    const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false)
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [selectedGuest, setSelectedGuest] = useState(null)

    useEffect(_ => {
        fetchGuests()
    }, [page, version])

    const toggleForm = () => setFormOpen(!isFormOpen)
    const toggleCheckout = () => setCheckoutModalOpen(!isCheckoutModalOpen)
    const toggleEdit = () => setEditModalOpen(!isEditModalOpen)

    async function fetchGuests() {
        try {
            setLoading(true)

            let rooms = store.rooms.get()
            let response = await AlohaApi.fetchGuests({ page, status, room_id })

            // filtering room
            let guests = response.data.result.map(guest => {
                let room = rooms.find(r => r.id === guest.room_id)
                let roomName = room ? room.name : ''
                return { ...guest, room: roomName }
            }).filter(o => {
                if (room_id !== '') {
                    return true
                } else {
                    return o.room !== "Quarto Teste (Aloha)"
                }
            })

            setGuests(guests)
            setHasNextPage(response.data.has_next_page)

        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    function reload() {
        setVersion(version + 1)
    }

    function handleError(e) {
        console.error(e)
        toast.error('Desculpe, ocorreu um erro!')
    }

    function formatDate(dateStr) {
        const date = new Date(dateStr)
        return date.toLocaleDateString()
    }

    function handleChangeFilterRoom(roomId) {
        setFilterRoom(roomId)
        if (page === 1) {
            reload()
        } else {
            setPage(1)
        }
    }

    function handleChangeStatus(status) {
        setStatus(status)
        if (page === 1) {
            reload()
        } else {
            setPage(1)
        }
    }

    function handleNextPage() {
        setPage(page + 1)
        setLoading(true)
    }

    function handlePreviousPage() {
        setPage(page - 1)
        setLoading(true)
    }

    function openCheckoutModal(guest) {
        setSelectedGuest(guest)
        toggleCheckout()
    }

    function openEditModal(guest) {
        setSelectedGuest(guest)
        toggleEdit()
    }

    return (
        <div>
            <PendingApprovalGuests className="mb-2" />
            
            <Card className="h-100">
                <Container className="p-4">
                    <Row className="align-items-end">
                        <Col sm={12} md={6} xl={5}>
                            <Label for="inp-search">Quarto</Label>
                            <SearchableRoom rooms={store.rooms.get()} onChangeRoomHandler={item => handleChangeFilterRoom(item ? item.value : '')} />
                        </Col>
                        <Col sm={12} md={6} xl={4} className="mt-2">
                            <Label for="inp-search">Status</Label>
                            <select className='form-select' onChange={(event) => handleChangeStatus(event.target.value)} value={status}>
                                <option value="">Todos</option>
                                <option value="REGISTERED">Registrado</option>
                                <option value="UNREGISTERED">Não Registrado</option>
                                <option value="CHECKED_OUT">Encerrado</option>
                            </select>
                        </Col>
                        <Col sm={12} md={12} xl={3} className="mt-3 mt-xl-0">
                            <Button className="text-button px-4 py-2" block color='primary' onClick={_ => toggleForm()}>Novo hóspede</Button>
                        </Col>
                    </Row>
                </Container>

                <Table responsive>
                    <thead>
                        <tr>
                            <th><span className="px-2">Email</span></th>
                            <th scope="col">Quarto</th>
                            <th scope="col">Chegada</th>
                            <th scope="col">Saída</th>
                            <th scope="col">Status</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && guests.map(guest =>
                            <tr key={guest.id}>
                                <td><span className="px-2">{guest.customer_email}</span></td>
                                <td><b className="px-1">{guest.room.replace('Quarto', '')}</b></td>
                                <td>{formatDate(guest.checkin)}</td>
                                <td>{formatDate(guest.checkout)}</td>
                                <td>
                                    <span className={'badge ' + statusColor[guest.status]}>
                                        {guestStatus[guest.status]}
                                    </span>
                                </td>
                                <td>
                                    <UncontrolledDropdown>
                                        <DropdownToggle className='btn-icon caret-off' color='transparent' size='sm' caret>
                                            <MoreVertical size={16} />
                                        </DropdownToggle>
                                        <DropdownMenu container='body' end>
                                            <DropdownItem onClick={_ => openEditModal(guest)}>
                                                <Edit className='mr-50' size={16} /><span className='ms-2 align-middle'>Editar</span>
                                            </DropdownItem>
                                            <DropdownItem onClick={_ => openCheckoutModal(guest)} disabled={guest.status === 'CHECKED_OUT'}>
                                                <Clipboard className='mr-50' size={16} />
                                                <span className='ms-2 align-middle'>Check-out</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <LoadingState show={loading} />

                <Pagination className="mt-auto ms-auto p-3">
                    <PaginationItem disabled={page <= 1}>
                        <PaginationLink previous onClick={e => { e.preventDefault(); handlePreviousPage() }} />
                    </PaginationItem>
                    <PaginationItem disabled={!hasNextPage}>
                        <PaginationLink next onClick={e => { e.preventDefault(); handleNextPage() }} />
                    </PaginationItem>
                </Pagination>

                <EmptyState show={!loading && guests.length === 0} />

                <GuestCheckoutModal
                    isOpen={isCheckoutModalOpen}
                    toggle={toggleCheckout}
                    guest={selectedGuest}
                    reload={reload} />

                <CreateGuestModal
                    isOpen={isFormOpen}
                    toggle={toggleForm}
                    reload={reload} />

                <EditGuestModal
                    toggleForm={toggleEdit}
                    reload={reload}
                    guest={selectedGuest}
                    isFormOpen={isEditModalOpen} />

            </Card>
        </div>
    )
}

export default Guests