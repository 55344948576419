import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDfb_eJz89-pukmLW_4RG-MP8HtLZXOLms",
  authDomain: "app-aloha.firebaseapp.com",
  databaseURL: "https://app-aloha-default-rtdb.firebaseio.com",
  projectId: "app-aloha",
  storageBucket: "app-aloha.appspot.com",
  messagingSenderId: "56584572945",
  appId: "1:56584572945:web:5b73d11bd391e892b821b0",
  measurementId: "G-Y9X4BPV1VW"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseDb = firebase.firestore();
export const firebaseAuth = firebase.auth();
