import { useEffect, useState } from "react"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { InputImage } from "../../../../components/Input/Input"
import ImageView from "../../../../components/ImageView/ImageView"
import StateView from "../../../../components/StateView"

const OperatorForm = (props) => {

    let isUpdate = props.operator

    const [name, setName] = useState(isUpdate ? props.operator.name : '')
    const [image, setImage] = useState(isUpdate ? props.operator.image : null)

    useEffect(_ => {
        props.onChange({ name, image })
    }, [name, image])

    return (
        <Form onSubmit={props.onSubmit}>

            <StateView show={isUpdate}>
                <center>
                    <ImageView src={image} width={120} height={120} className="rounded-circle mb-2" />
                </center>

                <FormGroup>
                    <Label>Foto</Label>
                    <InputImage onImage={setImage} />
                </FormGroup>
            </StateView>

            <FormGroup>
                <Label>Nome</Label>
                <Input value={name} onChange={e => setName(capitalize(e.target.value))} placeholder="Digite o nome do colaborador" />
            </FormGroup>
        </Form>
    )
}

function capitalize(text) {

    const capWord = (word) => {
        if (word.length > 2) {
            return word.charAt(0).toUpperCase() + word.slice(1)
        } else {
            return word
        }
    }

    return text.split(' ').map(capWord).join(' ')
}

export default OperatorForm