import React from 'react'
import PropTypes from 'prop-types'

export const StateMachine = props => {
    let states = []

    React.Children.forEach(props.children, (child) => {
        let key = child.props.stateKey
        states.push({ key, child })
    })

    return states.find(e => e.key === props.currentStateKey).child
}

export const State = props => {
    if (!props.stateKey) throw Error(`the props 'stateKey' is mandatory`)
    return props.children
}

StateMachine.propTypes = {
    currentStateKey: PropTypes.string.isRequired
}

State.propTypes = {
    stateKey: PropTypes.string.isRequired
}