import { createContext, useState } from "react";
import OperatorSwitchModal from "../components/Switch/OperatorSwitchModal";

export const OperatorContext = createContext({
    operator: null,
    setOperator: null,
    openOperatorSwitchModal: null,
    clear: () => {}
})

export const OperatorProvider = ({ children }) => {

    const [operator, setOperator] = useState(null)
    const [operatorModalOpen, setOperatorModalOpen] = useState(operator === null)
    const toggleOperatorModalOpen = () => setOperatorModalOpen(open => !open)
    const openOperatorSwitchModal = () => setOperatorModalOpen(true)
    const clear = () => setOperator(null)

    return (
        <OperatorContext.Provider value={{ operator, setOperator, openOperatorSwitchModal, clear }}>

            {children}

            <OperatorSwitchModal
                operator={operator}
                onConfirmOperator={operator => setOperator(operator)}
                isOpen={operatorModalOpen}
                toggle={toggleOperatorModalOpen} />

        </OperatorContext.Provider>
    )
}