import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { login } from '../../services/auth'
import { useContext } from "react"
import { StoreContext } from "../../context/StoreContext"
import { OperatorContext } from "../../context/OperatorContext"
import api from "../../services/api"
import StateView from "../StateView"
import Center from "../Center/Center"
import Avatar from "../Avatar"
import LoaderButton from "../Button/LoaderButton"

const OperatorSwitchModal = (props) => {

    const navigate = useNavigate()

    const store = useContext(StoreContext)
    const { operator } = useContext(OperatorContext)

    const [localOperator, setLocalOperator] = useState(operator)
    const [confirmLoading, setConfirmLoading] = useState(false)

    function onClickConfirmOperator() {
        setConfirmLoading(true)
        api.post('/v1/login/operators', { operator_id: localOperator.id })
            .then(res => {
                login(res.data.token)
                props.onConfirmOperator(localOperator)
                props.toggle()
            })
            .catch(e => {
                alert('ocorreu um erro!')
            })
            .finally(_ => setConfirmLoading(false))
    }

    function onClickAddOperator() {
        props.toggle()
        navigate('/dashboard/operators')
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onClosed={_ => setLocalOperator(operator)}>
            <ModalHeader toggle={props.toggle}>{props.operator ? 'Mudar colaborador' : 'Escolher colaborador'}</ModalHeader>
            <ModalBody>

                <StateView show={store.operators.get().length === 0}>
                    <Center className="flex-column text-center w-100" style={{ minHeight: 100 }}>
                        <div className="py-4">
                            <p className="text-body2 text-gray1 mb-3">Adicione os colaboradores que faram<br />a gestão do sistema <b>Aloha</b></p>
                            <Button className="border text-button" size="sm" color="secondary" onClick={onClickAddOperator}>+Adicionar</Button>
                        </div>
                    </Center>
                </StateView>

                <StateView show={store.operators.get().length > 0}>
                    {store.operators.get().map((op, index) => {
                        return (
                            <div key={`operator-${index}`} className="py-2 cursor-pointer hover" onClick={_ => setLocalOperator(op)}>
                                <div className="d-flex align-items-center">
                                    <Input readOnly type="radio" checked={localOperator && localOperator.id === op.id} />
                                    <Avatar className="ms-2" letter={op.name} image={op.image} width={40} height={40} />
                                    <span className="px-2 text-body2">{op.name}</span>
                                </div>
                            </div>
                        )
                    })}
                </StateView>

            </ModalBody>
            <ModalFooter>
                <LoaderButton 
                    disabled={!localOperator || (localOperator && operator && localOperator.id === operator.id)} 
                    loading={confirmLoading} 
                    onClick={onClickConfirmOperator} color="primary text-button">
                    Confirmar
                </LoaderButton>
            </ModalFooter>
        </Modal>
    )
}

export default OperatorSwitchModal