import { useState } from "react";
import { Info } from "react-feather";
import { Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from "reactstrap"
import { InputImage } from "../../../../components/Input/Input";
import LoaderButton from "../../../../components/Button/LoaderButton";
import AlohaApi from "../../../../services/aloha-api";
import ImageView from "../../../../components/ImageView/ImageView";

const CampaignRegisterModal = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [longDescriptionTooltipOpen, setLongDescriptionTooltipOpen] = useState(false);
    const [linkTooltipOpen, setLinkTooltipOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const linkToggle = () => setLinkTooltipOpen(!linkTooltipOpen);
    const longDescriptionToggle = () => setLongDescriptionTooltipOpen(!longDescriptionTooltipOpen)

    const [image, setImages] = useState({
        value: null,
        hasError: false,
        errorMessage: "Deve conter ao menos uma imagem"
    })

    const [title, setTitle] = useState({
        value: "",
        hasError: false,
        errorMessage: "Título não pode ser vazio"
    })

    const [shortDescription, setShortDescription] = useState({
        value: "",
        hasError: false,
        errorMessage: "Descrição não pode ser vazia"
    })

    const [longDescription, setLongDescription] = useState("")
    const [link, setLink] = useState("")
    const [audience, setAudience] = useState("ALL")

    const args = {
        autohide: true,
        flip: true,
    };

    function save() {
        validateForm()

        if (isValidForm()) {
            setLoading(true)
            let images = [image.value]
            AlohaApi.createCampaign(title.value, shortDescription.value, longDescription, audience, link, images)
                .then(_ => onSaveSuccess())
                .finally(_ => setLoading(false))
        }
    }

    function onSaveSuccess() {
        props.toggle() 
        props.onRegister()
    }

    function handleImage(file) {
        setImages(state => ({ ...state, hasError: false, value: file }))
    }

    function handleTitle(event) {
        const value = event.target.value;
        setTitle(state => ({ ...state, hasError: false, value: value }))
    }

    function handleShortDescription(event) {
        const value = event.target.value;
        setShortDescription(state => ({ ...state, hasError: false, value: value }))
    }

    function validateForm() {
        setImages(state => ({ ...state, hasError: !state.value }))
        setTitle(state => ({ ...state, hasError: !state.value || state.value === '' }))
        setShortDescription(state => ({ ...state, hasError: !state.value || state.value === '' }))
    }

    function isValidForm() {
        return image.value && title.value && title.value.length > 0 && shortDescription.value && shortDescription.value.length > 0
    }

    function onModalClose() {
        setTitle(state => ({...state, value: null, hasError: false}))
        setTitle(state => ({ ...state, value: null, hasError: false}))
        setShortDescription(state => ({ ...state, value: null, hasError: false}))
        setLongDescription('')
        setLink('')
        setAudience('ALL')
    }

    return (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg" onClosed={onModalClose}>
            <ModalHeader toggle={props.toggle}>
                Criar nova Campanha
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <ImageView alt={`Campaign image`} src={image.value} className='d-block m-auto rounded mb-3' />
                        <Label>Imagem</Label>
                        <InputImage id="inp-image" name="image" invalid={image.hasError} onImage={image => handleImage(image)} />
                        <FormFeedback valid={!image.hasError}>{image.errorMessage}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Título</Label>
                        <Input placeholder="Digite o título da campanha" invalid={title.hasError} name='title' type='text' onChange={handleTitle}></Input>
                        <FormFeedback valid={!title.hasError}>{title.errorMessage}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Descrição curta  <a target="_blank" rel="noreferrer" id="shortDescriptionTooltip"><Info size={16}></Info></a></Label>
                        <Input placeholder="Digite uma descrição" invalid={shortDescription.hasError} name='short-description' type='text' onChange={handleShortDescription}></Input>
                        <FormFeedback valid={!shortDescription.hasError}>{shortDescription.errorMessage}</FormFeedback>
                        <Tooltip {...args}
                            isOpen={tooltipOpen}
                            target="shortDescriptionTooltip"
                            placement="bottom"
                            toggle={toggle}>
                            Descrição breve que chame a atenção do cliente para saber mais detalhes da campanha
                        </Tooltip>
                    </FormGroup>
                    <FormGroup>
                        <Label>Descrição longa  <a target="_blank" rel="noreferrer" id="longDescriptionTooltip"><Info size={16}></Info></a></Label>
                        <Input
                            name="text"
                            type="textarea"
                            lines="4"
                            placeholder="Informe uma descrição completa"
                            onChange={e => { setLongDescription(e.target.value) }}
                            value={longDescription}
                        />
                        <Tooltip {...args}
                            isOpen={longDescriptionTooltipOpen}
                            target="longDescriptionTooltip"
                            placement="bottom"
                            toggle={longDescriptionToggle}>
                            Descrição completa da campanha
                        </Tooltip>
                    </FormGroup>
                    <FormGroup>
                        <Label>Link  <a target="_blank" rel="noreferrer" id="linkTooltip"><Info size={16}></Info></a></Label>
                        <Input placeholder="Digite um link" onChange={e => { setLink(e.target.value) }} value={link}></Input>
                        <Tooltip {...args}
                            isOpen={linkTooltipOpen}
                            target="linkTooltip"
                            placement="bottom"
                            toggle={linkToggle}>
                            Link de redirecionamento da campanha
                        </Tooltip>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inp-search">Público</Label>
                        <select className='form-select' onChange={(event) => setAudience(event.target.value)} value={audience}>
                            <option value="ALL">Todos</option>
                            <option value="HOSTED">Hospedados</option>
                            <option value="NOT_HOSTED">Não hospedados</option>
                        </select>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex gap-2">
                    <LoaderButton color="primary" onClick={save} loading={loading}>Enviar</LoaderButton>
                    <Button color="secondary" className="text-button" onClick={props.toggle}>Fechar</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CampaignRegisterModal