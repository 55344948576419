import { useRef } from "react"

const EventType = {
    ITEM_CREATED: 'ORDER_ITEM_CREATED',
    ITEM_UPDATED: 'ORDER_ITEM_UPDATED',
    ITEM_STATUS_CHANGED: 'ORDER_ITEM_STATUS_CHANGED'
}

/**
 * Handle the orders items received from server
 * 
 * Store all order items and build the orders with them
 */
const useRequestsOrdersHelper = () => {

    /**
     * the list of orders with items
     */
    const orderList = useRef([])

    /**
     * list of all order items
     */
    const orderItemList = useRef([])

    /**
     * Handle the message by event type 
     */
    function handleMessageReceive(data) {
        switch (data.event_type) {
            case EventType.ITEM_CREATED:
                handleItemCreated(data)
                break
            case EventType.ITEM_UPDATED:
                handleItemUpdated(data)
                break
            case EventType.ITEM_STATUS_CHANGED:
                handleOrderStatusUpdated(data)
                break
            default:
                break
        }
    }

    function handleItemCreated(data) {
        let item = mapOrderItem(data)
        updateItemsList(item)
        updateOrderList()
    }

    function handleItemUpdated(data) {
        let item = mapOrderItemUpdated(data)
        updateItemsList(item)
        updateOrderList()
    }

    function handleOrderStatusUpdated(data) {
        let item = mapOrderStatusChanged(data)
        orderList.current.forEach(el => {
            if (el.id === item.orderId) {
                el.status = item.status
            }
        })
    }

    /** Helper local functions **/

    function updateItemsList(item) {

        let oldItem = orderItemList.current.find(i => i.id === item.id)
        let newOrderItems = [...orderItemList.current.filter(el => el.id !== item.id)]

        if (oldItem) {
            // is an update
            newOrderItems.push({ ...oldItem, ...item })
        } else {
            // is an creation
            newOrderItems.push(item)
        }

        orderItemList.current = newOrderItems
    }

    function updateOrderList() {
        let ordersIds = [...new Set(orderItemList.current.map(el => el.orderId))]
        orderList.current = ordersIds.map(orderId => {
            // check order exists
            if (orderList.current.filter(el => el.id === orderId).length > 0) {
                let items = orderItemList.current.filter(item => item.orderId === orderId)
                let order = orderList.current.find(el => el.id === orderId)
                order.items = items
                return order
            } else {
                let items = orderItemList.current.filter(item => item.orderId === orderId)
                let firstItem = items[0]
                return mapOrder(firstItem, items)
            }
        })
    }

    return { handleMessageReceive, orders: orderList }
}

// ## - MAP FUNCTIONS

const mapOrder = (data, items = []) => {
    return {
        id: data.orderId,
        status: data.status,
        deliveryMode: data.deliveryMode,
        deliveryModeId: data.deliveryModeId,
        placeId: data.placeId,
        guestId: data.guestId,
        createdAt: data.createdAt,
        items: items
    }
}

const mapOrderItem = (data) => {
    return {
        id: data.metadata.order_item_id,
        orderId: data.metadata.order_id,
        guestId: data.metadata.guest_id,
        placeId: data.metadata.place_id,
        hotelId: data.metadata.hotel_id,
        name: data.metadata.item_name,
        quantity: data.metadata.quantity,
        details: data.metadata.details,
        itemPrice: data.metadata.item_price.number,
        unitPrice: data.metadata.unit_price.number,
        status: data.metadata.status,
        deliveryMode: data.metadata.delivery_mode.description,
        deliveryModeId: data.metadata.delivery_mode.id,
        createdAt: data.creation_at,
    }
}

const mapOrderItemUpdated = (data) => {
    return {
        id: data.metadata.order_item_id,
        orderId: data.metadata.order_id,
        quantity: data.metadata.new_quantity,
        details: data.metadata.new_details,
        placeId: data.metadata.place_id,
        createdAt: data.creation_at
    }
}

const mapOrderStatusChanged = (data) => {
    return {
        id: data.metadata.order_item_id,
        orderId: data.metadata.order_id,
        status: data.metadata.new_status,
        placeId: data.metadata.place_id,
        createdAt: data.creation_at
    }
}

// ## - END MAP FUNCTIONS

export default useRequestsOrdersHelper