import { useContext, useEffect } from "react"
import { useState } from "react"
import { AccountCard } from "./Common"
import { run, toSocialMediaUrl } from "../../../../utils/func"
import { ExternalLink, Plus, Trash2 } from "react-feather"
import { Button, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Tooltip } from "reactstrap"
import { ToastContext } from "../../../../context/ToastContext"
import api from '../../../../services/api'

const DEFAULT_TYPE = 'FACEBOOK'
const INITIAL_REGISTER_DATA = { type: DEFAULT_TYPE, name: null, url: null }

const EmptyState = () => {
    return (<p className="m-0 px-2 py-4 text-center text-muted">Nenhuma media social adicionada</p>)
}

const SocialMediaItem = (index, media, confirmDelete, onMouseEnter, onMouseLeave) => {
    return (
        <ListGroupItem key={`social-media-item-${media.name}`}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <span className="text-overline text-muted">{media.type}</span> <br />
                    <span className="text-body2">{media.name}</span> <br />
                </div>
                <div>
                    <a className="btn btn-light me-1"
                        target="_blank"
                        rel="noreferrer"
                        href={toSocialMediaUrl(media)}
                        id={`socia-media-id-${index}`}
                        onMouseEnter={_ => onMouseEnter(media, `socia-media-id-${index}`)}
                        onMouseLeave={onMouseLeave}>
                        <ExternalLink size={16} />
                    </a>
                    <button onClick={_ => confirmDelete(media)} className="btn btn-light"><Trash2 size={16} /></button>
                </div>
            </div>
        </ListGroupItem>
    )
}

const SocialMediaList = ({ medias, confirmDelete, onMouseEnter, onMouseLeave }) => {
    if (medias.length <= 0) {
        return <EmptyState />
    } else {
        return (
            <ListGroup className="mt-4">
                {medias.map((media, index) => SocialMediaItem(index, media, confirmDelete, onMouseEnter, onMouseLeave))}
            </ListGroup>
        )
    }
}

const AccountSocialMedia = _ => {

    const toast = useContext(ToastContext)

    const [tooltipState, setTooltipState] = useState({ open: false, targetId: '', content: '' })
    const toggleTooltip = () => setTooltipState(state => ({ ...state, open: !state.open }))

    const [state, setState] = useState({ data: [], loading: true, error: false })
    const [stateVersion, setStateVersion] = useState(1)

    const [registerData, setRegisterData] = useState(INITIAL_REGISTER_DATA)
    const [registerModal, setRegisterModal] = useState({ open: false, loading: false })
    const toogleRegisterModal = () => setRegisterModal(s => ({ ...s, open: !s.open }))

    const [deleteData, setDeleteData] = useState({ url: null })
    const [deleteModal, setDeleteModal] = useState({ open: false, loading: false })
    const toogleDeleteModal = () => setDeleteModal(s => ({ open: !s.open }))

    const isValidRegisterForm = run(_ => {
        let validType = registerData.type !== null && registerData.type !== undefined
        let validName = registerData.name && registerData.name.length > 2
        let validUrl = registerData.url && registerData.url.length > 2
        return validType && validName && validUrl
    })

    useEffect(_ => {
        api.get('social-medias')
            .then(res => setState({ data: res.data, loading: false, error: false }))
            .catch(_ => setState({ data: [], loading: false, error: true }))
    }, [stateVersion])

    const postRegister = () => {
        setRegisterModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Rede social adicionada com sucesso!')
            setStateVersion(v => v += 1)
            setRegisterData(INITIAL_REGISTER_DATA)
        }

        api.post('social-medias', registerData)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setRegisterModal(s => ({ ...s, open: false, loading: false })))
    }

    const postDelete = () => {
        if (!deleteData.url) { return }
        setDeleteModal(s => ({ ...s, loading: true }))

        const onSuccess = () => {
            toast.success('Rede social excluído com sucesso!')
            setStateVersion(v => v += 1)
        }

        api.delete(`social-medias/${deleteData.url}`)
            .then(_ => onSuccess())
            .catch(_ => toast.error('Ocorreu um erro, tente novamente!'))
            .finally(_ => setDeleteModal(s => ({ ...s, open: false, loading: false })))
    }

    function confirmDelete(url) {
        setDeleteData({ url })
        toogleDeleteModal()
    }

    return (
        <>
            <AccountCard loading={state.loading} error={state.error}>
                <div className="d-flex justify-content-between">
                    <h5 className="body2 fw-bold text-gray1">Redes sociais</h5>
                    <Button onClick={toogleRegisterModal} color="dark" className="border fw-bold px-2 py-1" outline>
                        <Plus size={16} /> <small>Adicionar</small>
                    </Button>
                </div>

                <div className="mt-2">
                    <SocialMediaList
                        medias={state.data}
                        confirmDelete={media => confirmDelete(media.url)}
                        onMouseEnter={(media, id) => setTooltipState({ open: true, targetId: id, content: media.url })}
                        onMouseLeave={_ => setTooltipState(state => ({ ...state, open: false }))}
                    />
                </div>
            </AccountCard>

            <Modal isOpen={registerModal.open} toggle={toogleRegisterModal}>
                <ModalHeader toggle={toogleRegisterModal}>Adicionar Social Media</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="inp-type">Tipo</Label>
                            <Input id="inp-type"
                                type="select"
                                onChange={e => setRegisterData(s => ({ ...s, type: e.target.value }))}
                                value={registerData.type}>
                                <option value='FACEBOOK'>Facebook</option>
                                <option value='INSTAGRAM'>Instagram</option>
                                <option value='TWITTER'>Twitter</option>
                                <option value='TRIPADVISOR'>Tripadvisor</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="inp-name">Nome</Label>
                            <Input value={registerData.name || ''}
                                id="inp-name"
                                type="text"
                                placeholder='Digite aqui'
                                onChange={e => setRegisterData(s => ({ ...s, name: e.target.value }))} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="inp-url">Endereço</Label>
                            <Input value={registerData.url || ''}
                                id="inp-url"
                                type="text"
                                placeholder='Digite aqui'
                                onChange={e => setRegisterData(s => ({ ...s, url: e.target.value }))} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={postRegister} color="primary" disabled={!isValidRegisterForm || registerModal.loading}>
                        {(registerModal.loading) ? <><span className="pe-3">Salvando</span><Spinner size="sm">Loading...</Spinner></> : 'Salvar'}
                    </Button>
                    <Button onClick={toogleRegisterModal} color="secondary">Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal.open} toggle={toogleDeleteModal}>
                <ModalHeader toggle={toogleDeleteModal}>Importante</ModalHeader>
                <ModalBody>Deseja realmente excluir esta rede social?</ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={deleteModal.loading} onClick={postDelete}>
                        {(deleteModal.loading) ? <><span className="pe-3">Excluindo..</span><Spinner size="sm">Loading...</Spinner></> : 'Excluir'}
                    </Button>
                    <Button color="secondary" onClick={toogleDeleteModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            {tooltipState.targetId && <Tooltip key={tooltipState.targetId} isOpen={tooltipState.open} target={tooltipState.targetId} toggle={toggleTooltip}>
                {tooltipState.content}
            </Tooltip>}

        </>
    )
}

export default AccountSocialMedia