import { Input } from "reactstrap"
import { React, useEffect, useState } from "react"
import { GeneralStatistics } from "./GeneralStatistics"
import { ProductsStatistics } from "./ProductsStatistics"
import { ServicesStatistics } from "./ServicesStatistics"
import { OrderStatistics } from "./OrderStatistics"
import { CardOffer } from "../../../../components/Card/CardOffer"
import { useNavigate } from "react-router-dom"
import { OperatorStatistics } from "./OperatorStatistics"
import AlohaApi from "../../../../services/aloha-api"

const FILTER_DAYS = [5, 10, 15, 20, 30]

const Dashboard = () => {

    const [days, setDays] = useState(FILTER_DAYS[0])
    const [showCard, setShowCard] = useState(false)

    const navigate = useNavigate()

    useEffect(_ => {
        AlohaApi.fetchPosts().then(res => setShowCard(res.data.result.length < 4))
    }, [])

    return (
        <div>
            <OrderStatistics className="mb-4 warning-card"></OrderStatistics>

            <CardOffer visible={showCard} onClick={_ => navigate('/dashboard/posts?action=create')} />

            {showCard ? <></> : <hr />}

            <div className="d-flex align-items-center justify-content-between mt-4">
                <h4>Relatório</h4>
                <div className="d-flex align-items-center">
                    <Input type="select" onChange={e => setDays(e.target.value)} style={{ minWidth: 120 }}>
                        {FILTER_DAYS.map((value, index) => (<option key={`days-range-key-${index}`} value={value}>{value} {value === 1 ? 'dia' : 'dias'}</option>))}
                    </Input>
                </div>
            </div>

            <GeneralStatistics className="mt-4" days={days} />
            <ProductsStatistics className="mt-4" days={days} />
            <ServicesStatistics className="flex-fill mt-4" days={days} />
            <OperatorStatistics className="mt-4" days={days} />
        </div>
    )
}

export default Dashboard