import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from "reactstrap"
import { Edit, Eye, EyeOff, Minimize, MoreVertical, Trash } from "react-feather"
import ImageView from "../../../../../components/ImageView/ImageView"

const MENU_ICON_SIZE = 15

const Actions = {
    detail: 'DETAIL',
    update: 'UPDATE',
    delete: 'DELETE',
    enable: 'ENABLE',
    disable: 'DISABLE'
}

const ProductTableRowItem = ({ product, onClickAction }) => {

    function showProductDetailed(event) {
        event.preventDefault()
        onClickAction(Actions.detail, product)
    }

    function showUpdateProductModal(event) {
        event.preventDefault()
        onClickAction(Actions.update, product)
    }

    function showDeleteProductModal(event) {
        event.preventDefault()
        onClickAction(Actions.delete, product)
    }

    function enableProduct(event) {
        event.preventDefault()
        onClickAction(Actions.enable, product)
    }

    function disableProduct(event) {
        event.preventDefault()
        onClickAction(Actions.disable, product)
    }

    return (
        <tr>
            <td style={{ cursor: 'pointer' }} onClick={e => showProductDetailed(e)}>
                <div className="d-flex center-y gap-2 ps-3">
                    <ImageView className="rounded" src={product.image} alt={product.name} width={36} height={36} />
                    <span className="d-block text-subtitle2">{product.name}</span>
                </div>
            </td>
            <td>
                <span className="text-body2">{product.product_type}</span>
            </td>
            <td>
                <span className="text-body2">
                    R$ {product.price.number.toFixed(2).replace('.', ',')}
                </span>
            </td>
            <td>
                <span className="text-body2">
                    {(product.hotel_place && product.hotel_place.name) || ''}
                </span>
            </td>
            <td>
                <div className="center-y">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4.22656" r="4" fill={product.enabled ? '#6FCF97' : '#EB5757'} />
                    </svg>
                    <span className="px-2 text-body2">
                        {product.enabled ? 'Ativo' : 'Inativo'}
                    </span>
                </div>
            </td>
            <td>
                <UncontrolledDropdown>
                    <DropdownToggle className='btn-icon caret-off' color='transparent' size='sm' caret>
                        <MoreVertical size={MENU_ICON_SIZE} />
                    </DropdownToggle>
                    <DropdownMenu end container='body'>
                        <DropdownItem onClick={e => showProductDetailed(e, product)}>
                            <Minimize className='mr-50' size={MENU_ICON_SIZE} />
                            <span className='ms-2 align-middle text-body2'>Detalhes</span>
                        </DropdownItem>
                        <DropdownItem onClick={e => showUpdateProductModal(e, product)}>
                            <Edit className='mr-50' size={MENU_ICON_SIZE} />
                            <span className='ms-2 align-middle text-body2'>Editar</span>
                        </DropdownItem>
                        <DropdownItem onClick={e => showDeleteProductModal(e)}>
                            <Trash className='mr-50' size={MENU_ICON_SIZE} />
                            <span className='ms-2 align-middle text-body2'>Excluir</span>
                        </DropdownItem>
                        {!product.enabled ? <DropdownItem onClick={e => enableProduct(e)}>
                            <Eye className='mr-50' size={MENU_ICON_SIZE} />
                            <span className='ms-2 align-middle text-body2'>Ativar</span>
                        </DropdownItem> : <DropdownItem onClick={e => disableProduct(e)}>
                            <EyeOff className='mr-50' size={MENU_ICON_SIZE} />
                            <span className='ms-2 align-middle text-body2'>Desativar</span>
                        </DropdownItem>
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </td>
        </tr>
    )
}

const ProductsTable = ({
    products,
    onClickProductDetail,
    onClickProductDelete,
    onClickProductUpdate,
    onClickProductEnable,
    onClickProductDisable
}) => {

    function onClickAction(action, product) {
        switch (action) {
            case Actions.detail:
                onClickProductDetail(product)
                break
            case Actions.update:
                onClickProductUpdate(product)
                break
            case Actions.delete:
                onClickProductDelete(product)
                break
            case Actions.enable:
                onClickProductEnable(product)
                break
            case Actions.disable:
                onClickProductDisable(product)
                break
            default:
                throw Error('No mapped action', action)
        }
    }

    return (
        <Table responsive>
            <thead>
                <tr>
                    <th scope="col"><span className="ps-3">Nome</span></th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Local</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => {
                    return <ProductTableRowItem
                        product={product}
                        onClickAction={onClickAction}
                        key={`product-table-item-${index}-${product.id}`} />
                })}
            </tbody>
        </Table>
    )
}

export default ProductsTable