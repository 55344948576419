import { useContext, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { DetailsPanel } from "../components/DetailsPanel"
import { StoreContext } from "../../../../../../context/StoreContext"
import api from "../../../../../../services/api"

export const CreateServiceModal = props => {

    const store = useContext(StoreContext)

    const [stepState, setStepState] = useState(0)
    const [loading, setLoading] = useState(false)
    const [schedulePage, setSchedulePage] = useState(1)
    const [scheduleOptions, setScheduleOptions] = useState([])
    const [data, setData] = useState({
        image: null,
        name: '',
        type: 'scheduled',
        price: {
            currency: 'BRL',
            value: 0
        },
        place: store.places.get()[0].id,
        description: '',
        time: 0,
        calendar: {
            type: 'NEW',
            id: null,
            name: ''
        },
        minimumLeadTime: 0
    })
    const start = new Date().toISOString().replace('Z', '').replace('/', '-').slice(0, -4)
    const end = new Date().toISOString().replace('Z', '').replace('/', '-').slice(0, -4)

    const [imageValidation, setImageValidation] = useState({
        errorMessage: '',
        isEditionStarted: false
    })
    const [nameValidation, setNameValidation] = useState({
        errorMessage: "",
        isEditionStarted: false
    })
    const [descriptionValidation, setDescriptionValidation] = useState({
        errorMessage: "",
        isEditionStarted: false
    })

    const [nameAgendaValidation, setNameAgendaValidation] = useState({
        errorMessage: "",
        isEditionStarted: false
    })

    function setValidation(setValidation, errorMessage, isEditionStarted) {
        setValidation({
            errorMessage: errorMessage,
            isEditionStarted: isEditionStarted
        })
    }

    const setPlace = (placeId) => setData(data => ({ ...data, place: placeId }))
    const setCalendarAction = (action) => setData(data => ({ ...data, calendar: { ...data.calendar, type: action } }))
    const setCalendarId = (calendarId) => setData(data => ({ ...data, calendar: { ...data.calendar, id: calendarId } }))
    const setCalendarName = (calendarName) => setData(data => ({ ...data, calendar: { ...data.calendar, name: calendarName } }))
    const setServiceName = (serviceName) => setData(data => ({ ...data, name: serviceName }))
    const setPrice = (priceValue) => setData(data => ({ ...data, price: { currency: 'BRL', value: priceValue } }))
    const setDescription = (serviceDescription) => setData(data => ({ ...data, description: serviceDescription }))
    const setImage = (image) => setData(data => ({ ...data, image }))
    const setServiceType = (serviceType) => setData(data => ({ ...data, type: serviceType }))
    const setTime = (serviceTime) => setData(data => ({ ...data, time: serviceTime }))
    const setMinimumLeadTime = (leadTime) => setData(data => ({ ...data, minimumLeadTime: leadTime }))

    useEffect(_ => {
        api.get(`/v1/schedules?page=${schedulePage}&start=${start}&end=${end}`)
            .then(res => {
                setScheduleOptions([...scheduleOptions, ...res.data.result])

                if (schedulePage === 1 && res.data.result.length > 0) {
                    setCalendarId(res.data.result[0].schedule.id)
                }

                if (res.data.has_next_page) {
                    setSchedulePage(schedulePage + 1)
                }
            })
    }, [])

    function createService() {
        setLoading(true)
        const formData = createFormData()
        const config = { headers: { "Content-Type": "multipart/form-data" } }
        api.post('/v1/services', formData, config)
            .then(item => {
                props.isCalendarCreated(data.calendar.type === "NEW")
                props.setSelectedService(item.data)
                props.reload()
                closeAndClean()
            })
            .finally(_ => setLoading(false))
    }

    function createFormData() {
        var formData = new FormData()
        formData.append('name', data.name)
        formData.append('type', data.type)
        formData.append('price_value', data.price.value)
        formData.append('price_currency', data.price.currency)
        formData.append('place_id', data.place)
        formData.append('description', data.description)
        formData.append('time', data.time)
        formData.append('calendar_type', data.calendar.type)
        formData.append('calendar_name', data.calendar.name)
        formData.append('calendar_id', data.calendar.id)
        formData.append('image', data.image)
        formData.append('minimum_lead_time', data.minimumLeadTime)

        return formData
    }

    function closeAndClean() {
        setStepState(0)
        setData(
            {
                image: null,
                name: '',
                type: 'scheduled',
                price: {
                    currency: 'BRL',
                    value: 0
                },
                place: store.places.get()[0].id,
                description: '',
                time: 0,
                calendar: {
                    type: 'NEW',
                    id: null,
                    name: ''
                },
                minimumLeadTime: 0
            }
        )

        setLoading(false)
        setSchedulePage(1)
        setValidation(setImageValidation, '', false)
        setValidation(setNameValidation, '', false)
        setValidation(setDescriptionValidation, '', false)
        setValidation(setNameAgendaValidation, '', false)
        props.toggle()
    }

    function getStepItemClass(state) {
        if (state === stepState) {
            return "c-stepper__item"
        }
        if (state < stepState) {
            return "c-stepper__item__success"
        }

        return "c-stepper__item__next"
    }

    function getStepTitleClass(state) {
        if (state === stepState) {
            return "c-stepper__title"
        }
        if (state < stepState) {
            return "c-stepper__title__success"
        }

        return "c-stepper__title__next"
    }

    function getCardClass(state) {
        if (state === data.calendar.type) {
            return "selected-card"
        }

        return "disabled-card"
    }

    function nextStep() {
        if (stepState < 2) {
            setStepState(stepState + 1)
        }
    }

    function prevStep() {
        if (stepState > 0) {
            setStepState(stepState - 1)
        }
    }

    function isNextEnable() {
        var conditions = {
            0: data.image !== null && data.name !== null && data.name !== '' && data.price.value >= 0 && data.description !== null && data.description !== '',
            1: data.time >= 0 && (data.type === "normal" || (
                data.type === "scheduled" && (
                    (data.calendar.type === "REGISTERED" && data.calendar.id !== null) || (data.calendar.type === "NEW" && data.calendar.name !== '')
                )
            ))
        }

        return conditions[stepState]
    }

    function handleImages(event) {
        const image = event.target.files[0]
        setImage(image)

        const errorMessage = image === null ? 'Imagem não pode ser vazia' : ''
        setValidation(setImageValidation, errorMessage, true)
    }

    function handleName(event) {
        const name = event.target.value;
        setServiceName(name);

        const errorMessage = name === '' ? 'Nome não pode ser vazio' : ''
        setValidation(setNameValidation, errorMessage, true)
    }

    function handleDescription(event) {
        const description = event.target.value;
        setDescription(description);

        const errorMessage = description === '' ? 'Descrição não pode ser vazia' : ''
        setValidation(setDescriptionValidation, errorMessage, true)
    }

    function handlePrice(event) {
        const value = event.target.value
        const price = value >= 0 ? value : 0
        setPrice(price)
    }

    function handleTime(event) {
        const value = event.target.value
        const time = value >= 0 ? value : 0
        setTime(time)
    }

    function handleNameAgenda(event) {
        const nameAgenda = event.target.value;
        setCalendarName(nameAgenda);

        const errorMessage = nameAgenda === '' ? 'Nome da agenda não pode ser vazia' : ''
        setValidation(setNameAgendaValidation, errorMessage, true)
    }

    function informationPanel() {
        return (
            <Form>
                {data.image && <div className="text-center"><img src={URL.createObjectURL(data.image)} alt="Serviço" width={124} /></div>}
                <FormGroup>
                    <Label for='inp-image'>Imagem</Label>
                    <Input id="inp-image" className={imageValidation.errorMessage === '' ? '' : 'is-invalid'} name="image" type="file" accept='image/*' onChange={handleImages} />
                    <div className="invalid-feedback">{imageValidation.errorMessage}</div>
                </FormGroup>
                <FormGroup>
                    <Label for="inp-place">Local</Label>
                    <Input
                        value={data.place || -1}
                        id="inp-place" name="select" type="select" onChange={e => setPlace(e.target.value)}>
                        {store.places.get().map(place => <option key={`place-option-${place.id}`} value={place.id}>{place.name}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for='inp-name'>Nome</Label>
                    <Input id="inp-name" className={nameValidation.errorMessage === '' ? '' : 'is-invalid'} name="name" type="text" value={data.name} onChange={handleName} />
                    <div className="invalid-feedback">{nameValidation.errorMessage}</div>
                </FormGroup>
                <FormGroup>
                    <Label for='inp-price'>Preço</Label>
                    <Input id="inp-price" name="price" type="number" value={data.price.value} onChange={handlePrice} />
                </FormGroup>
                <FormGroup>
                    <Label for='inp-description'>Descrição</Label>
                    <Input id="inp-description" className={descriptionValidation.errorMessage === '' ? '' : 'is-invalid'} name="description" type="textarea" value={data.description} onChange={handleDescription} />
                    <div className="invalid-feedback">{descriptionValidation.errorMessage}</div>
                </FormGroup>
            </Form>
        )
    }

    function productTypePanel() {
        return (
            <Form>
                <FormGroup>
                    <Label for='inp-time'>Tempo do serviço em minutos</Label>
                    <Input id="inp-time" name="time" type="number" value={data.time} onChange={handleTime} />
                </FormGroup>
                <FormGroup>
                    <Label for="inp-type">Tipo</Label>
                    <Input id="inp-type" name="select" type="select" value={data.type} onChange={e => { setServiceType(e.target.value) }}>
                        <option key="normal" value="normal">
                            Sem agendamento
                        </option>
                        <option key="scheduled" value="scheduled">
                            Com agendamento
                        </option>
                    </Input>
                </FormGroup>
                <div style={data.type === 'scheduled' ? {} : { display: 'none' }}>
                    <FormGroup>
                        <br />
                        <h6>Escolha uma agenda ou crie uma nova</h6>
                        <br />
                        <Row>
                            <Col md={6}>
                                <Card className={getCardClass('REGISTERED')} onClick={() => { setCalendarAction('REGISTERED') }}>
                                    <CardBody>
                                        <div className="text-button mb-2">Agenda Existente</div>
                                        <div className="text-body2">Utilize uma agenda existente</div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className={getCardClass('NEW')} onClick={() => { setCalendarAction('NEW') }}>
                                    <CardBody> 
                                        <div className="text-button mb-2">Nova Agenda</div>
                                        <div className="text-body2">Cadastre uma agenda nova</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </FormGroup>
                    <br />
                    <FormGroup style={data.calendar.type === 'NEW' ? {} : { display: 'none' }}>
                        <Label for='inp-schedule-name'>Nome da nova agenda</Label>
                        <Input id="inp-schedule-name" className={nameAgendaValidation.errorMessage === '' ? '' : 'is-invalid'} name="schedule-name" type="text" value={data.calendar.name} onChange={handleNameAgenda} />
                        <div className="invalid-feedback">{nameAgendaValidation.errorMessage}</div>
                    </FormGroup>
                    <FormGroup style={data.calendar.type === 'REGISTERED' ? {} : { display: 'none' }}>
                        <Label for="inp-schedule">Agenda</Label>
                        <Input
                            value={data.calendar.id || -1}
                            id="inp-schedule" name="select" type="select" onChange={e => setCalendarId(e.target.value)}>
                            {scheduleOptions.map(item => <option key={`schedule-option-${item.schedule.id}`} value={item.schedule.id}>{item.schedule.name}</option>)}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inp-schedule">Lead Time (Minutos)</Label>
                        <Input
                            value={data.minimumLeadTime || 0}
                            id="inp-leadtime" name="lead-time" type="number" onChange={e => setMinimumLeadTime(e.target.value)}>
                        </Input>
                    </FormGroup>
                </div>
            </Form>
        )
    }

    return <Modal isOpen={props.isOpen} toggle={closeAndClean} size="lg">
        <ModalHeader toggle={closeAndClean}>
            Novo serviço
        </ModalHeader>
        <ModalBody>
            {
                loading ? <></> :
                    <div className="wrapper option-1 option-1-1">
                        <ol className="c-stepper">
                            <li className={getStepItemClass(0)}>
                                <h3 className={getStepTitleClass(0)}>Informações</h3>
                            </li>
                            <li className={getStepItemClass(1)}>
                                <h3 className={getStepTitleClass(1)}>Tipo de serviço</h3>
                            </li>
                            <li className={getStepItemClass(2)}>
                                <h3 className={getStepTitleClass(2)}>Confirmação</h3>
                            </li>
                        </ol>
                    </div>
            }
            {
                loading ? <div className="position-absolute top-50 w-100 center mb-3">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div> : <Row className="m-4">
                    <div style={stepState === 0 ? {} : { display: 'none' }}>{informationPanel()}</div>
                    <div style={stepState === 1 ? {} : { display: 'none' }}>{productTypePanel()}</div>
                    <div style={stepState === 2 ? {} : { display: 'none' }}><DetailsPanel data={data} /></div>
                </Row>
            }
        </ModalBody>
        <ModalFooter>
            <Button className="text-button" color="primary" onClick={prevStep} style={stepState === 0 ? { display: 'none' } : {}} disabled={loading}>Voltar</Button>
            <Button className="text-button ms-2" color="primary" onClick={nextStep} style={stepState === 2 ? { display: 'none' } : {}} disabled={!isNextEnable() || loading}>Próximo</Button>
            <Button className="text-button ms-2" color="primary" onClick={createService} style={stepState === 2 ? {} : { display: 'none' }} disabled={loading}>Criar</Button>
            <Button className="text-button ms-2" color="secondary" onClick={closeAndClean} disabled={loading}>Cancelar</Button>
        </ModalFooter>
    </Modal>
}