import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import LoaderButton from "../../../../../../components/Button/LoaderButton"
import api from "../../../../../../services/api"

export const DeleteServiceModal = props => {

    const [loading, setLoading] = useState(false)

    function deleteService() {
        setLoading(true)
        api.delete(`/v1/services/${props.serviceId}`)
        .then(_ => {
            props.toggle()
            props.reload()
        })
        .catch(_ => handleError())
        .finally(_ => setLoading(false))
    }

    function handleError() {
        // TODO
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onClosed={props.reload}>
            <ModalHeader toggle={props.toggle}>
                Excluir serviço
            </ModalHeader>
            <ModalBody>
                <p className="text-body2">Deseja realmente excluir esse serviço?</p>
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="danger" loading={loading} onClick={deleteService}>Excluir</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={props.toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}