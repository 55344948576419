import { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"

import { ReactComponent as UpIcon } from './../../../../assets/images/dashboard_up.svg'
import { ReactComponent as UserIcon } from './../../../../assets/images/dashboard_user.svg'
import { ReactComponent as ProductsIcon } from './../../../../assets/images/dashboard_products.svg'

import { toCurrency } from "../../../../utils/strings"
import api from './../../../../services/api'


export const GeneralStatistics = props => {

    const days = props.days

    const [state, setState] = useState({ loading: false, error: false, data: null })

    useEffect(_ => {
        setState({ loading: true, error: false, data: [] })

        api.get(`v1/dashboards/counters?days=${days}`)
            .then((res) => setState({ loading: false, error: false, data: res.data }))
            .catch(_ => setState({ loading: false, error: true, data: [] }))
    }, [days])

    if (state.loading) {
        return (
            <Card {...props}>
                <CardBody className="p-4 center">
                    <Spinner size={'sm'}></Spinner>
                </CardBody>
            </Card>
        )
    }

    if (state.error && !state.loading) {
        return (
            <Card {...props}>
                <CardBody className="p-4 center">
                    <span>Desculpe, ocorreu um erro!</span>
                </CardBody>
            </Card>
        )
    }

    return (
        <Card {...props}>
            <CardBody>
                <div className="d-flex flex-wrap gap-4 justify-content-between">
                    <div className="d-flex align-items-center">
                        <UserIcon />
                        <div className="ms-3">
                            <h6><b>{(state.data && state.data.guests) || ''}</b></h6>
                            <span>Hóspedes</span>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <ProductsIcon />
                        <div className="ms-3">
                            <h6><b>R$ {(state.data && toCurrency(state.data.total_product_value)) || ''}</b></h6>
                            <span>Produtos vendidos</span>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <UpIcon />
                        <div className="ms-3">
                            <h6><b>R$ {(state.data && toCurrency(state.data.total_service_value)) || ''}</b></h6>
                            <span>Serviços realizados</span>
                        </div>
                    </div>

                </div>
            </CardBody>
        </Card>
    )
} 