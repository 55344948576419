import { useRef, useState } from "react"
import { Input } from "reactstrap"

const LazyInputSearch = (props) => {

    const delayDebounceFn = useRef('')
    const [value, setValue] = useState('')

    function onTextChange(event) {
        event.preventDefault()
        let newValue = event.target.value
        setValue(newValue)

        /**
         * clear old timeout to prevent many calls 
         */
        if (delayDebounceFn.current) {
            clearTimeout(delayDebounceFn.current)
        }

        delayDebounceFn.current = setTimeout(() => {
            props.onChange(newValue)
        }, 500)
    }

    return <Input {...props} value={value} onChange={e => onTextChange(e)} />
}

export default LazyInputSearch