import { Edit2 } from "react-feather"
import { Button, Card, Spinner } from "reactstrap"

export const EditButton = ({ onClick }) => {
    return <Button
        color="dark"
        className="border fw-bold px-2 py-1"
        outline
        style={{ minWidth: 90 }}
        onClick={onClick}><Edit2 size={16} /><small className="ms-2">Editar</small></Button>
}


export const AccountCard = ({ loading, error, children }) => {

    const contentLoading = (
        <div className="position-absolute w-100 h-100 left-0 top-0 center">
            <Spinner size="sm"></Spinner>
        </div>
    )

    const contentError = (
        <div className="position-absolute w-100 h-100 left-0 top-0 center">
            <span>Desculpe, ocorreu um erro!</span>
        </div>
    )

    return (
        (
            <Card className="py-4 px-3 mb-3">
                <div className="position-relative">
                    {loading && contentLoading}
                    {(!loading && error) && contentError}
                    <div className={(loading || error) ? 'invisible' : 'visible'}>
                        {children}
                    </div>
                </div>
            </Card>
        )
    )
}