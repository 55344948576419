import { useState } from "react";
import { Badge, Button, Carousel, CarouselIndicators, CarouselItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { LoadingState } from "../States";
import ImageView from "../../../../../components/ImageView/ImageView";
import api from "../../../../../services/api"

export const ViewPostDetailsModal = props => {

    const [state, setState] = useState({ activeIndex: 0 })
    const [items, setItems] = useState([])
    const [post, setPost] = useState({})

    function loadPost(postId) {
        api.get(`/v1/posts/${postId}`).then(res => {
            setPost(res.data)
            setItems(res.data.images.map((image, index) => { return { src: image } }))
        })
    }

    function loadGeneralInfo() {
        loadPost(props.postId)
    }

    function next() {
        const nextIndex = state.activeIndex === items.length - 1 ? 0 : state.activeIndex + 1;
        setState({ activeIndex: nextIndex });
    }

    function previous() {
        const nextIndex = state.activeIndex === 0 ? items.length - 1 : state.activeIndex - 1;
        setState({ activeIndex: nextIndex });
    }

    function goToIndex(newIndex) {
        setState({ activeIndex: newIndex });
    }

    function handleError() { 
        // TODO
    }

    const slides = items.map((item, index) => {
        return (
            <CarouselItem key={index}>
                <ImageView src={item.src} height={300} />
            </CarouselItem>
        );
    });

    function onClose() {
        setState({ activeIndex: 0 })
        setItems([])
        setPost({})
        props.toggle()
    }

    return (
        <Modal isOpen={props.isOpen} toggle={onClose} size='lg' onOpened={loadGeneralInfo}>
            <ModalHeader toggle={onClose}>
                Detalhes da postagem
            </ModalHeader>
            <ModalBody>
                <div className="d-flex flex-fill flex-wrap align-items-center justify-content-center">
                    <Carousel
                        activeIndex={state.activeIndex}
                        next={next}
                        previous={previous}>
                        <CarouselIndicators items={items} activeIndex={state.activeIndex} onClickHandler={goToIndex} />
                        {slides}
                    </Carousel>
                </div>
                <div>
                    <h3 className="mt-4">{post.title}</h3>
                    {
                        (post.tags && post.tags.length > 0) ? <div className="pb-2">
                            {post.tags.includes("PLACE") ? <Badge color="primary" className="me-2">Lugar</Badge> : <></>}
                            {post.tags.includes("TRIP") ? <Badge color="warning" className="me-2">Viagem</Badge> : <></>}
                            {post.tags.includes("RESTAURANT") ? <Badge color="success" className="me-2">Restaurante</Badge> : <></>}
                        </div> : <></>
                    }
                    <div style={{ 'white-space': 'pre-wrap'}}><p>{post.body}</p></div>
                </div>
                <LoadingState show={post === {}} className="mb-3"></LoadingState>
            </ModalBody>
            <ModalFooter>
                <Button className="text-button" color="secondary" onClick={onClose}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}