import { useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import api from "../../../../../../services/api";


export const CreateAgendaModal = props => {
    const [name, setName] = useState("");

    function create(){
        api.post('/v1/schedules',
        { name: name }).then(res => {
            props.setCreatedScheduleId(res.data.id)
            closeAndClean()
        }).catch(event => handleError(event))
    }

    function closeAndClean() {
        setName("")
        props.toggle()
    }

    function handleError(event) {
    }

    function isFormValid() {
        return name.length > 0
    }

    return (
        <>
        <Modal isOpen={props.isOpen} toggle={closeAndClean} size="lg">
            <ModalHeader toggle={closeAndClean}>
                Nova agenda
            </ModalHeader>
            <ModalBody>
                <p>Preencha o formulário abaixo para adicionar uma nova agenda</p>
                <Form>
                    <FormGroup>
                        <Label>Nome da agenda</Label>
                        <Input value={name} onChange={(event) => setName(event.target.value)}></Input>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button className="text-button" color="primary" onClick={create} disabled={!isFormValid()}>Criar</Button>
                <Button className="text-button ms-2" color="secondary" onClick={closeAndClean}>Cancelar</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}