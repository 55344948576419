import { useState } from "react"
import { useEffect } from "react"
import { Calendar, Minimize, MoreVertical, Target, Trash } from "react-feather"
import { useOutletContext } from "react-router-dom"
import { Button, Card, Col, Row, Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table, UncontrolledDropdown } from "reactstrap"
import Center from "../../../../../components/Center/Center"
import { State, StateMachine } from "../../../../../components/StateMachine"
import api from "../../../../../services/api"
import { CreateServiceModal } from "./modals/CreateServiceModal"
import { DeleteServiceModal } from "./modals/DeleteServiceModal"
import { DetailsServiceModal } from "./modals/DetailsServiceModal"
import { NewCalendarAddedModal } from "./modals/NewCalendarAddedModal"
import ImageView from "../../../../../components/ImageView/ImageView"

const Catalog = () => {

    const [version, setVersion] = useState(1)
    const [page, setPage] = useState(1)

    const [hasNextPage, setHasNextPage] = useState(false)
    const [services, setServices] = useState([])
    const [isCreateServiceOpen, setIsCreateServiceOpen] = useState(false)
    const [isDetailServiceOpen, setIsDetailServiceOpen] = useState(false)
    const [isNewCalendarAddedOpen, setIsNewCalendarAddedOpen] = useState(false)
    const [isDeleteServiceOpen, setIsDeleteServiceOpen] = useState(false)
    const [selectedService, setSelectedService] = useState({})
    const [isCalendarCreated, setIsCalendarCreated] = useState(false)
    const setCurrentMenu = useOutletContext()

    const [stateKey, setStateKey] = useState('loading')

    useEffect(_ => {
        setStateKey('loading')
        api.get(`/v1/services?page=${page}`)
            .then(res => {
                let data = res.data
                setStateKey(data.result.length === 0 ? 'empty' : 'data')
                setServices(data.result)
                setHasNextPage(data.has_next_page)
            })
            .catch(_ => handleError())
    }, [page, version])

    const toggleCreateServiceModalOpen = () => setIsCreateServiceOpen(open => !open)
    const detailServiceToggle = () => setIsDetailServiceOpen(!isDetailServiceOpen)
    const deleteServiceToggle = () => setIsDeleteServiceOpen(!isDeleteServiceOpen)
    const calendarAddedToggle = (redirect) => {
        if (redirect === true) {
            setCurrentMenu({ page: `agendas/${selectedService.schedule_id}`, name: "Gerenciar Disponibilidade" })
        }

        if (redirect === false) {
            reload()
        }

        setIsNewCalendarAddedOpen(!isNewCalendarAddedOpen)
    }

    function handleError() {
        setStateKey('error')
    }

    function onCloseCreateServiceModal() {
        if (isCalendarCreated) {
            calendarAddedToggle()
        } else {
            reload()
        }
    }

    function reload() {
        setVersion(version + 1)
    }

    function nextPage() {
        setPage(page + 1)
    }

    function previousPage() {
        setPage(page - 1)
    }

    function getPrice(service) {
        // var currency_code = service.price.currency.currency_code
        var price = service.price.number

        if (price === 0) {
            return "Gratuíto"
        }
        return `R$ ${price}`
    }

    function getType(service) {
        if (service.type === 'SCHEDULED') {
            return <Center horizontal={false}><Calendar size={18} /><span className="mx-2">Agendado</span></Center>
        }

        if (service.type === 'NORMAL') {
            return <Center horizontal={false}><Target size={18} /><span className="mx-2">Não agendado</span></Center>
        }
    }

    function showServicesDetail(service) {
        setSelectedService(service); 
        detailServiceToggle()
    }

    return (
        <div className="h-100">
            <Card className="h-100">

                <Container className="p-4">
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <Button className="text-button px-4" block color='primary' onClick={toggleCreateServiceModalOpen}>Novo serviço</Button>
                        </Col>
                    </Row>
                </Container>

                <StateMachine currentStateKey={stateKey}>
                    <State stateKey={'loading'}>
                        <div className="position-absolute top-50 w-100 center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </State>

                    <State stateKey={'empty'}>
                        <div className="h-100 center">
                            Nenhum serviço adicionado
                        </div>
                    </State>

                    <State stateKey={'data'}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th scope="col"><span className="ps-3">Serviço</span></th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Duração</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    services.map((value, index) =>
                                        <tr key={`cat-key-${index}`}>
                                            <td>
                                                <div className="px-3 cursor-pointer h-100" onClick={_ => showServicesDetail(value)}>
                                                    <ImageView src={value.image} className="rounded-circle border" width="40" height="40" />
                                                    <span className="text-subtitle2 px-3">{value.name}</span>
                                                </div>
                                            </td>
                                            <td>{getPrice(value)}</td>
                                            <td>{getType(value)}</td>
                                            <td>{value.duration_in_minutes} min</td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className='btn-icon caret-off' color='transparent' size='sm' caret>
                                                        <MoreVertical size={15} />
                                                    </DropdownToggle>
                                                    <DropdownMenu container='body' end>
                                                        <DropdownItem onClick={_ => showServicesDetail(value)}>
                                                            <Minimize className='mr-50' size={15} />
                                                            <span className='ms-2 align-middle'>Detalhes</span>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={_ => { setSelectedService(value); deleteServiceToggle() }}>
                                                            <Trash className='mr-50' size={15} />
                                                            <span className='ms-2 align-middle'>Excluir</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </State>


                    <State stateKey={'error'}>
                        <div className="h-100 center">
                            Desculpe, ocorreu um erro!
                        </div>
                    </State>
                </StateMachine>

                <Pagination className="mt-auto ms-auto p-3">
                    <PaginationItem disabled={page === 1}>
                        <PaginationLink previous onClick={e => { e.preventDefault(); previousPage() }} />
                    </PaginationItem>
                    <PaginationItem disabled={!hasNextPage}>
                        <PaginationLink next onClick={e => { e.preventDefault(); nextPage() }} />
                    </PaginationItem>
                </Pagination>
            </Card>

            <NewCalendarAddedModal isOpen={isNewCalendarAddedOpen} toggle={calendarAddedToggle} />

            <DetailsServiceModal isOpen={isDetailServiceOpen} toggle={detailServiceToggle} data={selectedService} />
            <DeleteServiceModal isOpen={isDeleteServiceOpen} toggle={deleteServiceToggle} serviceId={selectedService.id} reload={reload} />

            <CreateServiceModal isOpen={isCreateServiceOpen}
                toggle={toggleCreateServiceModalOpen}
                reload={onCloseCreateServiceModal}
                setSelectedService={setSelectedService}
                isCalendarCreated={setIsCalendarCreated} />
        </div>
    )
}

export default Catalog