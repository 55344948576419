import { useContext, useRef, useState } from "react"
import { Search, Settings } from "react-feather"
import { useNavigate } from "react-router-dom"
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap"
import { StoreContext } from "../../../../context/StoreContext"
import { mapPlaceSettings } from "../../../../services/aloha-settings"
import { PlaceRequestCard } from "./components/PlaceRequestCard"
import { PlaceChangeStateModal } from "./modals/PlaceChangeStateModal"
import OutlinedButton from "../../../../components/Button/OutlinedButton"
import ModalRequestSettings from "./modals/RequestSettingsModal"

const Requests = _ => {

    const navigate = useNavigate()

    const store = useContext(StoreContext)
    const [search, setSearch] = useState('')

    const [settingsModalOpen, setSettingsModalOpen] = useState(false)
    const toggleSettingsModal = () => setSettingsModalOpen(!settingsModalOpen)

    const placeChagenStateRef = useRef(null)
    const [placeChangeStateModalOpen, setPlaceChangeStateModalOpen] = useState(false)
    const togglePlaceChangeStateModalOpen = () => setPlaceChangeStateModalOpen(!placeChangeStateModalOpen)

    function onClickChangeState(place) {
        placeChagenStateRef.current = place
        setPlaceChangeStateModalOpen(true)
    }

    function goToKanban(placeId) {
        navigate(`/dashboard/requests/${placeId}`)
    }

    function filterSearch(place) {
        return (search ? place.name.includes(search) : true)
    }

    return (
        <div className="h-100">
            <div className="d-flex justify-content-end gap-3">
                <InputGroup style={{ maxWidth: 300 }}>
                    <Input id="inp-search" className="text-body2" value={search} type="text" placeholder="Digite para filtrar" onChange={(e) => { setSearch(e.target.value) }} />
                    <InputGroupText className="white-background">
                        <Search width={16}></Search>
                    </InputGroupText>
                </InputGroup>
                <OutlinedButton className="w-auto px-2" onClick={toggleSettingsModal}>
                    <div className="d-flex justify-content-center align-items-center gap-2 text-body2">
                        <span>Configurar</span>
                        <Settings size={16} />
                    </div>
                </OutlinedButton>
            </div>

            <Row className="mt-2">
                {
                    store.places.get().filter(filterSearch).map(mapPlaceSettings).map((place, index) => {
                        return (
                            <Col sm={12} lg={6} xl={4} className="pt-10px pb-10px" key={`place-request-${place.id}-${index}`}>
                                <div className="cursor-pointer" onClick={() => { goToKanban(place.id) }} >
                                    <PlaceRequestCard
                                        onClickChangeState={onClickChangeState}
                                        place={place} />
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

            <ModalRequestSettings
                isOpen={settingsModalOpen}
                toggle={toggleSettingsModal} />

            <PlaceChangeStateModal
                place={placeChagenStateRef.current}
                isOpen={placeChangeStateModalOpen}
                toggle={togglePlaceChangeStateModalOpen} />
        </div>
    )
}

export default Requests