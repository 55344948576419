import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ChangeCircle = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 96 960 960"
            fill={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}>
            <path d="M482.385 847.538 587.538 743 482.385 638.462 448.847 672l49.154 49.154q-37.231.616-68.539-9.961t-52.693-31.962q-21.154-21.154-32.039-48.308-10.884-27.154-10.884-54.308 0-17 4.115-34T349.692 510l-35.538-33.999Q299.846 499.078 293 524.417q-6.846 25.339-6.846 51.583 0 36.825 14.423 72.682 14.423 35.856 42.462 64.087 28.038 28.231 68.077 42.154 40.039 13.923 82.27 14.538L448.847 814l33.538 33.538Zm163.846-171.539q14.307-23.077 21.153-48.416 6.846-25.339 6.846-51.583 0-36.765-14.115-73.046-14.115-36.281-42.346-64.338-27.846-28.231-68.267-41.846-40.421-13.616-82.503-13.616L511.538 338 478 304.462 372.847 409 478 513.538 511.538 480l-49.77-49.77q36.616 0 68.847 10.885 32.231 10.885 53.114 31.797 20.883 20.913 31.846 48.1 10.964 27.186 10.964 54.373 0 17-4.115 34-4.116 17-11.731 32.615l35.538 33.999Zm-166.164 280q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
    );
});

ChangeCircle.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChangeCircle.displayName = 'ChangeCircle';

export default ChangeCircle;