import Resizer from "react-image-file-resizer";
import { Input } from "reactstrap";

const IMAGE_TYPE = "JPEG"
const MAX_WITH = 500
const MAX_HEIGHT = 500
const QUALITY_PERCENT = 100

/**
 * @param {onImage} -> function to receive image after resize finish
 * @param {maxWidth} -> Max width to resize image
 * @param {maxHeight} -> Max height to resize image
 * @param {invalid} -> Set invalid visualization, default false
 */
export const InputImage = (props) => {

    let maxWidth = props.maxWidth || MAX_WITH
    let maxHeight = props.maxHeight || MAX_HEIGHT
    let invalid = props.invalid || false

    const resizeImage = (image) => new Promise((resolve) => {
        Resizer.imageFileResizer(image, maxWidth, maxHeight, IMAGE_TYPE, QUALITY_PERCENT, 0, resolve, "file");
    });

    function onChangeImage(image) {
        resizeImage(image)
            .then(newImage => props.onImage(newImage))
            .catch(_ => props.onImage(image))
    }

    return <Input
        {...props}
        type="file"
        accept='image/*'
        invalid={invalid}
        onChange={e => onChangeImage(e.target.files[0])} />
}
