import { useRef } from "react"

const SERVICE_CREATED = 'SERVICE_REQUEST_CREATED'
const SERVICE_UPDATED = 'SERVICE_REQUEST_STATUS_CHANGED'

const SERVICE_PENDING = 'PENDING'
const SERVICE_ACCEPTED = 'ACCEPTED'
const SERVICE_SCHEDULED = 'SCHEDULED'
const SERVICE_CONCLUDED = 'FINISHED'
const SERVICE_REJECTED = 'REJECTED'

const useRequestsServicesHelper = () => {

    const services = useRef([])

    function handleMessageReceive(data) {

        switch (data.event_type) {
            case SERVICE_CREATED:
                handleServiceCreated(data)
                break
            case SERVICE_UPDATED:
                handleServiceUpdated(data)
                break
            default:
                break
        }
    }

    function handleServiceCreated(data) {
        let service = mapServiceCreated(data)
        let newList = services.current.filter(ser => ser.id !== service.id)
        newList.push(service)
        services.current = newList
    }

    function handleServiceUpdated(data) {
        let service = mapServiceStatusChanged(data)
        let oldService = services.current.find(el => el.id === service.id)

        if (oldService) {
            let serviceIndex = services.current.indexOf(oldService)
            services.current[serviceIndex] = { ...oldService, ...service }
        } else {
            throw Error("Servide needs to be created before updated")
        }
    }

    return { services, handleMessageReceive }
}

const mapServiceCreated = (sseEvent) => {

    let scheduleInfo = {
        scheduleId: sseEvent.metadata.service_data.scheduled_service_data ? sseEvent.metadata.service_data.scheduled_service_data.schedule_id : undefined,
        startDateTime: sseEvent.metadata.service_data.scheduled_service_data ? sseEvent.metadata.service_data.scheduled_service_data.start_date_time : undefined,
        endDateTime: sseEvent.metadata.service_data.scheduled_service_data ? sseEvent.metadata.service_data.scheduled_service_data.end_date_time : undefined,
    }

    return {
        id: sseEvent.metadata.service_request_id,
        status: sseEvent.metadata.status,
        title: sseEvent.metadata.service_data.service_name,
        serviceId: sseEvent.metadata.service_data.service_id,
        serviceType: sseEvent.metadata.service_data.service_type,
        detail: sseEvent.metadata.detail,
        scheduleInfo: scheduleInfo,
        guest: {
            email: sseEvent.metadata.requester_data.guest_email,
            name: sseEvent.metadata.requester_data.user_name
        },
        createdAt: sseEvent.creation_at,
        placeId: sseEvent.metadata.place_id,
        ...mapStatus(sseEvent.metadata.status)
    }
}

const mapServiceStatusChanged = (sseEvent) => {
    return {
        id: sseEvent.metadata.service_request_id,
        status: sseEvent.metadata.new_status,
        hotelId: sseEvent.metadata.hotel_id,
        createdAt: sseEvent.creation_at,
        ...mapStatus(sseEvent.metadata.new_status)
    }
}

function mapStatus(status) {
    return {
        isAccepted: status === SERVICE_ACCEPTED || status === SERVICE_SCHEDULED,
        isPending: status === SERVICE_PENDING,
        isConcluded: status === SERVICE_CONCLUDED || status === SERVICE_REJECTED
    }
}

export default useRequestsServicesHelper