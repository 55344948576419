import { Container } from "reactstrap"
import { TERMS_URL } from "../../../strings"

const DeleteAccount = () => {
    return (
        <Container className="py-4">
            <h1>Como excluir minha conta no Aloha?</h1>
            <p>Se você deseja excluir sua conta permanentemente no Aloha, basta seguir os passos abaixo</p>
            <ol>
                <li>Abra o app Aloha conectado a sua conta</li>
                <li>Toque na aba <em>Perfil</em></li>
                <li>Toque em <em>Excluir conta</em></li>
                <li>Toque em <em>Confirmar</em></li>
            </ol>
            <small>Alguns dados podem ser mantidos de acordo com nossa <a href={TERMS_URL}>Política de Privacidade</a></small>
        </Container>
    )
}

export default DeleteAccount