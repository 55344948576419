import PropTypes from 'prop-types';

const StateView = (props) => {
    if (!props.show) {
        return <></>
    } else {
        return props.children
    }
}

StateView.propTypes = {
    show: PropTypes.bool
}

export default StateView