import { useContext, useState } from "react"
import { RequestsContext } from "../../../../../context/requests/RequestsContext"
import parse from 'html-react-parser'
import ApproveGuestRequestModal from "../modals/ApproveGuestRequestModal"
import UrgentActionCard from "../../../../../components/Button/UrgentActionCard"

export const PendingApprovalGuests = props => {

    const [isAcceptFormOpen, setAcceptFormOpen] = useState(false)
    const requests = useContext(RequestsContext)

    const toggleAcceptForm = () => {
        setAcceptFormOpen(!isAcceptFormOpen)
    }

    function buildMessage() {
        if (requests.checkIns.pending.length < 1) {
            return 'Nenhuma solicitação pendente'
        } else if (requests.checkIns.pending.length === 1) {
            return parse('<b>1</b> solicitação de estadia aguardando aprovação')
        } else {
            return parse(`<b>${requests.checkIns.pending.length}</b> solicitações de estadia aguardando aprovação`)
        }
    }

    return requests.checkIns.pending.length > 0 && (
        <>
            <UrgentActionCard
                visible={requests.checkIns.pending.length > 0}
                title='Hóspedes pendentes'
                message={buildMessage()}
                actionLabel='Ver solicitações'
                actionClick={toggleAcceptForm} />

            <ApproveGuestRequestModal
                isOpen={isAcceptFormOpen}
                toggle={toggleAcceptForm} />
        </>
    )
}