import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ConnectionError = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 -960 960 960">
            <path d="M193.869-142.348q-58.913-56.434-93.13-132.941-34.218-76.507-34.218-164.824 0-85.039 32.294-160.064 32.294-75.025 87.842-130.609 55.549-55.585 130.562-87.9Q392.232-851 477.522-851q120.188 0 217.453 61.609Q792.239-727.783 843.718-627h-75.5q-46.479-71.63-122.392-115.065T477.522-785.5q-144.767 0-245.134 100.512-100.366 100.513-100.366 244.906 0 75.039 28.955 139.08 28.956 64.041 79.479 111.828l-46.587 46.826Zm114.913-114.913q-36.347-34.116-57.989-81.142-21.641-47.026-21.641-101.709 0-103.441 72.412-175.849 72.413-72.409 175.859-72.409 103.447 0 175.838 72.4 72.391 72.399 72.391 175.827 0 54.817-21.597 101.752-21.598 46.935-57.794 81.13l-46.826-46.826q28.256-25.404 44.487-59.82 16.23-34.416 16.23-75.979 0-76.31-53.185-129.527-53.186-53.217-129.414-53.217-76.227 0-129.444 53.173-53.218 53.173-53.218 129.372 0 41.563 16.12 76.161 16.119 34.598 44.598 59.837l-46.827 46.826ZM477.435-354.5q-35.305 0-60.359-25.141-25.054-25.141-25.054-60.446 0-35.304 25.141-60.359 25.141-25.054 60.445-25.054 35.305 0 60.359 25.141 25.055 25.141 25.055 60.446 0 35.304-25.141 60.359Q512.74-354.5 477.435-354.5Zm378.184 214.522q-15.641 0-27.217-11.518-11.576-11.517-11.576-27.369t11.518-27.309q11.517-11.456 27.229-11.456 15.711 0 27.308 11.469 11.598 11.469 11.598 27.25t-11.61 27.357q-11.61 11.576-27.25 11.576Zm-32.597-146.065V-529.63h65.5v243.587h-65.5Z" />
        </svg>
    )
});

ConnectionError.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConnectionError.displayName = 'ConnectionError';

export default ConnectionError;