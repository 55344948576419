import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { ToastContext } from "../../../../../context/ToastContext"
import Center from "../../../../../components/Center/Center"
import api from "../../../../../services/api"
import moment from "moment/moment"

const OrderDetailModal = props => {

    const toast = useContext(ToastContext)

    const [detail, setDetail] = useState({
        name: '',
        date: ''
    })

    const [isLoading, setLoading] = useState(false)

    if (!props.order) {
        return <></>
    }

    function setupState(response) {
        setDetail({
            name: response.customer.name,
            date: moment(response.order.created_at).subtract(3, 'hours').fromNow()
        })
    }

    function fetchOrderDetail() {
        setLoading(true)
        api.get(`/v1/orders/${props.order.id}`)
            .then(res => setupState(res.data))
            .catch(_ => onError())
            .finally(_ => setLoading(false))
    }

    function onError() {
        props.toggle()
        toast.error('Desculpe, ocorreu um erro!')
    }

    function onCloseModal() {
        setDetail({
            name: '',
            date: ''
        })
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onOpened={fetchOrderDetail} onClosed={onCloseModal}>
            <ModalHeader toggle={props.toggle}>
                <h6 className="text-h6">Detalhes do pedido</h6>
                <span className="text-body2 text-muted">Pedido: #{props.order.id}</span>
            </ModalHeader>
            <ModalBody>
                <div hidden={isLoading}>

                    {
                        props.order.items.map((item, index) => <OrderItem key={`sub-order-item-${props.order.id}-${item.id}-${index}`} item={item} />)
                    }

                    <div>
                        <span className="text-subtitle2">Hóspede:</span>
                        <span className="text-body2 px-2">{detail.name}</span>
                    </div>
                    <div>
                        <span className="text-subtitle2">Data:</span>
                        <span className="text-body2 px-2">{detail.date}</span>
                    </div>
                </div>
                <div className="p-3" hidden={!isLoading}>
                    <Center>
                        <Spinner size="sm" >Loading...</Spinner>
                    </Center>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle} className="text-button">Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const OrderItem = ({ item }) => {
    return (
        <div className="background1 px-3 py-2 mb-2">
            <div className="text-body1 text-gray1">{item.quantity}x {item.name}</div>
            {item.details && <div className="text-body2 text-gray2" style={{ marginTop: 12 }}><i>"{item.details}"</i></div>}
        </div>
    )
}

export default OrderDetailModal