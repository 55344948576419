import { useRef } from "react"

const EventType = {
    CHECK_IN_REQUESTED: 'CHECK_IN_REQUESTED',
    APPROVED_CHECK_IN: 'APPROVED_CHECK_IN',
    REMOVED_CHECK_IN: 'REMOVED_CHECK_IN'
}

/**
 * Handle the checkins received from server
 * 
 * Store all checkins requests
 */
const useRequestsCheckInsHelper = () => {

    /**
     * the list of checkins
     */
    const checkInList = useRef([])

    /**
     * Handle the message by event type 
     */
    function handleMessageReceive(data) {
        switch (data.event_type) {
            case EventType.CHECK_IN_REQUESTED:
                handleCheckInRequested(data)
                break
            case EventType.APPROVED_CHECK_IN:
                handleCheckInApproved(data)
                break
            case EventType.REMOVED_CHECK_IN:
                handleCheckInApproved(data)
                break
            default:
                break
        }
    }

    function handleCheckInRequested(data) {
        var checkIn = mapCheckIn(data)
        updateCheckInList(checkIn)
    }

    function handleCheckInApproved(data) {
        let newList = checkInList.current.filter(i => data.metadata.guest_id !== i.guest.id)
        checkInList.current = newList
    }

    function updateCheckInList(checkIn) {
        let oldCheckIn = checkInList.current.find(i => i.guest.id === checkIn.guest.id)
        let newCheckInList = [...checkInList.current.filter(el => el.guest.id !== checkIn.guest.id)]

        if (oldCheckIn) {
            // is an update
            newCheckInList.push({ ...oldCheckIn, ...checkIn })
        } else {
            // is an creation
            newCheckInList.push(checkIn)
        }

        checkInList.current = newCheckInList
    }


    return { handleMessageReceive, checkIns: checkInList }
}

const mapCheckIn = (data) => {
    return {
        name: data.metadata.name,
        guest: data.metadata.guest,
        customFields: data.metadata.custom_fields
    }
}

export default useRequestsCheckInsHelper