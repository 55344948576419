import { Card, CardBody, Table } from "reactstrap"
import { useEffect, useState } from "react"
import StateView from "../../../../components/StateView"
import PropTypes from 'prop-types'
import AlohaApi from "../../../../services/aloha-api"

export const OperatorStatistics = (props) => {

    const [operators, setOperators] = useState([])
    const [conversions, setConversion] = useState([])
    const [result, setResult] = useState([])

    useEffect(_ => {
        fetchOperators()
        fetchConversions()
    }, [props.days])

    useEffect(_ => {
        let array = []
        
        operators.forEach(operator => {
            let result = conversions.find(e => e.operator_id === operator.id)
            if (result) {
                array.push({ operator: operator.name, conversion: result.counter })
            }
        })

        array.sort((a, b) => b.conversion - a.conversion)
        setResult(array)

    }, [operators, conversions])

    function fetchConversions() {
        AlohaApi.fetchConversions({ days: props.days })
            .then(res => setConversion(res.data.customer_conversions))
    }

    function fetchOperators() {
        AlohaApi.fetchOperators()
            .then(operators => setOperators(operators))
    }

    return (
        <StateView show={result.length !== 0}>
            <Card {...props}>
                <CardBody>
                    <h5 className="p-2 mb-3">Conversão de hóspedes</h5>
                    <Table>
                        <thead>
                            <tr>
                                <td>Colaborador</td>
                                <td>Conversão</td>
                            </tr>
                        </thead>
                        <tbody>
                            {result.map((result, index) => {
                                return (
                                    <tr key={`operator-${index}`}>
                                        <td>{result.operator}</td>
                                        <td>{result.conversion}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </StateView>
    )
}

OperatorStatistics.propTypes = {
    days: PropTypes.number
}