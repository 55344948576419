import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert, Button, FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap"
import { login } from './../../../services/auth'
import { Stepper, Steppers } from "./../../../components/Steppers"
import { capitalize, validateEmail } from "./../../../utils/strings"
import { TERMS_URL } from "../../../strings"
import api from './../../../services/api'
import Logo from './../../../components/Logo'

const VALUE_PER_ROOM_FACTOR = 2.99

const SignUp = () => {

    const navigate = useNavigate()

    const [tabActive, setTabActive] = useState(0)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [rooms, setRooms] = useState(0)
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [checkTerms, setCheckTerms] = useState(false)

    const [request, setRequest] = useState({ loading: false, error: false })


    /**
     * Request to register 
     */
    function register() {
        if (!isValidInfoStep() || !isValidLocationStep() || !isValidStepConfirmation()) return

        setRequest({ loading: true, error: false }) // show loading

        const onSuccess = (res) => {
            login(res.data.token)
            navigate('/dashboard/home')
        }

        const onError = () => {
            // hide loading and show error
            setRequest({ loading: false, error: true })
        }

        const body = {
            email,
            number,
            city,
            password,
            hotel_name: name,
            street: address,
            room_quantity: rooms
        }

        api.post('/v1/onboarding/simple', body)
            .then(res => onSuccess(res))
            .catch(_ => onError())
    }

    /**
     *  Called when user changes rooms input
     */
    function onChangeRoomQuantity(event) {
        let quantity = Number(event.target.value)

        // max 999
        if (quantity > 99999) { quantity = 99999 }

        // min 0
        if (quantity <= 0) { quantity = 0 }

        setRooms(quantity)
    }

    /**
     * Calculates the monthly payment amount according to the number of rooms
     * 
     * @returns monthly payment value formatted BRL
     */
    function getMonthlyPaymentByRooms() {
        const MIN_ROOMS = 20 // Limit the minimum value

        let roomsToCalc = rooms

        // check limit min rooms
        if (roomsToCalc > 0 && roomsToCalc < MIN_ROOMS) { roomsToCalc = MIN_ROOMS }

        return (roomsToCalc * VALUE_PER_ROOM_FACTOR).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    function isValidInfoStep() {
        return name.length > 3 && validateEmail(email) && rooms > 0
    }

    function isValidLocationStep() {
        return city.length > 3 && address.length > 3 && number > 0
    }

    function isValidStepConfirmation() {
        return password.length > 3 && passwordAgain === password && checkTerms
    }

    function clearRequestError() {
        setRequest(e => ({ ...e, error: null }))
    }

    return (
        <div className="full center">
            <div className='d-flex flex-fill flex-wrap align-items-center justify-content-center'>
                <div className='p-3 mx-auto mx-md-5'>
                    <Logo onClick={_ => navigate('/')} />
                </div>
                <div className='p-3 mx-auto mx-md-5 w-100' style={{ maxWidth: '520px' }}>
                    <div className='text-center'>
                        <h1>Cadastro</h1>
                        <p className='body2'>Crie sua conta no Aloha</p>
                    </div>
                    <Alert color="danger" isOpen={request.error} toggle={clearRequestError}>Ocorreu um erro, tente novamente!</Alert>
                    <Steppers activeTab={tabActive}>
                        <Stepper title={"Informações básicas"}>
                            <FormGroup>
                                <Label for="inp-name">Nome</Label>
                                <Input id="inp-name" type="text" placeholder="Nome do estabelecimento"
                                    value={name}
                                    onChange={e => setName(capitalize(e.target.value))} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="inp-email">Email</Label>
                                <Input id="inp-email" type="email" placeholder="hotel@email.com"
                                    value={email}
                                    onChange={e => setEmail(e.target.value.toLowerCase())} />
                            </FormGroup>
                            <div className="d-flex">
                                <FormGroup>
                                    <Label for="inp-room">Número de quartos</Label>
                                    <Input id="inp-room" type="tel" placeholder="10" value={rooms} onChange={onChangeRoomQuantity} />
                                </FormGroup>
                                <div className="flex-fill d-flex justify-content-end align-items-end pb-3">
                                    <span className="fs-5 fw-bold">{getMonthlyPaymentByRooms()}</span><span className="body1 text-secondary fw-light">/mês</span>
                                </div>
                            </div>
                            <div className="mt-4 text-end">
                                <Button disabled={!isValidInfoStep()} onClick={_ => setTabActive(1)} color="primary" className="px-5 m-auto">Continuar</Button>
                            </div>
                        </Stepper>
                        <Stepper title={"Localização"}>
                            <FormGroup>
                                <Label for="inp-city">Cidade</Label>
                                <Input id="inp-city" type="text" placeholder="Digite aqui"
                                    value={city}
                                    onChange={e => setCity(capitalize(e.target.value))} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="inp-address">Logradouro</Label>
                                <Input id="inp-address" type="text" placeholder="Digite aqui"
                                    value={address}
                                    onChange={e => setAddress(capitalize(e.target.value))} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="inp-number">Número</Label>
                                <Input id="inp-number" type="number" placeholder="Digite aqui"
                                    value={number}
                                    onChange={e => setNumber(e.target.value)} />
                            </FormGroup>
                            <div className="mt-4 d-flex justify-content-between">
                                <Button onClick={_ => setTabActive(0)} color="light">Voltar</Button>
                                <Button color="primary"
                                    disabled={!isValidLocationStep()}
                                    onClick={_ => setTabActive(2)}>Continuar</Button>
                            </div>
                        </Stepper>
                        <Stepper title={"Confirmação"}>
                            <FormGroup>
                                <Label for="inp-pass">Senha</Label>
                                <Input id="inp-pass" type="password" placeholder="********"
                                    disabled={request.loading}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="inp-pass-again">Senha novamente</Label>
                                <Input id="inp-pass-again" type="password" placeholder="********"
                                    disabled={request.loading}
                                    invalid={(passwordAgain.length >= password.length && password !== passwordAgain)}
                                    value={passwordAgain}
                                    onChange={e => setPasswordAgain(e.target.value)} />
                                <FormFeedback>As senhas não coincidem</FormFeedback>
                            </FormGroup>
                            <FormGroup switch>
                                <Input disabled={request.loading} type="switch" checked={checkTerms} onChange={e => setCheckTerms(e.target.checked)} />
                                <Label check>Ao criar uma conta você concorda com nosso <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">termos de uso e política de privacidade</a>.</Label>
                            </FormGroup>
                            <div className="mt-4 d-flex justify-content-between">
                                <Button disabled={request.loading} onClick={_ => setTabActive(1)} color="light">Voltar</Button>
                                <Button onClick={_ => register()} color="primary" style={{ minWidth: 100 }}
                                    disabled={request.loading || !isValidStepConfirmation()}>
                                    {request.loading ? <Spinner color="light" size="sm"> Loading...</Spinner> : 'Continuar'}
                                </Button>
                            </div>
                        </Stepper>
                    </Steppers>
                    <div className="py-3 text-end">
                        <p>Já tem conta? <a href="/login">Faça login</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp