import moment from "moment"
import { useEffect, useState } from "react"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { CustomCheckbox } from "../../../../components/CustomCheckbox"
import { InputImage } from "../../../../components/Input/Input"
import ImageView from "../../../../components/ImageView/ImageView"
import api from "../../../../services/api"
import StyledTimePicker from "../services/agenda/modals/AlohaTimePicker/StyledTimePicker.styled"
import AlohaApi from "../../../../services/aloha-api"

export function useEvents() {

    const defaultEvent = () => {
        return {
            image: null,
            title: '',
            time: moment({ h: 8 }),
            minutes: 0,
            days_of_week: []
        }
    }

    const initialState = {
        events: [],
        data: defaultEvent()
    }

    const [version, setVersion] = useState(1)
    const [machineState, setMachineState] = useState('loading')
    const [state, setState] = useState(initialState)

    const setData = (item) => setState({ ...state, data: item })
    const setImage = (image) => setState({ ...state, data: { ...state.data, image } })
    const setTitle = (title) => setState({ ...state, data: { ...state.data, title } })
    const setStartTime = (startTime) => setState({ ...state, data: { ...state.data, time: startTime } })
    const setDuration = (duration) => setState({ ...state, data: { ...state.data, minutes: duration } })
    const setWeekDays = (weekDays) => setState({ ...state, data: { ...state.data, days_of_week: weekDays } })

    const form = (
        <Form>
            <ImageView src={state.data.image} alt="Evento" width={300} className="p-2 d-block m-auto" />

            <FormGroup>
                <Label for='inp-image'>Imagem</Label>
                <InputImage id="inp-image" name="image" onImage={image => setImage(image)} maxWidth={720} maxHeight={720} />
            </FormGroup>

            <FormGroup>
                <Label for='inp-title'>Título</Label>
                <Input value={state.data.title} id='inp-title' name='title' type='text' placeholder='Digite aqui' onChange={e => setTitle(e.target.value)} />
            </FormGroup>

            <div className="d-flex gap-2">
                <FormGroup className="flex-fill">
                    <Label>Início</Label> <br />
                    <StyledTimePicker value={state.data.time} onChange={setStartTime} className="w-100"></StyledTimePicker>
                </FormGroup>

                <FormGroup className="flex-fill">
                    <Label for="inp-duration">Duração (min)</Label>
                    <Input value={state.data.minutes} id="inp-duration" name="duration" type="number" onChange={e => setDuration(e.target.value)} />
                </FormGroup>
            </div>

            <FormGroup>
                <Label>Recorrência</Label>
                <div className="d-flex">
                    <CustomCheckbox value="SUNDAY" name="DOM" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="MONDAY" name="SEG" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="TUESDAY" name="TER" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="WEDNESDAY" name="QUA" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="THURSDAY" name="QUI" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="FRIDAY" name="SEX" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                    <CustomCheckbox value="SATURDAY" name="SAB" setState={setWeekDays} state={state.data.days_of_week}></CustomCheckbox>
                </div>
            </FormGroup>
        </Form>
    )

    const update = (callback, loadingCallback) => {
        var formData = new FormData()
        var isImageStr = typeof state.data.image === 'string' || state.data.image instanceof String
        var isImageNull = state.data.image === null
        if (!isImageStr && !isImageNull) {
            formData.append('image', state.data.image)
        }
        formData.append('title', state.data.title)
        formData.append('start_time', state.data.time.format('HH:mm:ss-03:00'))
        formData.append('duration', state.data.minutes)
        state.data.days_of_week.forEach((item) => formData.append('week_day', item))

        const config = { headers: { "Content-Type": "multipart/form-data" } }

        loadingCallback(true)
        api.put('v1/calendars/rules/' + state.data.id, formData, config)
            .then(_ => { callback(); reload() })
            .catch(_ => setMachineState('error'))
            .finally(_ => loadingCallback(false))
    }

    const deleteEvent = (callback, loadingCallback) => {
        loadingCallback(true)
        api.delete('v1/calendars/rules/' + state.data.id)
            .then(_ => { callback(); reload() })
            .catch(_ => setMachineState('error'))
            .finally(_ => loadingCallback(false))
    }

    const create = (callback, loadingCallback) => {
        var formData = new FormData()

        formData.append('image', state.data.image)
        formData.append('title', state.data.title)
        formData.append('start_time', state.data.time.format('HH:mm:ss-03:00'))
        formData.append('duration', state.data.minutes)
        state.data.days_of_week.forEach((item) => formData.append('week_day', item))

        const config = { headers: { "Content-Type": "multipart/form-data" } }

        loadingCallback(true)

        api.post('v1/calendars/rules', formData, config)
            .then(_ => { callback(); reload() })
            .catch(_ => setMachineState('error'))
            .finally(_ => loadingCallback(false))
    }

    function reload() {
        setVersion(v => v + 1)
    }

    function isValidEvent() {
        return state.data.image !== null &&
            state.data.title !== "" &&
            state.data.minutes > 0 &&
            state.data.days_of_week.length > 0
    }

    useEffect(_ => {
        // fetch events
        setMachineState('loading')
        AlohaApi.fetchEvents().then(res => {
            let allRules = res.data
            setState(state => ({ ...state, events: allRules, data: defaultEvent() }))

            if (allRules.length === 0) {
                setMachineState('empty')
            } else {
                setMachineState('data')
            }

        }).catch(_ => setMachineState('error'))
    }, [version])

    return [state, machineState, form, setData, create, update, reload, deleteEvent, defaultEvent, isValidEvent]
}