import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Printable } from "../../../../../components/Printable"
import { LoadingState } from "../States"
import { GuestCheckoutTable } from "./GuestCheckoutTable"
import { ToastContext } from "../../../../../context/ToastContext"
import api from "../../../../../services/api"
import LoaderButton from "../../../../../components/Button/LoaderButton"


export const GuestCheckoutModal = props => {

    const id = (props.guest && props.guest.id) || -1

    const toast = useContext(ToastContext)

    const [loading, setLoading] = useState(true)
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    
    const [guestsReviews, setGuestsReviews] = useState([])
    const [relatedGuests, setRelatedGuests] = useState([])

    function loadStayGuests() {

        let onSuccesss = (guests) => {
            setRelatedGuests(guests)
            loadCheckoutInformations(guests)
        }

        setLoading(true)

        api.post('/v2/guests/stays', { guest_id: id })
            .then(res => onSuccesss(res.data))
            .catch(_ => handleError())
    }

    function loadCheckoutInformations(guests) {
        var ids = guests.map(guest => guest.id)

        let onSuccesss = (data) => {
            setGuestsReviews(data)
            setLoading(false)
        }

        api.post('/v2/guests/review', { guest_ids: ids })
            .then(res => onSuccesss(res.data))
            .catch(_ => handleError())
    }

    function checkout() {

        let onSuccesss = () => {
            toast.success('Check-out realizado!')
            setGuestsReviews([])
            props.toggle()
        }

        setCheckoutLoading(true)

        var ids = relatedGuests.map(guest => guest.id)

        api.post('/v1/guests/checkout', { ids: ids })
            .then(_ => onSuccesss())
            .catch(_ => handleError())
            .finally(_ => setCheckoutLoading(false))
    }

    function handleError() {
        toast.error('Desculpe, ocorreu um erro!')
    }

    function resetState() {
        setGuestsReviews([])
        setRelatedGuests([])
    }

    function onModalOpen() {
        loadStayGuests()
    }

    function onModalClose() {
        resetState()
        props.reload()
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size='xl' onOpened={onModalOpen} onClosed={onModalClose}>
            <ModalHeader toggle={props.toggle}>  Checkout de hóspede</ModalHeader>
            <ModalBody className="p-0">
                <LoadingState show={loading} />

                <Printable
                    className="px-4"
                    fileName='checkout.pdf'
                    allowDownload={guestsReviews.length > 0}
                    allowPrint={guestsReviews.length > 0}>

                    {guestsReviews.map((guest, index) => <GuestCheckoutTable key={index} guest={guest} />)}
                    <div className="mb-4">
                        <h5 className="h1 text-dark">
                            Subtotal: {guestsReviews.reduce((p, c) => p + c.total.number, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </h5>
                    </div>
                </Printable>

            </ModalBody>
            <ModalFooter>
                <LoaderButton color="primary" onClick={checkout} loading={checkoutLoading} disabled={guestsReviews.length === 0}>Checkout</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={props.toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}