import { AlertCircle } from "react-feather"
import { Button, Card, CardBody } from "reactstrap"

const UrgentActionCard = ({ title, message, actionLabel, actionClick }) => {

    return (
        <Card className="warning-card mb-2">
            <CardBody>
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                    <div className="d-flex align-items-center">
                        <AlertCircle className="warning-text" size={24}></AlertCircle>
                        <div className="px-3">
                            <h6 className="text-body1 mb-2">{title}</h6>
                            <p className="text-body2">{message}</p>
                        </div>
                    </div>
                    <Button className="text-button" color="warning" onClick={actionClick}>{actionLabel}</Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default UrgentActionCard