import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { ProductForm } from "./ProductForm"
import { ToastContext } from "../../../../../context/ToastContext"
import LoaderButton from "../../../../../components/Button/LoaderButton"
import api from "../../../../../services/api"


const UpdateProductModal = (props) => {

    const toast = useContext(ToastContext)

    const [data, setData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(false)

    function save() {
        setLoading(true)

        var formData = new FormData()
        formData.append('id', data.id)
        formData.append('image', data.image)
        formData.append('name', data.name)
        formData.append('price_number', data.price)
        formData.append('price_currency', 'BRL')
        formData.append('description', data.description)
        formData.append('product_type', data.type)
        formData.append('place_id', data.place)
        data.delivery.forEach(i => formData.append('delivery_modes', i))

        const onSuccess = () => {
            toast.success('Produto salvo com sucesso')
            props.reload()
            props.toggle()
        }

        const onError = () => {
            toast.error('Desculpe, ocorreu um erro ao salvar o produto, tente novamente')
            props.toggle()
        }

        api.put(`/v1/products/${data.id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(_ => onSuccess())
            .catch(_ => onError())
            .finally(_ => setLoading(false))
    }

    if (!props.product) {
        return <></>
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} scrollable={true} size='lg'>
            <ModalHeader toggle={props.toggle}>Editar produto</ModalHeader>
            <ModalBody>
                <ProductForm
                    product={props.product}
                    typeSuggestionList={props.typeSuggestion}
                    onFormValid={data => { setData(data); setIsValid(true) }}
                    onFormInvalid={data => { setData(data); setIsValid(false) }} />
            </ModalBody>
            <ModalFooter>
                <LoaderButton loading={isLoading} disabled={!isValid} onClick={save}>Salvar</LoaderButton>
                <Button className="text-button ms-2" disabled={isLoading} color="secondary" onClick={props.toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default UpdateProductModal