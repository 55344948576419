import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { RequestsContext } from "../../../../context/requests/RequestsContext"
import parse from 'html-react-parser'
import UrgentActionCard from "../../../../components/Button/UrgentActionCard"

export const OrderStatistics = props => {

    const requests = useContext(RequestsContext)
    const navigate = useNavigate()

    function redirectToRequestPage() {
        navigate('/dashboard/requests')
    }

    function buildMessage() {
        let pendingCount = requests.notifications.length
        if (pendingCount < 1) {
            return 'Nenhuma solicitação pendente'
        } else if (pendingCount === 1) {
            return parse('<b>1</b> solicitação pendente de aprovação')
        } else {
            return parse(`<b>${pendingCount}</b> solicitações de produtos pendentes de aprovação`)
        }
    }

    return (
        requests.notifications.length > 0 && (
            <UrgentActionCard
                title='Importante'
                message={buildMessage()}
                actionLabel='Ver solicitações'
                actionClick={redirectToRequestPage}
            />
        )
    )
}