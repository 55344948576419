import { useContext, useRef, useState } from "react"
import { Card, Button, Label, Input, Container, Col, Row, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { DeleteModal, DetailModal } from "./States"
import { State, StateMachine } from "../../../../components/StateMachine"
import { ToastContext } from "../../../../context/ToastContext"
import { useEffect } from "react"
import { StoreContext } from "../../../../context/StoreContext"
import CreateProductModal from "./modals/CreateProductModal"
import UpdateProductModal from "./modals/UpdateProductModal"
import AlohaApi from "../../../../services/aloha-api"
import ProductsTable from "./components/ProductsTable"
import LazyInputSearch from "./components/LazyInputSearch"

const INITIAL_PAGE = 1

const initialState = {
    products: [],
    categories: [],
    filterName: null,
    filterType: null,
    page: INITIAL_PAGE,
    pages: [INITIAL_PAGE],
    pagination: {
        hasPrevious: false,
        hasNext: false
    }
}

const Products = () => {

    const store = useContext(StoreContext)
    const toast = useContext(ToastContext)

    const categories = useRef([])

    const [state, setState] = useState(initialState)
    const [machineState, setMachineState] = useState('loading')

    const [refreshKey, setRefreshKey] = useState(0)
    const updatePage = () => setRefreshKey(key => key += 1)

    const setFilterPage = (page) => {
        setState(state => ({ ...state, page }))
    }

    const setFilterType = (type) => {
        setState(state => ({ ...state, pages: [INITIAL_PAGE], page: INITIAL_PAGE, filterType: type }))
    }

    function search(text) {
        setState(state => ({ ...state, pages: [INITIAL_PAGE], page: INITIAL_PAGE, filterName: text }))
    }

    const [detailProduct, setDetailProduct] = useState(null)
    const [detailModal, setDetailModal] = useState(false)
    const toogleDetail = () => setDetailModal(!detailModal)

    const [isFormOpen, setFormOpen] = useState(false)
    const toggleForm = () => setFormOpen(!isFormOpen)

    const [productToUpdate, setUpdateProduct] = useState(null)
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
    const toggleUpdateModal = () => setUpdateModalOpen(!isUpdateModalOpen)

    const [productToDelete, setDeleteProduct] = useState(null)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen)

    useEffect(_ => {

        async function fetchAllProducts() {
            setMachineState('loading')

            const page = state.page
            const name = state.filterName
            const productType = state.filterType

            /**
             * needed to fetch product types only one time
             */
            if (categories.current.length === 0) {
                const res = await AlohaApi.fetchProductTypes()
                const mCategories = res.data.product_types
                mCategories.sort((a, b) => a.localeCompare(b)) 
                categories.current = mCategories
            }

            const res = await AlohaApi.fetchProducts({ page, name, productType })
            let allProducts = res.data.products
            let nextPage = res.data.next_page

            setState(state => {
                let pages = [...state.pages]
                let pagination = state.pagination
                pagination.hasNext = nextPage !== null
                pagination.hasPrevious = state.page > INITIAL_PAGE
                pagination.current = state.page

                if (nextPage !== null && !pages.includes(nextPage)) {
                    pages.push(nextPage)
                }

                let isEmpty = state.page === 1 && allProducts.length === 0
                setMachineState(isEmpty ? 'empty' : 'data')

                return {
                    ...state,
                    categories: categories.current,
                    pages,
                    products: allProducts
                }
            })
        }

        fetchAllProducts()

    }, [refreshKey, state.page, state.filterName, state.filterType])

    function nextPage() {
        setFilterPage(state.page += 1)
    }

    function previousPage() {
        setFilterPage(state.page -= 1)
    }

    function goToPage(page) {
        setFilterPage(page)
    }

    function refresh(backToFirstPage) {
        if (backToFirstPage && state.page !== INITIAL_PAGE) {
            setFilterPage(INITIAL_PAGE)
        } else {
            updatePage()
        }
    }

    function showProductDetailed(product) {
        setDetailProduct(product)
        toogleDetail()
    }

    function showUpdateProductModal(product) {
        setUpdateProduct(product)
        setUpdateModalOpen(true)
    }

    function showDeleteProductModal(product) {
        setDeleteProduct(product)
        setDeleteModalOpen(true)
    }

    function postProductEnabled(product) {
        AlohaApi.enableProduct(product.id)
            .then(_ => refresh(false))
            .catch(_ => toast.error())
    }

    function postProductDisabled(product) {
        AlohaApi.disableProduct(product.id)
            .then(_ => refresh(false))
            .catch(_ => toast.error())
    }

    function deleteProduct() {
        setDeleteModalOpen(false)

        const showSuccessDeleteToast = () => {

            /**
             * important, update store after delete
             */
            store.places.refresh()

            toast.success('Produto excluido com sucesso!')
            refresh(false)
        }

        const showErrorDeleteToast = () => {
            toast.error('Não foi possível excluir o produto, tente novamente!')
        }

        AlohaApi.deleteProduct(productToDelete.id)
            .then(_ => showSuccessDeleteToast())
            .catch(_ => showErrorDeleteToast())
    }

    function getCategories() {
        return categories.current
    }

    return (
        <div className="h-100">
            <Card className="h-100">

                <Container className="p-4">
                    <Row className="align-items-center">
                        <Col md="auto">
                            <Label for="inp-search">Pesquisar</Label>
                        </Col>
                        <Col className="mb-3 mb-md-0">
                            <LazyInputSearch id="inp-search" type="text" placeholder="Digite aqui" onChange={text => search(text)} />
                        </Col>
                        <Col className="mb-3 mb-md-0">
                            <Input id="inp-type" type="select" onChange={e => setFilterType(e.target.value)}>
                                <option value={''}>Todos</option>
                                {getCategories().map((e, index) => <option key={`pro-type-option-${index}`} value={e}>{e}</option>)}
                            </Input>
                        </Col>
                        <Col md="auto">
                            <Button className="text-button px-4" color='primary' block={true} onClick={_ => toggleForm()}>Novo produto</Button>
                        </Col>
                    </Row>
                </Container>

                <StateMachine currentStateKey={machineState}>

                    <State stateKey={'loading'}>
                        <div className="position-absolute top-50 w-100 center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </State>

                    <State stateKey={'error'}>
                        <div className="h-100 center">
                            Desculpe, ocorreu um erro!
                        </div>
                    </State>

                    <State stateKey={'empty'}>
                        <div className="h-100 center">
                            Nenhum produto encontrado
                        </div>
                    </State>

                    <State stateKey={'data'}>
                        <ProductsTable
                            products={state.products}
                            onClickProductDetail={product => showProductDetailed(product)}
                            onClickProductDelete={product => showDeleteProductModal(product)}
                            onClickProductUpdate={product => showUpdateProductModal(product)}
                            onClickProductEnable={product => postProductEnabled(product)}
                            onClickProductDisable={product => postProductDisabled(product)} />
                    </State>

                </StateMachine>

                <Pagination className="mt-auto ms-auto p-3">
                    <PaginationItem disabled={!state.pagination.hasPrevious}>
                        <PaginationLink previous onClick={e => { e.preventDefault(); previousPage() }} />
                    </PaginationItem>
                    {state.pages.map(p => (
                        <PaginationItem active={p === state.page} key={p}>
                            <PaginationLink onClick={e => { e.preventDefault(); goToPage(p) }}>{p}</PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={!state.pagination.hasNext}>
                        <PaginationLink next onClick={e => { e.preventDefault(); nextPage() }} />
                    </PaginationItem>
                </Pagination>

            </Card>

            <CreateProductModal
                typeSuggestion={getCategories()}
                reload={_ => refresh(true)}
                isOpen={isFormOpen}
                toggle={toggleForm} />

            <UpdateProductModal
                typeSuggestion={getCategories()}
                product={productToUpdate}
                reload={_ => refresh(true)}
                isOpen={isUpdateModalOpen}
                toggle={toggleUpdateModal} />

            <DetailModal
                isOpen={detailModal}
                product={detailProduct}
                toggle={toogleDetail} />

            <DeleteModal
                product={productToDelete}
                isOpen={isDeleteModalOpen}
                toggle={toggleDeleteModal}
                confirm={deleteProduct} />
        </div>
    )
}

export default Products