import Select, { createFilter } from 'react-select';

const trim = true
const ignoreCase = true
const ignoreAccents = true
const matchFromStart = false

const filterConfig = {
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom: matchFromStart ? 'start' : 'any',
    stringify: option => `${option.label}`,
};

const SearchableRoom = ({ rooms, value, onChangeRoomHandler }) => {

    function roomOptions() {
        if (rooms) {
            return rooms.map(item => ({ 'value': item.id, 'label': item.name }))
        } else {
            return []
        }
    }

    if (value && rooms) {
        value = roomOptions().find(e => e.value === value)
    }

    return (
        <Select
            isClearable
            isSearchable
            value={value}
            placeholder="Digite ou selecione uma opção"
            options={roomOptions()}
            filterOption={createFilter(filterConfig)}
            onChange={onChangeRoomHandler} />
    )
}

export default SearchableRoom
