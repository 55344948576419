import { Col, Row } from "reactstrap"
import AccountWifi from "./AccountWifi"
import AccountPlaces from "./AccountPlaces"
import AccountGallery from "./AccountGallery"
import AccountAddress from "./AccountAddress"
import AccountSocialMedia from "./AccountSocialMedia"
import AccountHeader from "./AccountHeader"
import AccountContacts from "./AccountContacts"

const Account = () => {
    return (
        <Row>
            <Col sm="12" xl="8">
                <AccountHeader />
                <AccountGallery />
                <AccountAddress />
                <AccountSocialMedia />
                <AccountContacts />
            </Col>
            <Col>
                <AccountPlaces />
                <AccountWifi />
            </Col>
        </Row>
    )
}

export default Account