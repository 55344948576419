import { Download, Printer } from "react-feather"
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import PropTypes from 'prop-types';

export const Printable = ({
    fileName,
    allowDownload,
    allowPrint,
    children,
    className
}) => {

    function print() {
        const options = getOptions()
        const element = document.getElementById('printable');
        html2pdf().from(element).set(options).toPdf().get('pdf').then(function (pdfObj) {
            pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');
        })
    }

    function download() {
        const options = getOptions()
        const element = document.getElementById('printable');
        html2pdf().from(element).set(options).save()
    }

    function getOptions() {
        return {
            margin: 0.5,
            filename: (fileName.includes('.pdf')) ? fileName : `${fileName}.pdf`,
            image: {
                type: 'jpeg',
                quality: 500
            },
            html2canvas: {
                scale: 1
            },
            jsPDF: {
                unit: 'in',
                format: 'letter',
                orientation: 'portrait'
            }
        }
    }

    return (
        <div className={className}>
            <div className="d-flex justify-content-end">
                <button disabled={!allowPrint} className={"my-3 btn btn-sm btn-light " + (allowPrint ? 'text-dark' : 'text-secondary')} onClick={print}>
                    <Printer />
                </button>
                <button disabled={!allowDownload} className={"ms-2 my-3 btn btn-sm btn-light " + (allowDownload ? 'text-dark' : 'text-secondary')} onClick={download}>
                    <Download />
                </button>
            </div>

            <div id="printable">
                {children}
            </div>
        </div>
    )
}

Printable.propTypes = {
    fileName: PropTypes.string.isRequired,
    allowDownload: PropTypes.bool.isRequired,
    allowPrint: PropTypes.bool.isRequired
};