import { useContext } from "react"
import { AlertTriangle, Bell, BellOff } from "react-feather"
import { Card, CardBody } from "reactstrap"
import { RequestsContext } from "../../../../../context/requests/RequestsContext"
import { OpenClosedView } from "./OpenClosedView"

export const PlaceRequestCard = (props) => {

    const place = props.place
    const requests = useContext(RequestsContext)

    const isPlaceOpen = place.enabled

    function onClickChangePlaceState(event) {
        event.stopPropagation()
        props.onClickChangeState(place)
    }

    return (
        <Card {...props}>
            <CardBody>
                <div className="d-flex justify-content-between mb-2">
                    <div className="">
                        <h6 className="text-h6 text-gray1">{place.name}</h6>
                    </div>
                    <NotificationInfo place={place} />
                </div>
                <hr className="text-gray3 mt-3 mb-2"></hr>
                <div className="d-flex center-y py-1 justify-content-between">
                    <OpenClosedView open={isPlaceOpen} />
                    <button onClick={onClickChangePlaceState} className="btn-link text-button rounded py-2">Alterar</button>
                </div>
                <hr className="text-gray3 mb-3 mt-2"></hr>
                <InfoCard place={place} requests={requests} />

                <div className="mt-4 center gap-2 text-primary">
                    <span className="text-center fw-bold text-button d-block">Acompanhar solicitações</span>
                </div>
            </CardBody>
        </Card>
    )
}

const NotificationInfo = ({ place }) => {

    const getStartTime = () => place.notifications.startTime
    const getEndTime = () => place.notifications.endTime

    return (
        <div className="pt-1 d-flex gap-2">
            {place.notifications.timeActive ? <span className="text-caption" style={{ whiteSpace: 'nowrap' }}>{getStartTime()} ~ {getEndTime()}</span> : <></>}
            {place.notifications.active ? <Bell size={16} color={'var(--green1)'} /> : <BellOff size={16} color={'var(--red)'} />}
        </div>
    )
}

const InfoCard = ({ place, requests }) => {
    const pendingNotifications = requests.notifications.filter(item => item.place === place.id).length

    if (pendingNotifications > 0) {
        return (
            <div className="pl-16 pr-16 pt-10px pb-10px overflow-auto light-warning-background rounded-2">
                <div className="text-body2 float-left warning-text m-14">
                    <b>{pendingNotifications}</b> {pendingNotifications === 1 ? 'Solicitação pendente' : 'Solicitações pendentes'}
                </div>
                <div className="float-right warning-text m-14">
                    <AlertTriangle size={18}></AlertTriangle>
                </div>
            </div>
        )
    }

    return (
        <div className="text-center bg-light rounded p-2">
            <span className="text-body2 text-gray2">Nenhuma solicitação pendente</span>
        </div>
    )
}