import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useEvents } from "./hooks";
import { AlertCircle } from "react-feather";
import { useState } from "react";
import { State, StateMachine } from "../../../../components/StateMachine"
import { LoaderButton } from "../../../../components/Button/LoaderButton";
import { SkeletonLoader } from "../../../../components/SkeletonLoader";
import EventCard from "./components/EventCard";
import moment from "moment";

const Events = () => {

    const [isCreateEventLoading, setCreateEventLoading] = useState(false)
    const [isUpdateEventLoading, setUpdateEventLoading] = useState(false)
    const [isDeleteEventLoading, setDeleteEventLoading] = useState(false)

    const [isCreateEventOpen, setCreateEventOpen] = useState(false)
    const [isUpdateEventOpen, setUpdateEventOpen] = useState(false)
    const [isDeleteEventOpen, setDeleteEventOpen] = useState(false)
    const [state, stateMachine, form, setEvent, create, update, reload, deleteEvent, initialState, isValidEvent] = useEvents()

    const toggleCreateEvent = () => setCreateEventOpen(!isCreateEventOpen)
    const toggleUpdateEvent = () => setUpdateEventOpen(!isUpdateEventOpen)
    const toggleDeleteEvent = () => setDeleteEventOpen(!isDeleteEventOpen)

    function openUpdateEvent(event) {
        let new_event = { ...event, time: moment(moment().format('YYYY-MM-DD') + "T" + event.time) }
        setEvent(new_event)
        toggleUpdateEvent()
    }

    function openDeleteEvent(event) {
        setEvent(event)
        toggleDeleteEvent()
    }

    return <>

        <div className="h-100 d-flex flex-column">

            {/* Header */}

            <Row className="align-items-center">
                <Col className="mb-3 mb-md-0">
                    <div className="alert alert-primary py-2 my-auto">
                        <small>
                            Cadastre os eventos recorrentes relacionados ao seu estabelecimento. Ex: Café da manhã, Almoço, Atividades
                        </small>
                    </div>
                </Col>
                <Col md="auto">
                    <Button className="text-button px-4" block color="primary" onClick={toggleCreateEvent}>Novo Evento</Button>
                </Col>
            </Row>

            {/* Content */}

            <div className="flex-fill mt-3">
                <StateMachine currentStateKey={stateMachine}>
                    <State stateKey={'loading'}>
                        <div className="mt-4">
                            <SkeletonLoader></SkeletonLoader> <br />
                            <SkeletonLoader></SkeletonLoader> <br />
                            <SkeletonLoader></SkeletonLoader>
                        </div>
                    </State>

                    <State stateKey={'empty'}>
                        <div className="h-100 center">
                            Nenhum evento adicionado
                        </div>
                    </State>

                    <State stateKey={'error'} className="my-auto">
                        <div className="h-100 center">
                            <div >
                                <div>
                                    <center>
                                        <AlertCircle></AlertCircle>
                                    </center>
                                </div>
                                <div className="pt-3">
                                    <center>
                                        Desculpe, ocorreu um erro, tente novamente
                                    </center>
                                </div>
                                <div className="pt-3">
                                    <center>
                                        <Button color="link" onClick={reload}>Tentar novamente</Button>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </State>

                    <State stateKey='data'>
                        <div className="d-flex flex-wrap gap-4">
                            {
                                state.events.map((value, index) => {
                                    return <EventCard key={`event-card-${index}`} event={value} openUpdateModal={openUpdateEvent} openDeleteModal={openDeleteEvent}></EventCard>
                                })
                            }
                        </div>
                    </State>
                </StateMachine>
            </div>
        </div>

        {/* Modals */}

        <Modal isOpen={isCreateEventOpen} toggle={() => { setEvent(initialState()); toggleCreateEvent() }} scrollable={true} style={{ maxWidth: '560px', width: '100%' }} size='md'>
            <ModalHeader toggle={() => { setEvent(initialState()); toggleCreateEvent() }}>
                Novo evento
                <div className="text-gray3 mt-2"><small>Preencha o formulário abaixo</small></div>
            </ModalHeader>
            <ModalBody>
                {form}
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="primary" loading={isCreateEventLoading} disabled={!isValidEvent()} onClick={() => { create(toggleCreateEvent, setCreateEventLoading) }}>Adicionar</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={() => { setEvent(initialState()); toggleCreateEvent() }}>Cancelar</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={isUpdateEventOpen} toggle={() => { setEvent(initialState()); toggleUpdateEvent() }} scrollable={true} style={{ maxWidth: '560px', width: '100%' }} size='md'>
            <ModalHeader toggle={() => { setEvent(initialState()); toggleUpdateEvent() }}>
                Atualizar evento
            </ModalHeader>
            <ModalBody>
                {form}
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="primary" loading={isUpdateEventLoading} disabled={!isValidEvent()} onClick={() => { update(toggleUpdateEvent, setUpdateEventLoading) }}>Salvar</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={() => { setEvent(initialState()); toggleUpdateEvent() }}>Cancelar</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={isDeleteEventOpen} toggle={() => { setEvent(initialState()); toggleDeleteEvent() }} scrollable={true} style={{ maxWidth: '560px', width: '100%' }} size='md'>
            <ModalHeader toggle={() => { setEvent(initialState()); toggleDeleteEvent() }}>
                Excluir evento
            </ModalHeader>
            <ModalBody>
                Deseja realmente excluir este evento?
            </ModalBody>
            <ModalFooter>
                <LoaderButton color="danger" loading={isDeleteEventLoading} onClick={() => { deleteEvent(toggleDeleteEvent, setDeleteEventLoading) }}>Excluir</LoaderButton>
                <Button className="text-button ms-2" color="secondary" onClick={() => { setEvent(initialState()); toggleDeleteEvent() }}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    </>
}

export default Events;