import { useEffect, useRef, useState } from "react"
import { Link2, Users } from "react-feather"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Legend, RadialBar, RadialBarChart } from "recharts"
import AlohaApi from "../../../../services/aloha-api"

const CampaignContent = ({ campaign }) => {
    return (
        <Card className="mb-2">
            <CardBody>
                <img className="rounded" src={campaign.image} alt={campaign.title} width='100%' />
                <h3 className="mt-4 text-h5">{campaign.title}</h3>
                <p className="text-body2 mt-2">{campaign.shortDescription}</p>
                <p className="text-body2 mt-3">{campaign.longDescription}</p>
            </CardBody>
        </Card>
    )
}

const segmentationName = (name) => {
    let map = {
        'ALL': 'Todos os clientes',
        'HOSTED': 'Somente clientes hospedados',
        'NOT_HOSTED': 'Clientes não hospedados'
    }

    return map[name]
}

const CampaignSegmentationCard = ({ segmentationType }) => {
    return (
        <div className="border rounded p-3 bg-light">
            <div className="center-y gap-2">
                <Users size={20} />
                <span className="text-subtitle1">Segmentação</span>
            </div>
            <div className="mt-2 md-16">{segmentationName(segmentationType)}</div>
        </div>
    )
}

const CampaignRedirectLinkCard = ({ link }) => {
    return (
        <div className="border rounded p-3 bg-light">
            <div className="center-y gap-2">
                <Link2 size={20} />
                <span className="text-subtitle1">Link para redirecionamento</span>
            </div>
            <div className="mt-2 md-16"><a href={link}>{link}</a></div>
        </div>
    )
}

const CampaignMetricsCard = ({ data }) => {
    return (
        <div>
            <RadialBarChart
                width={800}
                height={400}
                cx={150}
                cy={150}
                innerRadius={20}
                outerRadius={140}
                barSize={30}
                data={data}>

                <RadialBar
                    minAngle={25}
                    label={{ position: "insideStart", fill: "#fff" }}
                    background
                    clockWise
                    dataKey="value" />

                <Legend
                    iconSize={10}
                    width={220}
                    height={400}
                    layout="vertical"
                    verticalAlign="middle" />

            </RadialBarChart>
        </div>
    )
}

const CampaignDetails = () => {
    const [campaign, setCampaign] = useState({});
    const [loading, setLoading] = useState(true);
    const { campaignId } = useParams()
    const dataFetchedRef = useRef(false);

    const loadCampaign = () => {
        setLoading(true)
        AlohaApi.fetchCampaign(campaignId)
            .then(onSuccess)
            .finally(async _ => {
                await setTimeout(1000);
                setLoading(false)
            })
    }

    useEffect(_ => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        loadCampaign();
    }, [])

    const onSuccess = (res) => {
        setCampaign(
            {
                title: res.data.title,
                segmentation: res.data.audience_segmentation,
                shortDescription: res.data.short_description,
                longDescription: res.data.long_description,
                link: res.data.link,
                image: res.data.image,
                statistics: [
                    {
                        name: `Enviados`,
                        value: res.data.stats.delivers.total_sent,
                        fill: "#A5D7EF"
                    },
                    {
                        name: `Entregues`,
                        value: res.data.stats.delivers.total_delivered,
                        fill: "#FFA412"
                    },
                    {
                        name: `Abertos`,
                        value: res.data.stats.clicks.open_clicks,
                        fill: "#85A500"
                    },
                    {
                        name: `Redirecionados`,
                        value: res.data.stats.clicks.redirect_clicks,
                        fill: "#C9EA60"
                    },
                ]
            }
        )
    }

    return (
        <Row>
            <Col md={6}>
                <CampaignContent campaign={campaign}></CampaignContent>
            </Col>
            <Col md={6}>
                <Card>
                    <CardBody>
                        <div className="mb-2"><CampaignSegmentationCard segmentationType={campaign.segmentation}></CampaignSegmentationCard></div>
                        <div className="mb-4"><CampaignRedirectLinkCard link={campaign.link}></CampaignRedirectLinkCard></div>
                        <div className="mb-2 md-16 text-center">Métricas</div>
                        {loading ? <></> : <dir><CampaignMetricsCard data={campaign.statistics}></CampaignMetricsCard></dir>}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}


export default CampaignDetails