import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const NewCalendarAddedModal = props => {
    return <Modal isOpen={props.isOpen} size="md">
        <ModalHeader>Nova agenda adicionada</ModalHeader>
        <ModalBody>
            <p>Você acabou criar uma agenda, deseja adicionar a disponibilidade para essa agenda agora?</p>
        </ModalBody>
        <ModalFooter>
                <Button color="primary" onClick={() => {props.toggle(true)}}>Sim</Button>
                <Button outline color="danger" onClick={() => {props.toggle(false)}}>Não</Button>
        </ModalFooter>
    </Modal>
}