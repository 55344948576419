import axios from "axios";
import { getToken, logout } from "./auth";

export const LOGIN_URL = '/v1/login'

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

api.interceptors.response.use(
    response => response,
    error => {
        /**
         * Whenever a request was not authorized, login is required
         */
        if (error.response.status === 401 && error.config.url !== LOGIN_URL) {
            logout() // Clear the the stored token
            window.location.href = '/login'; // force navigation to login screen
        } else {
            return Promise.reject(error);
        }
    }
)

api.interceptors.request.use(async config => {
    const token = getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = '@l0h4'
    }

    return config;
});

/**
 * 
 * This method create a query path based on object param
 * 
 * for example
 * @param { page: 1, status: 'active' }
 * @return '?page=1&status=active'
 * 
 * now you can use the result cancatenated with your request url
 * 
 * important: params with null value will not concatenated
 */
function buildQueryUrlParamFromObject(objectParam) {
    const entries = Object.entries(objectParam).map(item => ({ key: item[0], value: item[1] })).filter(item => item.value)
    return entries.reduce((acc, obj) => {
        let prefix = !acc ? '?' : '&'
        return `${acc}${prefix}${obj.key}=${obj.value}`
    }, '')
}

/**
 * Only get requests
 */
const fetchRequests = {
    fetchConversions: (query) => api.get(`/v1/dashboards/operators-leaderboard` + buildQueryUrlParamFromObject(query)),
    fetchOperators: () => api.get(`/v1/operators?type=NO_LOGIN`).then(res => res.data.operators.sort((a, b) => a.name.localeCompare(b.name))),
    fetchPosts: () => api.get('/v1/posts'),
    fetchRooms: () => api.get('/rooms'),
    fetchPlaces: () => api.get('/places?productCounter=true&serviceCounter=true').then(res => res.data.sort((a, b) => a.name.localeCompare(b.name))),
    fetchEvents: () => api.get('/v1/calendars/rules'),
    fetchProductTypes: () => api.get('/v1/products/product-types'),
    fetchProducts: (query) => api.get('/v1/products' + buildQueryUrlParamFromObject(query)),
    fetchGuest: (id) => api.get(`/v1/guests/${id}`),
    fetchGuests: (query) => api.get('/v1/guests' + buildQueryUrlParamFromObject(query).replace('_', '-')),
    fetchCampaigns: (query) => api.get(`/v1/campaigns` + buildQueryUrlParamFromObject(query).replace('_', '-')),
    fetchCampaign: (campaignId) => api.get(`/v1/campaigns/${campaignId}`),
    fetchInfo: () => api.get('/info'),
    fetchAddress: () => api.get('/address'),
    fetchContacts: () => api.get('/contacts'),
}

/**
 * Only post requests
 */
const postRequests = {

    createGuest: (room, email, whatsapp, checkin, checkout, escorts) => api.post('/v2/guests', {
        main_guest: { email, whatsapp },
        stay_check_in: checkin,
        stay_check_out: checkout,
        other_guests: escorts.map(item => ({ email: item.email, whatsapp: item.whatsapp })),
        stay_room_id: room
    }),

    approveGuest: (guestId, checkin, checkout, roomId, customFields) => api.post(`/v1/guests/${guestId}/approve`, {
        checkin: checkin,
        checkout: checkout,
        room_id: roomId,
        custom_fields: customFields
    }),

    refuseGuest: (guestId) => api.post(`/v1/guests/${guestId}/refuse`),
    
    createProduct: ({
        place,
        delivery,
        image,
        name,
        price,
        description,
        type,
    }) => {

        var formData = new FormData()
        formData.append('image', image)
        formData.append('name', name)
        formData.append('price_number', price)
        formData.append('price_currency', 'BRL')
        formData.append('description', description)
        formData.append('product_type',type)
        formData.append('place_id', place)
        delivery.forEach(i => formData.append('delivery_modes', i))

        return api.post('/v1/products', formData, { headers: { "Content-Type": "multipart/form-data" } })
    },

    createCampaign: (title, shortDescription, longDescription, audienceSegmentation, link, images) => {
        var formData = new FormData()

        var isImageNull = !images || images.length === 0
        if (!isImageNull) {
            images.map((item) => formData.append('image', item))
        }

        formData.append('title', title)
        formData.append('short_description', shortDescription)
        formData.append('long_description', longDescription)
        formData.append('audience_segmentation', audienceSegmentation)
        formData.append('link', link)

        return api.post('v1/campaigns', formData, { headers: { "Content-Type": "multipart/form-data" } })
    },
    
    createContact: (type = 'CALL', name, phone) => api.post('contacts', { type, name, phone }),

    disablePlace: (placeId) => api.post(`places/${placeId}/disable`),
    enablePlace: (placeId) => api.post(`places/${placeId}/enable`),
    enableProduct: (productId) => api.post(`/v1/products/${productId}/enable`),
    disableProduct: (productId) => api.post(`/v1/products/${productId}/disable`),
}

/**
 * Only delete requests
 */
const deleteRequests = {
    deleteContact: (contactId) => api.delete(`contacts/${contactId}`),
    deleteProduct: (productId) => api.delete(`/v1/products/${productId}`)
}

/**
 * Only put requests
 */
const updateRequests = {

    /***
     * wait for new way to send update body with whatsapp...
     */
    updateGuest: (guestId, email, whatsapp, room, checkin, checkout) => api.put(`/v1/guests/${guestId}`, {
        email: email,
        checkin: checkin,
        checkout: checkout,
        room_id: room,
        change_stay_guests: true  // Improve in the future -.-
    })
}

const AlohaApi = {
    ...fetchRequests,
    ...postRequests,
    ...deleteRequests,
    ...updateRequests
}

export default AlohaApi