import { useContext, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { AdminContent, AppBarMobile, LogoutButton, SidebarHeader } from "./AdminComponents"
import { Home, Users, Calendar, BookOpen, User, Archive, Tool, ShoppingCart, Send } from 'react-feather'
import { logout } from './../../../services/auth'
import { Menu, MenuBadge, MenuItem, MenuSubItem } from "../../../components/Menu"
import { Modal, ModalHeader } from "reactstrap"
import { RequestsContext } from "../../../context/requests/RequestsContext"
import SideBar, { SidebarBottom, SidebarContent } from "../../../components/Sidebar"
import OperatorSwitch from "../../../components/Button/OperatorSwitchButton"
import AlohaApi from "../../../services/aloha-api"

const HOME = { page: "/dashboard/home", name: "Dashboard" }
const GUEST = { page: "/dashboard/guests", name: "Hóspedes" }
const REQUESTS = { page: "/dashboard/requests", name: "Solicitações" }
const PRODUCTS = { page: "/dashboard/products", name: "Produtos" }
const POSTS = { page: "/dashboard/posts", name: "Postagens" }
const ACCOUNT = { page: "/dashboard/account", name: "Conta" }
const EVENTS = { page: "/dashboard/events", name: "Eventos" }
const CAMPAIGN = { page: "/dashboard/campaigns", name: "Campanhas" }
const OPERATORS = { page: "/dashboard/operators", name: "Colaboradores" }

const SERVICES = {
    page: '/dashboard/services',
    name: 'Serviços',
    AGENDA: { page: "/dashboard/services/agendas", name: "Agendas" },
    CATALOG: { page: "/dashboard/services/catalog", name: "Catálogo" }
}

const SIDEBAR_WIDTH = 300

const AdminContainer = ({ activeMenu }) => {

    const navigate = useNavigate()
    const requests = useContext(RequestsContext)

    const [version, setVersion] = useState(1)
    const [header, setHeader] = useState({ state: null, data: null })
    const [containerHeader, setContainerHeader] = useState(HOME.name)
    const [menuModalOpen, setMenuModalOpen] = useState(false)

    const date = new Date().toLocaleDateString('pt-BR', { dateStyle: 'long' })

    useEffect(_ => {
        setHeader({ state: 'loading', data: null })
        AlohaApi.fetchInfo()
            .then(res => setHeader({ state: 'data', data: res.data }))
            .catch(_ => setHeader({ state: 'error', data: null }))
    }, [version])

    useEffect(_ => {
        setHeader({ state: 'loading', data: null })
    }, [])

    useEffect(() => {
        switch (activeMenu) {
            case HOME.page:
                setContainerHeader(HOME.name)
                break
            case GUEST.page:
                setContainerHeader(GUEST.name)
                break
            case REQUESTS.page:
                setContainerHeader(REQUESTS.name)
                break
            case PRODUCTS.page:
                setContainerHeader(PRODUCTS.name)
                break
            case POSTS.page:
                setContainerHeader(POSTS.name)
                break
            case ACCOUNT.page:
                setContainerHeader(ACCOUNT.name)
                break
            case EVENTS.page:
                setContainerHeader(EVENTS.name)
                break
            case SERVICES.CATALOG.page:
                setContainerHeader(SERVICES.CATALOG.name)
                break
            case SERVICES.AGENDA.page:
                setContainerHeader(SERVICES.AGENDA.name)
                break
            case CAMPAIGN.page:
                setContainerHeader(CAMPAIGN.name)
                break
            case OPERATORS.page:
                setContainerHeader(OPERATORS.name)
                break
            default:
                break
        }
    }, [activeMenu])

    function navigateMenu(menu) {
        setMenuModalOpen(false)
        navigate(menu.page)
    }

    function doLogout() {
        logout()
        navigate(`/`)
    }

    const SideBarContentView = (
        <SidebarContent>
            <SidebarHeader state={header.state} data={header.data} refresh={_ => setVersion(v => (v += 1))} />
            <Menu>
                <MenuItem icon={<Home size={20} />} name={HOME.name} onClick={_ => navigateMenu(HOME)} active={activeMenu === HOME.page} />

                <MenuItem icon={<Users size={20} />} name={GUEST.name} onClick={_ => navigateMenu(GUEST)} active={activeMenu === GUEST.page}>
                    <MenuBadge hidden={requests.checkIns.pending.length === 0} value={requests.checkIns.pending.length}></MenuBadge>
                </MenuItem>

                <MenuItem icon={<Archive size={20} />} name={REQUESTS.name} onClick={_ => navigateMenu(REQUESTS)} active={activeMenu.includes(REQUESTS.page)} >
                    <MenuBadge hidden={requests.notifications.length === 0} value={requests.notifications.length}></MenuBadge>
                </MenuItem>
                
                <MenuItem icon={<ShoppingCart size={20} />} name={PRODUCTS.name} onClick={_ => navigateMenu(PRODUCTS)} active={activeMenu === PRODUCTS.page} />
                <MenuItem icon={<Tool size={20} />} name={SERVICES.name} onClick={_ => navigateMenu(SERVICES.CATALOG)} active={activeMenu.includes(SERVICES.page)}>
                    <MenuSubItem visible={activeMenu.includes(SERVICES.page)} active={activeMenu === SERVICES.CATALOG.page} name={SERVICES.CATALOG.name} onClick={_ => navigateMenu(SERVICES.CATALOG)} />
                    <MenuSubItem visible={activeMenu.includes(SERVICES.page)} active={activeMenu === SERVICES.AGENDA.page} name={SERVICES.AGENDA.name} onClick={_ => navigateMenu(SERVICES.AGENDA)} />
                </MenuItem>

                <MenuItem icon={<BookOpen size={20} />} name={POSTS.name} onClick={_ => navigateMenu(POSTS)} active={activeMenu === POSTS.page} />
                <MenuItem icon={<Calendar size={20} />} name={EVENTS.name} onClick={_ => navigateMenu(EVENTS)} active={activeMenu === EVENTS.page} />
                <MenuItem icon={<Send size={20} />} name={CAMPAIGN.name} onClick={_ => navigateMenu(CAMPAIGN)} active={activeMenu.includes(CAMPAIGN.page)} />

                <MenuItem icon={<Users size={20} />} name={OPERATORS.name} onClick={_ => navigateMenu(OPERATORS)} active={activeMenu === OPERATORS.page} />
                <MenuItem icon={<User size={20} />} name={ACCOUNT.name} onClick={_ => navigateMenu(ACCOUNT)} active={activeMenu === ACCOUNT.page} />
            </Menu>
        </SidebarContent>
    )

    const SideBarBottomView = (
        <SidebarBottom>
            <OperatorSwitch breakLine="true" className="mb-2" />
            <LogoutButton onClick={doLogout} />
        </SidebarBottom>
    )

    return (
        <div className="background1 full">
            <div className="d-block d-md-none">
                <AppBarMobile toggle={_ => setMenuModalOpen(!menuModalOpen)} />
                <Modal isOpen={menuModalOpen} centered={true} scrollable={true} toggle={_ => setMenuModalOpen(!menuModalOpen)}>
                    <ModalHeader className="border-white" toggle={_ => setMenuModalOpen(!menuModalOpen)}></ModalHeader>
                    {SideBarContentView}
                    {SideBarBottomView}
                </Modal>
            </div>

            <div className="d-flex w-100 h-100">
                <SideBar width={SIDEBAR_WIDTH}>
                    {SideBarContentView}
                    {SideBarBottomView}
                </SideBar>

                <div className="w-100 flex-fill">
                    <AdminContent label={date} title={containerHeader}>
                        <Outlet />
                    </AdminContent>
                </div>
            </div>
        </div >
    )
}

export default AdminContainer