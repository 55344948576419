import { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import api from "../../../../services/api"

export const ProductsStatistics = props => {

    const days = props.days
    const [state, setState] = useState({ loading: false, error: false, data: [] })

    useEffect(_ => {
        setState({ loading: true, error: false, data: [] })

        api.get(`v1/dashboards/products-requested?days=${days}`)
            .then((res) => {
                let data = []

                // create default data to graph
                for (let index = days; index >= 0; index--) {
                    var date = new Date();
                    date.setDate(date.getDate() - index);
                    data.push({ date: date.toLocaleDateString(), Pedidos: 0 })
                }

                // populate graph
                res.data.forEach(r => {
                    let items = r.date.split("/")
                    let day = items[0]
                    let month = items[1]
                    let year = items[2]

                    let date = new Date(year, month - 1, day)
                    let found = data.find(e => e.date === date.toLocaleDateString())
                    if (found) {
                        found.Pedidos = r.requested
                    }
                })

                setState({ loading: false, error: false, data: data })
            })
            .catch(_ => setState({ loading: false, error: true, data: [] }))
    }, [days])

    const Content = () => {
        if (state.loading) {
            return <Spinner size={'sm'}></Spinner>
        }

        if (state.error && !state.loading) {
            return <span>Desculpe, ocorreu um erro!</span>
        }

        return (
            <ResponsiveContainer>
                <BarChart data={state.data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Pedidos" fill={'var(--bs-primary)'} />
                </BarChart>
            </ResponsiveContainer>
        )
    }

    return (
        <Card {...props} >
            <CardBody>
                <h5 className="p-2">Venda de produtos</h5>
                <div className="mt-5 center" style={{ height: 250, maxWidth: '100%' }}>
                    {Content()}
                </div>
            </CardBody>
        </Card>
    )
}