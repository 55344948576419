import React from "react"
import InputMask from "react-input-mask"

function InputPhone(props) {
    let invalid = props.invalid ? props.invalid : false
    let className = 'form-control ' + (props.className ? props.className : '')
    if (invalid) {
        className = className.concat(' is-invalid')
    }
    return <InputMask placeholder="(__) _____ ____" mask="(99) 99999 9999" name={props.name} id={props.id} className={className} onChange={props.onChange} value={props.value} />
}

export default InputPhone